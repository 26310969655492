import React, { useEffect, useState } from 'react';
import LecturasCaudalimetrosTabla from './lecturascaudalimetros/LecturasCaudalimetrosTabla.jsx';
import RiegosDiagnosticoInfo from './diagnostico/RiegosDiagnosticoInfo.jsx';
import Modal from 'react-bootstrap/Modal';
import LecturasCaudalimetrosRegistrar from './lecturascaudalimetros/LecturasCaudalimetrosRegistrar.jsx';

const RiegosConsumoAgua = (props) => {
  const [modal, setModal] = useState(false);
  const [idSectorLecturaCaudalimetro, setIdSectorLecturaCaudalimetro] = useState(0);
  const [reload, setReload] = useState(0);

  const onComplete = () => {
    setReload(reload + 1);
    setModal(false);
  };

  const onUpdateClick = (id_sector_lectura_caudalimetro) => {
    setIdSectorLecturaCaudalimetro(id_sector_lectura_caudalimetro);
    setModal(true);
  };



  return (
    <>

      <div className="row">
        <div className="col col-md-12">
          <RiegosDiagnosticoInfo id_productor={props.id_productor} diagnostico_inicial={props.diagnostico_inicial} />

          <div className="row">
            <div className="col col-md-2 offset-md-10">
              <a className='btn btn-info' onClick={() => setModal(!modal)}>Registrar lectura</a>
            </div>
          </div>

          <LecturasCaudalimetrosTabla
            onUpdateClick={(id_sector_lectura_caudalimetro) => onUpdateClick(id_sector_lectura_caudalimetro)}
            id_productor={props.id_productor}
            id_ciclo={props.id_ciclo}
            reload={reload}
          />
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Registar / Actualizar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LecturasCaudalimetrosRegistrar
            id_ciclo={props.id_ciclo}
            huertas={props.huertas}
            etapas={props.etapas}
            id_sector_lectura_caudalimetro={idSectorLecturaCaudalimetro}
            id_productor={props.id_productor}
            onComplete={(result) => onComplete(result)}
          />
        </Modal.Body>
      </Modal>

    </>
  );
};
export default RiegosConsumoAgua;
