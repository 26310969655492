import {
  getWeek,
  addWeeks,
  format,
  getWeekYear,
  getISOWeek,
  getISOWeekYear,
} from "date-fns";

export const bit_names = {
  bit_aplicaciones: "Aplicación",
  bit_pre_siembra_aplicaciones: "Presiembra",
  bit_siembras: "Siembra",
  bit_riegos: "Riego",
  bit_mantenimiento: "Mantenimiento",
  bit_cosecha_ventas: "Cosecha/Venta",
  bit_preparacion_tierra: "Preparación Tierra",
};

export const getDateRange = (fechaInicial, fechaFinal) => {
  const inicio = new Date(fechaInicial);
  const final = new Date(fechaFinal);
  const fechas = [];
  let fecha = inicio;

  while (fecha <= final) {
    fechas.push({
      fecha: fecha,
      year: getWeekYear(fecha),
      weekNumber: String("0" + getWeek(fecha, { weekStartsOn: 0 })).slice(-2),
      label: format(fecha, "dd MMM yyyy"),
      yearweek:
        getISOWeekYear(fecha).toString() +
        String("0" + getISOWeek(fecha)).slice(-2),
      isoWeek: getISOWeek(fecha),
    });
    fecha = addWeeks(fecha, 1);
  }
  return fechas;
};

export const countActiveUsers = (data) => {
  // get unique values and turn into arrays
  const avisos = data.usuarios_avisos.map((it) => it.id_user)
  const clima_consultas = data.usuarios_clima_consultas.map((it) => it.id_user)
  const activos_clima = [...avisos, ...clima_consultas].filter((v, i, a) => a.indexOf(v) === i)
  const bitacoras = data.usuarios_bitacoras
    .map((it) => it.id_user)
    .filter((v, i, a) => a.indexOf(v) === i)

  // get excluding sets
  const activos_bitacoras_y_clima = bitacoras.filter((id) => activos_clima.includes(id))
  const bitacora_solamente = bitacoras.filter((id) => !activos_clima.includes(id))
  const activos_clima_solamente = activos_clima.filter((id) => !bitacoras.includes(id))

  const inactivos = data.productores_todos.filter(
    (user) => !bitacoras.includes(user.id) && !activos_clima.includes(user.id)
  )
  return {
    totales: [
      {
        label: 'Clima y Bitácora',
        total: activos_bitacoras_y_clima.length,
      },
      {
        label: 'Sólo Bitácora',
        total: bitacora_solamente.length,
      },
      {
        label: 'Sólo Clima',
        total: activos_clima_solamente.length,
      },
    ],
    listadoInactivos: inactivos,
  }
}

export const countActiveInactive = (data) => {
  const activeUsers = countActiveUsers(data).totales;
  const totalUsers = data.total_final;
  const active = activeUsers.reduce((acc, cur) => acc + cur.total, 0);
  return [
    {
      label: "Activos",
      total: active,
    },
    {
      label: "Inactivos",
      total: parseInt(totalUsers.total) - active,
    },
  ];
};

export const cleanData = (data) => {
  return data.data.map((it) => {
    return {
      ...it,
      total: parseInt(it.total),
    };
  });
};

  // count weekly users per new, deleted and total accumulated
export const createNewDeletedTotalUsersArray = (creados, borrados, dateRange, inicio, final) => {
    let acc = parseInt(inicio.total);
    let newUsers = 0;
    let deletedUsers = 0;
    let temp_creados = [];
    let temp_borrados = [];
    const new_data = dateRange.map((item) => {
      temp_creados = creados.find((d) => item.yearweek === d.yearweek);
      temp_borrados = borrados.find((d) => item.yearweek === d.yearweek);
      if (temp_creados) {
        acc += parseInt(temp_creados.total);
        newUsers = parseInt(temp_creados.total);
      } else {
        newUsers = 0;
      }

      if (temp_borrados) {
        acc -= parseInt(temp_borrados.total_deleted);
        deletedUsers = parseInt(temp_borrados.total_deleted);
      } else {
        deletedUsers = 0;
      }
      return {
        ...item,
        total_users: acc,
        new_users: newUsers,
        deleted_users: -deletedUsers,
      };
    });
    if (parseInt(new_data.at(-1).total_users) !== parseInt(final.total)) {
      console.log(`Error el final esperado no coincide ${(new_data.at(-1).total_users)} VS ${final.total}`);
    }
    return new_data;
  };