export const Colores = [
  "#E74155",
  "#47AB52",
  "#FFC107",
  "#A7A7AA",
  "#4181BC",
  "#5A3E03",
  "#E3F5EA",
  "rgba(196, 40, 190, 1)",
  "rgba(213, 194, 223, 1)",
  "rgba(74, 242, 212, 0.9)",
  "rgba(45, 102, 97, 0.9)",
  "rgba(255, 176, 4, 0.9)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(131, 151, 80, 0.4)",
  "rgba(140, 29, 147, 0.3)",
];

export const coloresPastel = [
  "#E74155",
  "#47AB52",
  "#FFC107",
  "#A7A7AA",
  "#4181BC",
  "#5A3E03",
  "#E3F5EA",
];

export const coloresPastel1 = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
export const coloresPastel2 = ["#FC2525", "#ACADAF"];

export const mainRed = "#EC253D";
export const mainGreen = "#47AB52";

export const verdes = [
  "#195A22",
  "#2A7934",
  "#348A3E",
  "#3E9C49",
  "#47AB52",
  "#62B76B",
  "#7DC485",
  "#A2D4A7",
  "#C6E5C9",
  "#E7F4E9",
];

export const rojos = [
  "#AF0022",
  "#BE002D",
  "#CB0935",
  "#DD193B",
  "#EC253D",
  "#E74155",
  "#DD6875",
  "#E9939B",
  "#FAC9D3",
  "#FDE9EE",
];
