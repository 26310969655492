import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import Button from 'react-bootstrap/Button';

var dataArray = [];
const LecturasEtosTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'ID',
			selector: 'id_recomendacion_riego_eto',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Ciclo',
			selector: 'nombre_ciclo',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Inicio',
			selector: 'fecha',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Fin',
			selector: 'fecha_fin',
			sortable: true,
			right: false
		},
		{
			name: 'Semana',
			selector: 'semana',
			sortable: true,
			maxWidth: '150px',
			right: false
		},
		{
			name: 'ETO',
			selector: 'eto',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Button className="btn btn-xs btn-warning" onClick={() => onUpdateClick(row.id_recomendacion_riego_eto)}><i className="fa fa-edit"></i></Button> <DeleteButton onClick={() => eliminaRegistro(row.id_recomendacion_riego_eto)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	]);


	useEffect(() => {
		if(props.id_zona > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'nombre_zona' }))}
		obtenerRegistros(props.id_zona);
	}, [props.id_zona]);

	  const obtenerRegistros = (id_zona = 0)  => {
		setBlocking(true);
		Api.getAll(ApiUrl.riegos.recomendaciones.etos + '?id_zona=' + id_zona)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
			  setDatosFiltrados(response.data.data);
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaRegistro = (id_recomendacion_riego_eto) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				setBlocking(true);
				Api.delete(ApiUrl.riegos.recomendaciones.etos, id_recomendacion_riego_eto)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						setBlocking(false);
						Swal.fire(
						  'Eliminado!',
						  'Registro eliminado.',
						  'success'
						);
					}
				obtenerRegistros();
				})
				.catch(e => {
					setBlocking(false);
				  Swal.fire(
					'Error!',
					'Error al eliminar el registro.',
					'error'
				  );
				  obtenerRegistros();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}
	const onUpdateClick = (id_recomendacion_riego_eto) => {props.onUpdateClick(id_recomendacion_riego_eto);}

	useEffect(() => {
		if(props.id_zona > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
		obtenerRegistros(props.id_zona);
	}, [props.id_zona,props.reload]);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar lectura" 
				datosTabla={dataArray} 
				urlRegistrar={false}
				columnasFiltrar={["nombre_estacion","semana","fecha","eto"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>
          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default LecturasEtosTabla;
