import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import Modal from 'react-bootstrap/Modal';
import EditButton from '../../../nuup/componentes/Generales/EditButton';
import PlaguicidasPreciosForm from './PlaguicidasPreciosForm';
const PlaguicidasPreciosTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [precio, setPrecio] = useState([]);
	const [state, setState] = useState({
	id_producto_comercial : 0,
	precios: [],
	});

	const columns = [
		{
			name: 'ID',
			selector: 'id_producto_comercial_precio',
			sortable: true,
			right: false
		},


		{
			name: 'CANTIDAD',
			selector: 'cantidad',
			sortable: true
		},

		{
			name: 'ID UNIDAD',
			selector: 'unidad.nombre_unidad',
			sortable: true
		},
		{
			name: 'PRECIO',
			selector: 'precio',
			sortable: true,
			right: false
		},
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><EditButton onClick={() =>onEditPrecio(row)} ><i className="fa fa-edit"></i></EditButton> <DeleteButton onClick={() => eliminaPrecio(row.id_producto_comercial_precio)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	const onEditPrecio = (data) => {
		setPrecio(data);
		setShow(true);
	}



	useEffect(() => {
		obtenerPrecios();
	// eslint-disable-next-line react-hooks/exhaustive-deps		
	},[]);


	  const obtenerPrecios = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.catalogs.plaguicidas.precios + '/' + props.id_producto_comercial)
		  .then(response => {
			setBlocking(false);
			hideFormEdit();
			setState({
			  precios: response.data.data
			});
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaPrecio = (id_producto_comercial_precio) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.catalogs.plaguicidas.precios, id_producto_comercial_precio)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						hideFormEdit();
						Swal.fire(
						  'Eliminado!',
						  'Precio eliminado.',
						  'success'
						);
					}
				obtenerPrecios();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el precio.',
					'error'
				  );
				  obtenerPrecios();
				});

			}
		  })

	};


	const showForm = () => {
		setPrecio([]);
		setShow(true);
	};


	const hideFormEdit = () => {
		setPrecio([]);
		setShow(false);
	};

	const onChangeElementos = () => {
		obtenerPrecios();
	};


	
	const actionsMemo = React.useMemo(() => <><button onClick={() =>showForm()} className="btn btn-secondary">Registrar</button></>, []);

	return (
			<>
			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Registrar / Actualizar precio
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PlaguicidasPreciosForm onChangeElementos={onChangeElementos} id_producto_comercial={props.id_producto_comercial} precio={precio} />
              </Modal.Body>
            </Modal>


		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<DataTable
				pagination
				columns={columns}
				data={state.precios}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				actions={actionsMemo}
			/>
		</BlockUi>
		</>
    );
  };
export default PlaguicidasPreciosTabla;
