import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import changeKeyObjects from '../../../nuup/helpers/changeKeyObjects';
import selectArrayToArray from '../../../nuup/helpers/selectArrayToArray';
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import BlockUi from 'react-block-ui';
import { saveAs } from "file-saver";


const ReportesBitacoras = () => {
	const [blocking, setBlocking] = useState(false);

	const schema = yup.object().shape({
		id_users: yup.array().min(1, 'Requerido').of(
			yup.object().shape({
				id: yup.number().min(1).required(),
				label: yup.string().required(),
			})
		),
		fechaInicio: yup.string(),
		fechaFin: yup.string()
	});

	const { register, handleSubmit, errors, control } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_users: [],
			fechaInicio: "",
			fechaFin: ""
		}
	});

	const [state, setState] = useState({
		id_aviso: 0,
		avisos: [],
		currentUsuario: null,
		currentIndex: -1,
		items: []
	});


	useEffect(() => {
		obtenerProductores();
	// eslint-disable-next-line react-hooks/exhaustive-deps	
	},[]);

	const obtenerProductores = () => {
		setBlocking(true);
		Api.getAll(ApiUrl.bitacoras.productores)
			.then(response => {
				setState({
					...state,
					items: response.data.data
				});
				setBlocking(false);
			})
			.catch(e => {
				setBlocking(false);
			});
	}


	const onSubmit = async (values) => {
		setBlocking(true);
		let elementos = {
			fechaInicio: values.fechaInicio,
			fechaFin: values.fechaFin,
			id_users: selectArrayToArray(values.id_users, 'id')
		};
		const response = await fetch(ApiUrl.Url + '/api/' + ApiUrl.bitacoras.exportar + '?fechaInicio=' + elementos.fechaInicio + '&fechaFin=' + elementos.fechaFin + '&id_users=' + selectArrayToArray(values.id_users, 'id') , {
			method: 'GET',
			mode: 'cors',
			cache: 'no-cache',
			headers: {
			  'Authorization': 'Bearer ' + localStorage.token
			}		  
		});
		  const blob = await response.blob();
		  saveAs(blob, "ReporteBitacoras.xlsx");
		  setBlocking(false);
	}

	return (
		<>
			<div>
				<ol className="breadcrumb float-xl-right">
					<li className="breadcrumb-item"><Link to="/administracion/usuarios">Exportar bitácoras</Link></li>
					<li className="breadcrumb-item active"></li>
				</ol>
				<h1 className="page-header">Exportar bitácoras <small></small></h1>

				<div className="row">
					<div className="col-md-12">

						<Panel>
							<PanelHeader>Opciones de filtrado</PanelHeader>
							<PanelBody>
								<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

									<form onSubmit={handleSubmit(onSubmit)}>


										<div className="form-group row">
											<div className="col-sm-6">
												<FormInput
													id="fechaInicio"
													type="date"
													name="fechaInicio"
													label="Fecha Inicio"
													error={errors.fechaInicio}
													register={register}
												/>
											</div>
											<div className="col-sm-6">
												<FormInput
													id="fechaFin"
													type="date"
													name="fechaFin"
													label="Fecha Fin"
													error={errors.fechaFin}
													register={register}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-12">

												<Controller
													name="id_users"
													control={control}
													render={({ onChange, ref, value }) => (
														<MultiSelect
															items={changeKeyObjects(state.items, { id: "id", productor: "label" })}
															onChange={onChange}
															ref={ref}
															showSearch
															showSelectAll
															showSelectedItems
															selectedItems={value}
															messages={{
																searchPlaceholder: "Buscar...",
																noItemsMessage: "Sin elementos...",
																noneSelectedMessage: "Ninguno seleccionado",
																selectedMessage: "seleccionados",
																selectAllMessage: "Seleccionar todos",
																clearAllMessage: "Quitar todos",
															}}
														/>


													)}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-md-6 offset-md-6">
												<button className="btn btn-primary btn-block" type="submit">Exportar</button>
											</div>
										</div>

									</form>
								</BlockUi>
							</PanelBody>
						</Panel>
					</div>
				</div>


			</div>
		</>
	);
};
export default ReportesBitacoras;