import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import "react-data-table-component-extensions/dist/index.css";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import UsuariosRegistrar from './UsuariosRegistrar';
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import UsuariosExportar from './UsuariosExportar';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const UsuariosTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(true);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [state, setState] = useState({
	id_user : 0,
	usuarios: [],
	usuariosFiltrados: [],
	currentUsuario: null,
	currentIndex: -1
	});


	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'ROL',
			selector: 'roles',
			sortable: true,
			maxWidth: '200px',
		},


		{
			name: 'USUARIO',
			selector: 'username',
			sortable: true,
			maxWidth: '200px',
			right: false
		},

		{
			name: 'NOMBRE',
			sortable: true,
			maxWidth: '250px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.firstname + ' ' + row.lastname1}</div></div>
		},

		{
			name: 'NIVEL',
			selector: 'nombre_nivel',
			sortable: true,
			maxWidth: '200px',
			right: false
		},
		{
			name: 'ZONA',
			selector: 'nombre_zona',
			sortable: true,
			maxWidth: '200px',
			right: false
		},

		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning mx-2" to={'usuarios/registrar/' + row.id}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaUsuario(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerUsuarios();
	}, []);

	  const obtenerUsuarios = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.usuarios.listado + '/0')
		  .then(response => {
			  dataArray = response.data.data; 
			  setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }


	const eliminaUsuario = (uuid) => {		
		console.log(uuid);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.usuarios.general, uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Usuario eliminado.',
						  'success'
						);
					}
				obtenerUsuarios();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el usuario.',
					'error'
				  );
				  obtenerUsuarios();
				});

			}
		  })

	};


	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar usuario" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/usuarios/registrar/0" 
				columnasFiltrar={["email","roles","full_name","username","nombre_nivel","nombre_zona","created_at"]}
				exportar={UsuariosExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}

			/>
			<DataTable
				noHeader
				pagination
				defaultSortAsc={false}
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				highlightOnHover
			/>

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Registrar Usuario
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UsuariosRegistrar id_user={state.id_user} />
              </Modal.Body>
            </Modal>
		</BlockUi>
    );
  };
export default UsuariosTabla;
