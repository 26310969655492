import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import Modal from 'react-bootstrap/Modal';
import EditButton from '../../nuup/componentes/Generales/EditButton';
import HuertasSectoresForm from './HuertasSectoresForm';
const HuertasSectoresTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [sector, setSector] = useState([]);
	const [state, setState] = useState({
		id_huerta : props.id_huerta,
		sectores: [],
	});

	const columns = [
		{
			name: 'ID',
			selector: 'id_sector',
			sortable: true,
			right: false
		},

		{
			name: 'SECTOR',
			selector: 'nombre_sector',
			sortable: true,
			right: false
		},

		{
			name: 'TAMAÑO (Has)',
			selector: 'hectareas',
			sortable: true,
			right: false
		},


		{
			name: 'CULTIVO',
			selector: 'nombre_cultivo',
			sortable: true,
			right: false
		},
		{
			name: 'VARIEDAD',
			selector: 'nombre_variedad',
			sortable: true,
			right: false
		},

		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><EditButton onClick={() =>onEditSector(row)} ><i className="fa fa-edit"></i></EditButton> <DeleteButton onClick={() => eliminaSector(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	const onEditSector = (data) => {
		setSector(data);
		setShow(true);
	}



	useEffect(() => {
		obtenerSectores();
	// eslint-disable-next-line react-hooks/exhaustive-deps	
	},[]);

	  const obtenerSectores = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.sectores + '/' + props.id_huerta + '/huerta')
		  .then(response => {
			setBlocking(false);
			hideFormEdit();
			setState({
			  sectores: response.data.data
			});
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaSector = (uuid) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.sectores, uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						hideFormEdit();
						Swal.fire(
						  'Eliminado!',
						  'Sector eliminado.',
						  'success'
						);
					}
				obtenerSectores();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el sector.',
					'error'
				  );
				  obtenerSectores();
				});

			}
		  })

	};


	const showForm = () => {
		setSector([]);
		setShow(true);
	};


	const hideFormEdit = () => {
		setSector([]);
		setShow(false);
	};

	const onChangeElementos = () => {
		obtenerSectores();
	};


	
	const actionsMemo = React.useMemo(() => <><button onClick={() =>showForm()} className="btn btn-secondary">Registrar</button></>, []);

	return (
			<>
			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Registrar / Actualizar sector
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HuertasSectoresForm onChangeElementos={onChangeElementos} id_huerta={props.id_huerta} sector={sector} />
              </Modal.Body>
            </Modal>


		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<DataTable
				pagination
				columns={columns}
				data={state.sectores}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				actions={actionsMemo}
			/>
		</BlockUi>
		</>
    );
  };
export default HuertasSectoresTabla;
