const getPivotArray = (
  dataArray,
  idRowKey,
  rowIndex,
  colIndex,
  dataIndex,
  titleHeader = "",
  idRowIndex,
  idColIndex
) => {
  // Producto comercial (rowIndex, idRowIndex)
  // Productor (colIndex, idColIndex)
  var result = {},
    ret = [];
  var newCols = [];
  for (var i = 0; i < dataArray.length; i++) {
    let id_prod = dataArray[i][idRowIndex] + "|" + dataArray[i][rowIndex];
    if (!result[id_prod]) {
      result[id_prod] = {};
    }
    result[id_prod][dataArray[i][colIndex]] = dataArray[i][dataIndex];

    if (
      newCols.indexOf(
        dataArray[i][idColIndex] + "|" + dataArray[i][colIndex]
      ) == -1
    ) {
      newCols.push(dataArray[i][idColIndex] + "|" + dataArray[i][colIndex]);
    }
  }

  newCols.sort();
  var item = [];
  item.push(titleHeader);
  item.push.apply(item, newCols);
  ret.push(item);

  for (var key in result) {
    item = [];
    item.push(key);
    for (var i = 0; i < newCols.length; i++) {
      let arrProd = newCols[i].split("|");
      let value = result[key][arrProd[1]] || "-";
      value =
        key +
        "|" +
        arrProd[0] +
        "|" +
        arrProd[1] +
        "|" +
        value +
        "|" +
        getId(dataArray, key, arrProd[0] + "|" + arrProd[1]);
      item.push(value);
    }
    ret.push(item);
  }
  return ret;
};

const getId = (data, key, prod) => {
  let id = 0;
  let arrProducto = key.split("|");
  let arrProductor = prod.split("|");

  const result = data.filter(
    (item) =>
      item.id_producto_comercial == arrProducto[0] &&
      item.id_productor == arrProductor[0]
  );

  if (typeof result[0] !== "undefined") {
    id = result[0].id;
  }

  return id;
};

export default getPivotArray;
