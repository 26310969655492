import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const RiegosListadoEstaciones = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'Estación',
			selector: 'nombre_zona',
			sortable: true,
			right: false
		}

	]);


	useEffect(() => {
		obtenerRegistros();
	}, []);

	  const obtenerRegistros = (id_productor = 0)  => {
		setBlocking(true);
		Api.getAll(ApiUrl.riegos.recomendaciones.zonas)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
			  setDatosFiltrados(response.data.data);
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

  const estacionHandler = row  => {
	console.log(row);
    props.onSelectItem(row);
  }

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <DataTable
		  	    noHeader
            columns={columns}
            data={datosFiltrados}
            Clicked
            noDataComponent={'Sin datos por mostrar'}
            customStyles={estiloGeneral}
            onRowClicked={estacionHandler}
          />
		</BlockUi>
    );
  };
export default RiegosListadoEstaciones;