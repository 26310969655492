import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import HuertasSectoresTabla from './HuertasSectoresTabla';
import FormMultiSelect from '../../nuup/componentes/Forms/FormMultiSelect.jsx';
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import selectArrayToInteger from '../../nuup/helpers/selectArrayToInteger';
import selectArrayToArray from '../../nuup/helpers/selectArrayToArray';

const HuertasRegistrar = () => {
	const [blocking, setBlocking] = useState(false);

	const [catalogos, setCatalogos] = useState({});
	const { id_huerta } = useParams();

	const [data] = useState({
		id_huerta : id_huerta,
		uuid : '',
		prop_huerta: [],
		nombre_huerta: '',
		total_hectareas: '',
		id_sistema_riego: {},
		origen_agua: '',
		costo_agua: '',
		certificacion: '',
		id_estado: {},
		id_municipio: {},
		id_localidad: {},
		latitud: '',
		longitud: ''
	});

	const schema = yup.object().shape({
		prop_huerta: yup.array().min(1, 'Requerido').of(
		  yup.object().shape({
			id_productor: yup.number().min(1).required(),
			nombre_productor: yup.string().required(),
		  })
		),
		nombre_huerta :  yup.string().required('Requerido'),
		total_hectareas :  yup.string().required('Requerido')
	});

	const { register, handleSubmit, errors,reset,control } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});

	const selectFinish = (items,key) => {
		setValorCatalogo(items,key);
		if (id_huerta > 0) {obtenerHuerta();}
	};


	const setValorCatalogo = (_data,key) => {
		setCatalogos({...catalogos,'prop_huerta' : _data});
	};

	const obtenerHuerta = () => {
		setBlocking(true);
		setTimeout(function(){ 
			Api.getAll(ApiUrl.huertas + '/' + id_huerta).then(response => {
				let huertaData = response.data.data;
				reset(huertaData[0]);
				setBlocking(false);
				console.log(catalogos);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		 }, 5000);
	}

	const creahuerta = values => {
		setBlocking(true);
		Api.create(ApiUrl.huertas,{
			prop_huerta: selectArrayToArray(values.prop_huerta,'id_productor'),
			nombre_huerta: values.nombre_huerta,
			total_hectareas: values.total_hectareas,
			latitud: values.latitud,
			longitud: values.longitud,
			id_sistema_riego: selectArrayToInteger([values.id_sistema_riego],'id_sistema_riego'),
			origen_agua: values.origen_agua,
			costo_agua: values.costo_agua,
			certificacion: values.certificacion,
			id_estado: selectArrayToInteger([values.id_estado],'id_estado'),
			id_municipio: selectArrayToInteger([values.id_municipio],'id_municipio'),
			id_localidad: selectArrayToInteger([values.id_localidad],'id_localidad'),
		}).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				window.location.href = '/administracion/huertas/registrar/' + _result.data.id_huerta;
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const actualizaHuerta = values => {
		setBlocking(true);
		Api.update(ApiUrl.huertas,values.uuid, {
			prop_huerta: selectArrayToArray(values.prop_huerta,'id_productor'),
			nombre_huerta: values.nombre_huerta,
			total_hectareas: values.total_hectareas,
			latitud: values.latitud,
			longitud: values.longitud,
			id_sistema_riego: selectArrayToInteger([values.id_sistema_riego],'id_sistema_riego'),
			origen_agua: values.origen_agua,
			costo_agua: values.costo_agua,
			certificacion: values.certificacion,
			id_estado: selectArrayToInteger([values.id_estado],'id_estado'),
			id_municipio: selectArrayToInteger([values.id_municipio],'id_municipio'),
			id_localidad: selectArrayToInteger([values.id_localidad],'id_localidad'),
		}).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const onSubmit = values => {
		//setValue('prop_huerta',[{id : 1, username : 'Espinoza'}]);
		if (id_huerta > 0) {
			actualizaHuerta(values);
		} else {
			creahuerta(values);
		}
	}
	useEffect(() => {
		if(id_huerta === '0'){
			reset(data);
		}
	// eslint-disable-next-line
	},[id_huerta]);



	return (
		<>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/huertas">Registrar huerta</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de huertas <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del huertas</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>
					<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
				<div className="col-sm-12">
					<FormMultiSelect 
						id="prop_huerta"
						name="prop_huerta"
						label="Propietrario"
						keyArray="id_productor"
						labelArray="nombre_productor"
						url={ApiUrl.catalogs.formularios.propietarios}
						error={errors.prop_huerta}
						onFinish={(items) => setValorCatalogo(items,'prop_huerta')}
						control={control}
						isMulti={true}
					/>
				</div>

				</div>

	
				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput 
							id="nombre_huerta"
							type="text"
							name="nombre_huerta"
							label="Nombre Huerta"
							error={errors.nombre_huerta}
							register={register}
						/>
					</div>
					<div className="col-sm-6">
						<FormInput 
							id="total_hectareas"
							type="text"
							name="total_hectareas"
							label="Total de hectareas"
							error={errors.total_hectareas}
							register={register}
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput 
							id="latitud"
							type="text"
							name="latitud"
							label="Ubicación Latitud"
							error={errors.latitud}
							register={register}
						/>
					</div>
					<div className="col-sm-6">
						<FormInput 
							id="longitud"
							type="text"
							name="longitud"
							label="Ubicación Longitud"
							error={errors.longitud}
							register={register}
						/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
						<FormMultiSelect 
							id="id_sistema_riego"
							name="id_sistema_riego"
							label="Sistema de riego"
							keyArray="id_sistema_riego"
							labelArray="nombre_sistema_riego"
							url={ApiUrl.catalogs.formularios.sistemasriego}
							error={errors.id_sistema_riego}
							onFinish={(items) => setValorCatalogo(items,'id_sistema_riego')}
							control={control}
							isMulti={false}
						/>
					</div>
	
					<div className="col-sm-3">
						<FormInput 
							id="origen_agua"
							type="text"
							name="origen_agua"
							label="Origen del agua"
							error={errors.origen_agua}
							register={register}
						/>

					</div>
					<div className="col-sm-3">
					<FormInput 
							id="costo_agua"
							type="text"
							name="costo_agua"
							label="Costo del agua"
							error={errors.costo_agua}
							register={register}
						/>

					</div>

					<div className="col-sm-3">
					<FormInput 
							id="certificacion"
							type="text"
							name="certificacion"
							label="Certificación"
							error={errors.certificacion}
							register={register}
						/>

					</div>

				</div>

				<div className="form-group row">
					<div className="col-sm-4">
						<FormMultiSelect 
							id="id_estado"
							name="id_estado"
							label="Estado"
							keyArray="id_estado"
							labelArray="nombre_estado"
							url={ApiUrl.catalogs.formularios.estados}
							error={errors.id_estado}
							onFinish={(items) => setValorCatalogo(items,'id_estado')}
							control={control}
							isMulti={false}
						/>
					</div>
					<div className="col-sm-4">
						<FormMultiSelect 
							id="id_municipio"
							name="id_municipio"
							label="Municipio"
							keyArray="id_municipio"
							labelArray="nombre_municipio"
							url={ApiUrl.catalogs.formularios.municipios}
							error={errors.id_municipio}
							onFinish={(items) => setValorCatalogo(items,'id_municipio')}
							control={control}
							isMulti={false}
						/>
					</div>
					<div className="col-sm-4">
						<FormMultiSelect 
							id="id_localidad"
							name="id_localidad"
							label="Localidad"
							keyArray="id_localidad"
							labelArray="nombre_localidad"
							url={ApiUrl.catalogs.formularios.localidades}
							error={errors.id_localidad}
							onFinish={(items) => selectFinish(items,'id_localidad')}
							control={control}
							isMulti={false}
						/>
					</div>
					</div>

						<FormInput 
							id="uuid"
							type="hidden"
							name="uuid"
							label="Uuid"
							error={errors.uuid}
							register={register}
						/>

						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar huerta</button>
						</div>
					</div>
					</form>
					</BlockUi>

					</PanelBody>
				</Panel>
			</div>
			</div>

			{id_huerta > 0 && (
			<div className="row">
				<div className="col-md-12">

					<Panel>
						<PanelHeader>Sectores</PanelHeader>
						<PanelBody>
							<HuertasSectoresTabla id_huerta={id_huerta} />
						</PanelBody>

					</Panel>
				</div>

			</div>
			)}
		</>
	);
};
export default HuertasRegistrar;