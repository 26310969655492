import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "./../components/panel/panel.jsx";
import FormFiltradoRangoFechas from "../nuup/componentes/Forms/FormFiltradoRangoFechas";
import { format, sub} from "date-fns";
import GraficaAreaGral from "../nuup/componentes/Graficas/GraficaAreaGral";
import GraficaProductoresTotal from "../nuup/componentes/Graficas/GraficaProductoresTotal";
import BarrasTipoBitacora from "../nuup/componentes/Graficas/BarrasTipoBitacora";
import AreaProdPorSemana from "../nuup/componentes/Graficas/AreaProdPorSemana";
import AreaBitPorSemana from "../nuup/componentes/Graficas/AreaBitPorSemana";
import GraficaPastelGral from "../nuup/componentes/Graficas/GraficaPastelGral";
import GraficaBarrasHorizGral from "../nuup/componentes/Graficas/GraficaBarrasHorizGral";
import GraficaBarrasGral from "../nuup/componentes/Graficas/GraficaBarrasGral";
import { GraficasActivos } from "../nuup/componentes/Graficas/GraficasActivos.jsx";
import { AreaProdPorActivoYNivel } from "../nuup/componentes/Graficas/AreaProdPorActivoYNivel.jsx";

const today = new Date();
const threeMonthsAgo = sub(today, { months: 3 });

const Home = () => {
  const [state, setState] = useState({
    fechaInicial: format(threeMonthsAgo, "yyyy-MM-dd"),
    fechaFinal: format(today, "yyyy-MM-dd"),
  });

  const onFilter = (fechas) => {
    setState({
      ...state,
      fechaInicial: format(fechas.fechaInicial, "yyyy-MM-dd"),
      fechaFinal: format(fechas.fechaFinal, "yyyy-MM-dd"),
    });
  };

  return (
    <div>
      <div
        style={{
          position: '-webkit-sticky',
          // eslint-disable-next-line
          position: 'sticky',
          top: '50px',
          backgroundColor: '#fff',
          zIndex: '100',
          borderRadius: '5px',
          padding: '10px',
          color: '#000',
          borderStyle: 'solid',
          borderColor: '#C0C0C0',
          borderWidth: '1px',
          borderTop: '10px solid #000',
        }}
      >
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Active Page</li>
        </ol>
        <h1 className="page-header">
          Dashboard <small>Información general</small>
        </h1>
        <FormFiltradoRangoFechas onFilter={onFilter} />
      </div>
      <hr />

      <Panel>
        <PanelHeader>Productores de Madre Tierra</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-12">
              <h4>Productores en Madre Tierra</h4>
              <GraficaProductoresTotal
                height={400}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="row">
            <div className="col-md-12">
              <AreaProdPorActivoYNivel
                height={400}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <GraficasActivos fechaInicial={state.fechaInicial} fechaFinal={state.fechaFinal} />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader>Bitácoras</PanelHeader>
        <PanelBody>
          <br />

          <div className="row">
            <div className="col-md-12">
              <BarrasTipoBitacora
                // url="graficas/registros_por_tipo_bitacora"
                height={400}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <hr />
          <br />
          <div className="row">
            <div className="col-md-12">
              <AreaProdPorSemana
                height={400}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>        
          <div className="row">
            <div className="col-md-12">
              <AreaBitPorSemana
                height={400}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <hr />
          <br />
          <div className="row">
            <div className="col-md-4">
              <h4>Presiembra</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_presiembras"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Plantación</h4>
              <GraficaBarrasHorizGral
                titulo="Bitácoras registradas"
                url="graficas/totales_bitacoras_siembras"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Riego</h4>
              <GraficaBarrasHorizGral
                titulo="Bitácoras registradas"
                url="graficas/totales_bitacoras_riegos"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <h4>Actividades de Huerta</h4>
              <GraficaBarrasHorizGral
                titulo="Bitácoras registradas"
                url="graficas/totales_bitacoras_mantenimientos"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Cosecha</h4>
              <GraficaBarrasHorizGral
                titulo="Bitácoras registradas"
                url="graficas/totales_bitacoras_cosechas"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Post-Cosecha</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_postcosechas"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <h4>Aplicación de desinfectantes de suelos</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_suelos"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Aplicación de otros productos</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_otros"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Preparación de Tierra</h4>
              <GraficaBarrasHorizGral
                titulo="Bitácoras registradas"
                url="graficas/totales_bitacoras_preparaciontierras"
                height={200}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <h4>Aplicación de fertilizantes</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_fertilizantes"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Aplicación de plaguicidas, pesticidas, fungicidas</h4>
              <GraficaBarrasHorizGral
                titulo="Productos aplicados"
                url="graficas/totales_bitacoras_plaguicidas"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
            <div className="col-md-4">
              <h4>Registros de Ingresos o Egresos</h4>
              <GraficaBarrasHorizGral
                titulo="Ingresos o Egresos"
                url="graficas/totales_bitacoras_ingresos_egresos"
                height={300}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader>Mensajes de clima</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-12">
              <h4>Total abiertos por productor</h4>
              <GraficaBarrasGral
                titulo="Total abiertos"
                url="graficas/avisos_tasa_abiertos_productores"
                height={500}
                fechaInicial={state.fechaInicial}
                fechaFinal={state.fechaFinal}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>

    </div>
  )
};

export default Home;