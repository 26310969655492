const addButton = (value) => {
    let arrData = value.split("|");
    let valor = arrData[4];
    return `<button style="border : 'none',padding : 10;outline: none;border:none;background-color: transparent" onClick="(e) => openModal(e)" value="${value}">${valor}</button>`;
}


const getHeaderCol = (value) => {    
    let result = '';
    let arrData = value.split("|");
    if(arrData.length > 1){
        result = arrData[1];
    }
    if(arrData.length > 2){
        result = arrData[2];
    }
    return result;
}

const arrayToHTMLTable = (myArray) => {
    var result = "<table border='1' cellpadding='7' cellspacing='0'>";
    for (var i = 0; i < myArray.length; i++) {
        if(i == 0){
            result += "<tr style='background-color : #d4d4d4'>";
        }else{
            result += "<tr>";
        }

        for (var j = 0; j < myArray[i].length; j++) {
            if(i == 0 || j == 0){
                result += "<th>" + getHeaderCol(myArray[i][j]) + "</th>";
            }else{
                result += "<td>" + addButton(myArray[i][j]) + "</td>";            }
        }
        result += "</tr>";
    }
    result += "</table>";

    return result;
};

export default arrayToHTMLTable;