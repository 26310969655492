import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import RiegosDiagnosticosRegistrar from './diagnostico/RiegosDiagnosticosRegistrar.jsx';
import RiegosDiagnosticosTabla from './diagnostico/RiegosDiagnosticosTabla.jsx';

const RiegosDiagnosticoInicial = (props) => {
  const [modal, setModal] = useState(false);
  const [idSectorDiagnosticoRiego, setIdSectorDiagnosticoRiego] = useState(0);

  const onComplete = () => {
    props.onUpdateDiagnosticos();
    setModal(false);
  };

  const onUpdateClick = (id_sector_diagnostico_riego) => {
    setIdSectorDiagnosticoRiego(id_sector_diagnostico_riego);
    setModal(true);
  };
  

  return (
    <>
      <div className="row">
        <div className="col col-md-12">
          <div className="row">
            <div className="col col-md-2 offset-md-10">
              <a className='btn btn-info' onClick={() => setModal(!modal)}>Registrar diagnóstico</a>
            </div>
          </div>

          <RiegosDiagnosticosTabla
            diagnosticos={props.diagnosticos}
            onUpdateClick={(id_sector_diagnostico_riego) => onUpdateClick(id_sector_diagnostico_riego)}
            id_ciclo={props.id_ciclo}
            id_productor={props.id_productor}
            onComplete={() => onComplete()}
          />
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Registar / Actualizar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RiegosDiagnosticosRegistrar
            id_ciclo={props.id_ciclo}
            huertas={props.huertas}
            id_sector_diagnostico_riego={idSectorDiagnosticoRiego}
            id_productor={props.id_productor}
            onComplete={() => onComplete()}
          />
        </Modal.Body>
      </Modal>

    </>
  );
};
export default RiegosDiagnosticoInicial;
