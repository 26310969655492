import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from "recharts";
import BlockUi from "react-block-ui";
import Api from "../../../api/global.service";
import { getWeek } from "date-fns";
import { bit_names, getDateRange } from "./DataHelpers";

export const AreaBitPorSemana = ({
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
}) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [labelsTotal, setLabelsTotal] = useState(false);
  const [labelsProm, setLabelsProm] = useState(false);
  

  // insert data of fetched created and deleted users per week in dateRange array
  const insertData = (users, dateRange) => {
    const new_data = dateRange.map((item) => {
      let fetched_data = {};
      fetched_data = users.find((d) => item.yearweek === d.yearweek);
      const current_week_data = {};
      for (const temp_name in bit_names) {
        const bit_name = "total_" + temp_name;
        current_week_data[bit_name] =
          fetched_data && fetched_data[bit_name] ? fetched_data[bit_name] : 0;
      }
      const bitacoras_total = Object.values(current_week_data).reduce(
        (acc, total) => acc + parseInt(total),
        0
      );
      const usuarios =
        fetched_data && fetched_data["total_users"]
          ? parseInt(fetched_data["total_users"])
          : 1;
      const promedio = bitacoras_total / usuarios;
      return {
        ...item,
        ...current_week_data,
        promedio: promedio,
        semana: getWeek(new Date(item.created_at)),
      };
    });
    return new_data;
  };

  useEffect(() => {
    const dateRange = getDateRange(fechaInicial, fechaFinal);
    Api.getAll("graficas/registros_por_semana", {
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
    })
      .then((response) => {
        setData(insertData(response.data.data, dateRange));
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
        console.log("ERROR !!", e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <h2>Total de Bitácoras por semana</h2>
        <div className="col-md-12">
          <button
            onClick={() => setLabelsTotal(!labelsTotal)}
            className={`btn ${labelsTotal ? "btn-secondary" : "btn-light"} `}
          >
            {labelsTotal ? "esconder valores" : "Ver valores"}
          </button>
        </div>
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart width={500} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" iconSize={20} />
            <XAxis
              dataKey="label"
              angle={-90}
              textAnchor="end"
              height={80}
              padding={{ top: 20, left: 15, right: 15 }}
            />
            <YAxis />
            <Legend verticalAlign="top" height={36} />
            <Tooltip />
            <Area
              name="Aplicaciones"
              type="monotone"
              dataKey="total_bit_aplicaciones"
              stackId="1"
              stroke="#4181BC"
              fill="#4181BC"
            >
              {labelsTotal && <LabelList dataKey="total_bit_aplicaciones" position="top" />}
            </Area>
            <Area
              name="PreSiembras"
              type="monotone"
              dataKey="total_bit_pre_siembra_aplicaciones"
              stackId="1"
              stroke="#FF8042"
              fill="#FF8042"
            >
              {labelsTotal && <LabelList dataKey="total_bit_pre_siembra_aplicaciones" position="top" />}
            </Area>
            <Area
              name="Siembras"
              type="monotone"
              dataKey="total_bit_siembras"
              stackId="1"
              stroke="#5A3E03"
              fill="#5A3E03"
            >
              {labelsTotal && <LabelList dataKey="total_bit_siembras" position="top" />}
            </Area>
            <Area
              name="Riegos"
              type="monotone"
              dataKey="total_bit_riegos"
              stackId="1"
              stroke="#7BBD40"
              fill="#7BBD40"
            >
              {labelsTotal && <LabelList dataKey="total_bit_riegos" position="top" />}
            </Area>
            <Area
              name="Mantenimientos"
              type="monotone"
              dataKey="total_bit_mantenimiento"
              stackId="1"
              stroke="#FFC107"
              fill="#FFC107"
            >
              {labelsTotal && <LabelList dataKey="total_bit_mantenimiento" position="top" />}
            </Area>
            <Area
              name="Ventas/Cosechas"
              type="monotone"
              dataKey="total_bit_cosecha_ventas"
              stackId="1"
              stroke="#E93953"
              fill="#E93953"
            >
              {labelsTotal && <LabelList dataKey="total_bit_cosecha_ventas" position="top" />}
            </Area>
            <Area
              name="Preparación Tierra"
              type="monotone"
              dataKey="total_bit_preparacion_tierra"
              stackId="1"
              stroke="#818181"
              fill="#818181"
            >
              {labelsTotal && <LabelList dataKey="total_bit_preparacion_tierra" position="top" />}
            </Area>
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="row">
        <h2>Promedio de bitácoras por usuario por semana</h2>
        <div className="col-md-12">
          <button
            onClick={() => setLabelsProm(!labelsProm)}
            className={`btn ${labelsProm ? "btn-secondary" : "btn-light"} `}
          >
            {labelsProm ? "esconder valores" : "Ver valores"}
          </button>
        </div>
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart width={500} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" iconSize={20} />
            <XAxis
              dataKey="label"
              angle={-90}
              textAnchor="end"
              height={80}
              padding={{ top: 20, left: 15, right: 15 }}
            />
            <YAxis />
            <Legend verticalAlign="top" height={36} />
            <Tooltip />
            <Area
              name="Promedio de bitácoras por usuario por semana"
              type="monotone"
              dataKey="promedio"
              stackId="1"
              stroke="#E93953"
              fill="#E93953"
            >
              {labelsProm && <LabelList dataKey="promedio" position="top" />}
            </Area>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </BlockUi>
  );
};

export default AreaBitPorSemana;
