import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import BlockUi from 'react-block-ui';
import Swal from "sweetalert2";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import GraficaBarrasHorizGral from '../../nuup/componentes/Graficas/GraficaBarrasHorizGral';

const CampaniasEstatus = () => {
  const { id_campania } = useParams()
  const [campania, setCampania] = useState(null)
  const [blocking, setBlocking] = useState(true);
  const [soloProductores, setSoloProductores] = useState(true)


  useEffect(() => {
    obtenerInfoCampania()
  // eslint-disable-next-line react-hooks/exhaustive-deps	 
  },[soloProductores]);

  const obtenerInfoCampania = () => {
    setBlocking(true);
    const params = soloProductores ? '?productores=1' : '';
    Api.getAll(ApiUrl.concursos.campaniaEstatus + '/' + id_campania + params).then(response => {
      let campaniaData = response.data.data;
      setCampania(campaniaData);
      setBlocking(false);
    }).catch(e => {
      setBlocking(false);
      let errorMessage = e
      if (e.response?.status === 404) {
        errorMessage = `No se encontró la campaña con id ${id_campania}`
      }
      console.log(e);
      Swal.fire({
        icon: 'error',
        title: '',
        text: JSON.stringify(errorMessage),
        confirmButtonText: 'Regresar a Listado',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = '/administracion/campanias'; 
        }
      })
    });
}

  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/administracion/campanias">Listado de Campañas</Link>
        </li>
        <li className="breadcrumb-item active">Estatus Campaña</li>
      </ol>
      <BlockUi tag="div" blocking={blocking} renderChildren={true}>
        <div className="jumbotron">
          <h1 className="display-4">
            Estatus - {campania ? campania.campania.nombre_campania : "..."}
          </h1>
          <p className="lead">
            Descripción -{" "}
            {campania ? campania.campania.descripcion_campania : "..."}
          </p>
          <p className="lead">
            Inicio - {campania ? campania.campania.fecha_inicio : "..."}
          </p>
          <p className="lead">
            Fin - {campania ? campania.campania.fecha_final : "..."}
          </p>
          <hr className="my-4" />
          <div className="row col-md-12">
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Registro de aplicación-{" "}
                {campania ? campania.campania.puntos_por_actividad : "..."}
              </p>
            </div>
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Otros registros (cosecha, riego, preparación, plantación/siembra
                y actividad huerta) -{" "}
                {campania ? campania.campania.puntos_por_registro : "..."}
              </p>
            </div>
          </div>
          <div className="row col-md-12">
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Compartir tus registros -{" "}
                {campania ? campania.campania.puntos_por_compartir : "..."}
              </p>
            </div>
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Recomendar a otro productor la APP -{" "}
                {campania ? campania.campania.puntos_por_difundir : "..."}
              </p>
            </div>
          </div>
          <div className="row col-md-12">
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Registro de huerta -{" "}
                {campania ? campania.campania.puntos_por_huertas : "..."}
              </p>
            </div>
          </div>
          <div className="row col-md-12">
            <div className="col-xs-12 col-sm-6">
              <p className="lead">
                Registro de Gastos/Ingresos -{" "}
                {campania ? campania.campania.puntos_por_reg_costos : "..."}
              </p>
            </div>
          </div>
        </div>

        <button
          onClick={() => setSoloProductores(!soloProductores)}
          className={`btn ${soloProductores ? "btn-info" : "btn-default"} `}
        >
          {soloProductores
            ? "Filtrado por Productores"
            : "viendo a TODXS los usuarixs"}
        </button>
        <br />
        <br />

        <Panel>
          <PanelHeader>Puntos acumulados al día de hoy</PanelHeader>
          <PanelBody>
            <div className="row col-md-12"></div>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_totales}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>Premios</PanelHeader>
          <PanelBody>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItems: "stretch",
              }}
            >
              {campania?.concursos.map((c) => {
                return (
                  <div className="card text-center" style={{ width: "18rem" }}>
                    {c.imagen_premio ? (
                      <img
                        className="card-img-top"
                        src={ApiUrl.Url + c.imagen_premio.split(",")[0]}
                        alt="Imagen Premio Concurso"
                      />
                    ) : null}
                    <div className="card-body">
                      <h5 className="card-title">{c.nombre_concurso}</h5>
                      <p className="card-text">{c.descripcion_concurso}</p>
                      <p className="card-text">
                        Fecha Inicio: {c.fecha_inicio}
                      </p>
                      <p className="card-text">Fecha Final: {c.fecha_final}</p>
                      <p className="card-text">
                        Puntos para ganar: {c.puntos_para_ganar}
                      </p>
                      <p className="card-text">
                        Premios Entregados: {c.ganadores.length} de{" "}
                        {c.posibles_ganadores}
                      </p>
                      <ul className="list-group list-group-flush">
                        {c.nombres_ganadores.map((n) => {
                          return <li className="list-group-item">{n}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>Puntos Canjeados</PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_canjeados}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Registro de aplicación{" "}
            {campania ? campania.campania.puntos_por_actividad : "..."} puntos
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_actividad}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Otros registros (riego, preparación,
            plantación/siembra y actividad huerta) (
            {campania ? campania.campania.puntos_por_registro : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_registro}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por registros de Cosecha, (
            {campania ? campania.campania.puntos_por_registro_cosecha : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_registro_cosecha}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Compartir tus registros (
            {campania ? campania.campania.puntos_por_compartir : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_compartir}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Recomendar a otro productor la APP (
            {campania ? campania.campania.puntos_por_difundir : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_difundir}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Registro de huerta (
            {campania ? campania.campania.puntos_por_huertas : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_huertas}
            />
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHeader>
            Puntos por Registro Gastos/Ingresos (
            {campania ? campania.campania.puntos_por_reg_costos : "..."} puntos)
          </PanelHeader>
          <PanelBody>
            <GraficaBarrasHorizGral
              height={800}
              preData={campania?.puntos_por_reg_costos}
            />
          </PanelBody>
        </Panel>
      </BlockUi>
    </div>
  );
}

export default CampaniasEstatus;