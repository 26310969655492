import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from "recharts";
import BlockUi from "react-block-ui";
import Api from "../../../api/global.service";
import { format } from "date-fns";
import { bit_names } from "./DataHelpers";

export const BarrasTipoBitacora = ({
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
}) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);

  const cleanData = (data) => {
    const clean_data = [];
    for (const bit_name in bit_names) {
      const data_recieved = data.find((it) => it.label === bit_name);
      if (data_recieved) {
        clean_data.push({
          name: bit_names[bit_name],
          value: parseInt(data_recieved.total),
        });
      } else {
        clean_data.push({
          name: bit_names[bit_name],
          value: 0,
        });
      }
    }
    return clean_data;
  };

  useEffect(() => {
    Api.getAll("graficas/registros_por_tipo_bitacora", {
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
    })
      .then((response) => {
        const cleanedData = cleanData(response.data.data);
        setData(cleanedData);
        setTotal(cleanedData.reduce((acc, cur) => acc + cur.value, 0));
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
        console.log("ERROR", e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <div className="row">
        <div className="col-md-12">
          <h4>Número de Bitácoras</h4>
          <ResponsiveContainer width="100%" height={height}>
            <BarChart width="100%" height={height} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey="value" fill="#E93953">
                <LabelList dataKey="value" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <br />
          <h4>
            Total de: {total} bitácoras generadas entre el{" "}
            {format(new Date(fechaInicial), "dd MMM yyyy")} y el{" "}
            {format(new Date(fechaFinal), "dd MMM yyyy")}{" "}
          </h4>
        </div>
      </div>
    </BlockUi>
  );
};

export default BarrasTipoBitacora;
