import React, { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const VideosRegistrar = () => {
	const { id_video } = useParams();

	const [data] = useState({
		id_video : id_video,
		uuid: "",
		id_videos_categoria: 0,
		titulo: "",
		descripcion: "",
		url: "",
		destacado: 0,
		orden: 0
	});

	const [blocking, setBlocking] = useState(false);
	const schema = yup.object().shape({
		id_video: yup.number().min(0),
		uuid: yup.string(),
		id_videos_categoria: yup.number().min(1,'Seleccione una categoría'),
		titulo: yup.string().required('Título requerido'),
		descripcion: yup.string().required('Descripción requerido'),
		url: yup.string().required('Url requerido'),
		destacado: yup.number().min(0),
		orden: yup.number().min(0),
	});

	const { register, handleSubmit, errors,reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});

	
	const onFinish = () => {
		if (id_video > 0) {obtenerVideo();}
	}


	const obtenerVideo = () => {
			setBlocking(true);
			Api.getAll(ApiUrl.videos + '/' + id_video).then(response => {
				let videoData = response.data.data;
				reset(videoData[0]);
				console.log(videoData[0]);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
	}

	const creaVideo = values => {
		setBlocking(true);
		Api.create(ApiUrl.videos,values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location = '/administracion/videos'; 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const actualizaVideo = values => {
		setBlocking(true);
		Api.update(ApiUrl.videos,values.uuid, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location = '/administracion/videos'; 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const onSubmit = values => {
		if (id_video > 0) {
			actualizaVideo(values);
		} else {
			creaVideo(values);
		}
	}

	useEffect(() => {
		if(id_video === '0'){
			reset(data);
		}
	// eslint-disable-next-line
	},[id_video]);

	return (
		<>
		<div>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/videos">Registrar video</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de videos <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del video</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

					<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormSelectApi 
							id="id_videos_categoria"
							name="id_videos_categoria"
							label="Categoría"
							url={ApiUrl.catalogs.formularios.categoriasvideos}
							keyArray="id_videos_categoria"
							labelArray="nombre_categoria"
							error={errors.id_videos_categoria}
							onFinish={onFinish}
							register={register}
						/>
					</div>
		
					<div className="col-sm-6">
						<FormInput 
							id="titulo"
							type="text"
							name="titulo"
							label="Título"
							error={errors.titulo}
							register={register}
						/>
					</div>
				</div>



				<div className="form-group row">
					<div className="col-sm-12">
						<FormInput 
							id="descripcion"
							type="text"
							name="descripcion"
							label="Descripción"
							error={errors.descripcion}
							register={register}
						/>
					</div>

				</div>

				<div className="form-group row">
					<div className="col-sm-12">
						<FormInput 
							id="url"
							type="text"
							name="url"
							label="URL: Colocar solo la última parte de la url (ej. 3CHz9Ul6RFQ)"
							error={errors.url}
							register={register}
						/>
					</div>

				</div>

						<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="uuid"
									type="hidden"
									name="uuid"
									label="UUID"
									error={errors.uuid}
									register={register}
								/>
							</div>
						</div>



						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar video</button>
						</div>
					</div>
					</form>
					</BlockUi>
					</PanelBody>
				</Panel>
			</div>
			</div>
	

			</div>
		</>
	);
};
export default VideosRegistrar;
