import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HuertasExportar from './HuertasExportar';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
var dataArray = [];

const HuertasTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'ID',
			selector: 'id_huerta',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
	
		{
			name: 'HUERTA',
			selector: 'nombre_huerta',
			sortable: true,
			maxWidth: '300px',
		},

		{
			name: 'PROPIETARIOS',
			selector: 'propietarios',
			sortable: true,
			maxWidth: '300px',
		},

		{
			name: 'TAMAÑO',
			selector: 'total_hectareas',
			sortable: true,
			maxWidth: '300px',
		},

		{
			name: 'ORIGEN AGUA',
			selector: 'origen_agua',
			sortable: true,
			maxWidth: '300px',
		},
		
		{
			name: 'UBICACIÓN',
			sortable: true,
			maxWidth: '300px',
			cell: (row) => row.latitud ? row.latitud + ', ' + row.longitud : null
		},

		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			maxWidth: '300px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'huertas/registrar/' + row.id_huerta}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaHuerta(row.uuid)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerHuertas();
	}, []);

	  const obtenerHuertas = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.huertas)
		  .then(response => {
			  dataArray = response.data.data; 
			setDatosFiltrados(response.data.data);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }


	const eliminaHuerta = (uuid) => {		
		console.log(uuid);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.huertas, uuid)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Huerta eliminada.',
						  'success'
						);
					}
				obtenerHuertas();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar la huerta.',
					'error'
				  );
				  obtenerHuertas();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar huerta" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/huertas/registrar/0" 
				columnasFiltrar={["id_huerta","nombre_huerta","propietarios","localidad","municipio","origen_agua","created_at"]}
				exportar={HuertasExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}

			/>
			<DataTable
				noHeader
				pagination
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
			/>
		</BlockUi>
    );
  };
export default HuertasTabla;
