import xlsx from "json-as-xlsx";
import moment from 'moment';
const UsuariosExportar = (content) => {
    let columns = [
        { label: 'ID Usuario', value: 'id' },
        { label: 'Rol', value: row => (row.roles) },
        { label: 'Usuario', value: 'username' },
        { label: 'Nombre', value: row => (row.firstname + ' ' + row.lastname1 + ' ' + ((row.lastname2 === null) ? '' : row.lastname2)) }, // Run functions
        { label: 'Genero', value: 'gender' },
        { label: 'Fecha de Nacimiento', value: 'birthdate' },
        { label: 'Nivel', value: 'nombre_nivel' },
        { label: 'Zona', value: 'nombre_zona' },
        { label: 'Fecha registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Usuarios',
        fileName: 'Usuarios_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default UsuariosExportar;
