import xlsx from "json-as-xlsx";
import moment from 'moment';
const PlaguicidasExportar = (content) => {
    let columns = [
        { label: 'ID Plaguicida', value: 'id_producto_comercial' },
        { label: 'Tipo', value: row => (row.tipo.nombre_tipo_producto) }, // Run functions
        { label: 'Nombre', value: 'nombre_producto_comercial' },
        { label: 'Ingredientes activos', value: 'ingredientes_activos' },
        { label: 'Fecha registro', value: 'created_at' }
      ]
      
      let settings = {
        sheetName: 'Plaguicidas',
        fileName: 'Plaguicidas_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default PlaguicidasExportar;
