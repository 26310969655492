import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../../nuup/styles/Tablas.css'
import PlaguicidasExportar from './PlaguicidasExportar';
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const PlaguicidasTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);

	const columns = [
		{
			name: 'ID',
			selector: 'id_producto_comercial',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'TIPO',
			selector: 'tipo.nombre_tipo_producto',
			sortable: true,
			maxWidth: '250px',
		},

		{
			name: 'NOMBRE',
			selector: 'nombre_producto_comercial',
			sortable: true,
			maxWidth: '300px',
		},

		{
			name: 'INGREDIENTES ACTIVOS',
			selector: 'ingredientes_activos',
			sortable: true,
			maxWidth: '300px',
		},
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			maxWidth: '300px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'plaguicidas/registrar/' + row.id_producto_comercial}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaPlaguicida(row.id_producto_comercial)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerPlaguicidas();
	}, []);

	  const obtenerPlaguicidas = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.catalogs.plaguicidas.general)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
			  setDatosFiltrados(response.data.data);
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaPlaguicida = (id_producto_comercial) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				setBlocking(true);
				Api.delete(ApiUrl.catalogs.plaguicidas.general, id_producto_comercial)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						setBlocking(false);
						Swal.fire(
						  'Eliminado!',
						  'Plaguicida eliminado.',
						  'success'
						);
					}
				obtenerPlaguicidas();
				})
				.catch(e => {
					setBlocking(false);
				  Swal.fire(
					'Error!',
					'Error al eliminar el plaguicida.',
					'error'
				  );
				  obtenerPlaguicidas();
				});

			}
		  })

	};
	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar plaguicida" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/catalogos/plaguicidas/registrar/0" 
				columnasFiltrar={["id_producto_comercial","nombre_producto_comercial","ingredientes_activos","formulacion","created_at"]}
				exportar={PlaguicidasExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>
          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default PlaguicidasTabla;
