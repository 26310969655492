import React,{useState} from 'react';
import BlockUi from 'react-block-ui';
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';

const BotonDestacado= (props) => {
	const [blocking, setBlocking] = useState(false);

    const recargarTabla = () => {
        props.recargarTabla();
    };


    const onClick = () => {
        setBlocking(true);
        Api.customUpdate(ApiUrl.videos + '/' + props.uuid +'/destacado').then(response => {
            setBlocking(false);
            recargarTabla();
        }).catch(e => {
			console.log(e);
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return <>
        <BlockUi tag="div" blocking={blocking}>
            <button 
                onClick={() => onClick()}
                className={`btn btn-xs ${props.destacado === '1' ? "btn-info" : "btn-default"} `}
            >
                {(props.destacado === '1') ? 'DESTACADO' : 'DESTACAR'}
            </button>
        </BlockUi>
    </>
};
export default BotonDestacado