import React, { Suspense, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api.js';
import BlockUi from 'react-block-ui';

import RiegosDiagnosticosExportar from '../diagnostico/RiegosDiagnosticosExportar.jsx';
import LecturasTensiometrosExportar from '../lecturastensiometros/LecturasTensiometrosExportar.jsx';
import LecturasCaudalimetrosExportar from '../lecturascaudalimetros/LecturasCaudalimetrosExportar.jsx';
import RecomendacionesExportar from '../recomendaciones/RecomendacionesExportar.jsx';
import LecturasEtosExportar from '../etos/LecturasEtosExportar.jsx';

var dataArray = [];

const RiegosDescargas = () => {
  const [blocking, setBlocking] = useState(false);
  const [tipo, setTipo] = useState('1');
  const [id_ciclo, setIdCiclo] = useState(1);
  const [id_productor, setIdProductor] = useState(0);
  const [ciclos, setCiclos] = useState([]);

  const obtenerRegistros = (url,exportClass)  => {
		setBlocking(true);
		Api.getAll(url + '?id_productor=' + id_productor + '&id_ciclo=' + id_ciclo)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
        exportClass(response.data.data);
			})
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }

  useEffect(() => {
    Api.getAll(ApiUrl.riegos.ciclos).then(response => {
      setCiclos(response.data.data);
    });
  }, []);

  const descargaDatos = () => {
    let url = '';
    let exportClass;
    switch (tipo) {
      case '1':
        url = ApiUrl.riegos.diagnosticos.general;
        exportClass = RiegosDiagnosticosExportar;
        break;
      case '2':
        url = ApiUrl.riegos.lecturasTensiometros.general;
        exportClass = LecturasTensiometrosExportar;
        break;
      case '3':
        url = ApiUrl.riegos.lecturasCaudalimetros.general;
        exportClass = LecturasCaudalimetrosExportar;
        break;
      case '4':
        url = ApiUrl.riegos.recomendaciones.general;
        exportClass = RecomendacionesExportar;
        break;
      case '5':
        url = ApiUrl.riegos.recomendaciones.etos;
        exportClass = LecturasEtosExportar;
      break;
    }
    obtenerRegistros(url,exportClass);
  }


  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Descarga de datos</Link></li>
        <li className="breadcrumb-item active"></li>
      </ol>
      <h1 className="page-header">Descarga de datos<small></small></h1>
      <div className="row">
        <div className="col col-md-12">
          <Panel>
            <PanelHeader><h3>{ }</h3></PanelHeader>
            <PanelBody>
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              <div className="row">
                <div className="col col-md-6 offset-3">
                  <table className='table table-bordered'>
                    <tbody>
                      <tr>
                        <th>Tipo</th>
                        <th>
                        <select className='form-control' onChange={(event) => setTipo(event.target.value)}>
                            <option value="1">Diagnóstico de riego inicial</option>
                            <option value="2">Lectura de tensiómetros</option>
                            <option value="3">Consumo de agua</option>
                            <option value="4">Recomendaciones de riego</option>
                            <option value="5">Lectura ETO</option>
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <th>Ciclo</th>
                        <th>
                          
                          <select className='form-control' onChange={(event) => setIdCiclo(event.target.value)}>
                            {ciclos.length > 0 ? ciclos.map((item, index) => {
                              return (<option key={index} value={item.id_ciclo}>{item.nombre_ciclo}</option>);
                            }
                            ) : (
                              //cargando
                              <option value="1">Cargando...</option>
                            )
                            
                            }
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>
                            <a className='btn btn-info' onClick={() => descargaDatos()}>Descargar</a>
                        </th>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </BlockUi>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};
export default RiegosDescargas;
