import React, { useState, forwardRef, useImperativeHandle } from "react";
import ApiUrl from "../../../constants/Api";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import { publish } from '../.././../components/events';
import Api from '../../../api/global.service';

const ModalAdminCostos = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [idCiclo, setIdCiclo] = useState(0);
  const [path, setPath] = useState('');
  const [id, setId] = useState(0);
  const [idProductor, setIdProductor] = useState(0);
  const [nombreProductor, setNombreProductor] = useState("");
  const [idProductoComercial, setIdProductoComercial] = useState(0);
  const [nombreProductoComercial, setNombreProductoComercial] = useState(0);
  const [costo, setCosto] = useState(0);
  const [errors, setErrors] = useState(null);

  const [data] = useState({
    id: id,
    id_productor: idProductor,
    id_producto_comercial: idProductoComercial,
    costo: costo,
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setPath: (path) => setPath(path),
    setIdCiclo: (id) => setIdCiclo(id),
    setId: (id) => setId(id),
    setIdProductor: (id) => setIdProductor(id),
    setNombreProductor: (nombre_productor) => setNombreProductor(nombre_productor),
    setIdProductoComercial: (id_producto_comercial) => setIdProductoComercial(id_producto_comercial),
    setNombreProductoComercial: (nombre_producto_comercial) => setNombreProductoComercial(nombre_producto_comercial),
    setCosto: (costo) => setCosto(costo),
  }));

  const showHandler = () => {
    setShow(true);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const schema = yup.object().shape({
    id: yup.number().min(0, "Requerido"),
    id_ciclo: yup.number().min(1, "Requerido"),
    id_productor: yup.number().min(1, "Requerido"),
    id_producto_comercial: yup.number().required("Requerido"),
    costo: yup.number().min(1,"Se espera un costo mayor a cero").transform((value) => Number.isNaN(value) ? null : value ).nullable().required('Campo requerido')
  });

  const updateItem = (id,data) => {
    Api.update(path,id,data).then(response => {
      setBlocking(false);
      setShow(false);
      publish('AdminCosto::onSave', data);
    }).catch(e => {
      setBlocking(false);
    });
  }

  const createItem = (data) => {
    Api.create(path, data).then(response => {
      setBlocking(false);
      setShow(false);
      publish('AdminCosto::onSave', data);
    }).catch(e => {
      setBlocking(false);
    });
  }


  const validateHandler = () => {
    var data = {			 
        id: id,
        id_productor: idProductor,
        id_producto_comercial: idProductoComercial,
        costo: costo
    }	
    var that = this;		
    schema.validate(data, { abortEarly: false })
        .then(function (valid) {
            //Datos validos
            const data = {
                id: id, 				
                id_ciclo: idCiclo,	
                id_productor: idProductor, 					
                id_producto_comercial: idProductoComercial, 
                costo: costo
            }

            if(id > 0){
              updateItem(id,data);
            }else{
              createItem(data);
            }

        }).catch(function (err) {
            let errors = err.inner.reduce(function(map, obj) {
                map[obj.path] = obj.message;
                return map;
            }, {});
            setErrors(errors);
        });
    
}

  return (
    <div
      className="modal"
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <div
        className="modal-dialog"
        style={{
          top: 50,
          right: 25,
          position: "absolute",
          width: 400,
          height: "calc(100% - 100px)",
        }}
      >
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header" style={{ flex: "inherit" }}>
            <h4 className="modal-title">{nombreProductoComercial + ' | ' + nombreProductor}</h4>
            <button
              onClick={() => closeHandler()}
              type="button"
              className="btn"
              style={{ borderRadius: "20px 20px 20px 20px" }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">

              {props.historial.length > 0 && (
                <>
                  <div style={{color : '#666',fontWeight : 'bold',backgroundColor : '#d4d4d4',paddingLeft : 5,paddingTop : 10,paddingBottom : 10,marginBottom : 5}}>Historial de cambios</div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Fecha actualización</th>
                        <th>Vigencia</th>
                        <th>costo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.historial.map(function(item, i){
                        return (<tr><td>{item.created_at}</td><td>{item.fecha_inicio + ' / ' + item.fecha_fin}</td><td>{item.costo}</td></tr>)
                        })}
                    </tbody>
                  </table>
                </>
              )}

              <div className="form-group row">
                <div className="col-sm-12">
                <input
                      type="hidden"
                      name="id_ciclo"
                      className="form-control"
                      value={idCiclo}
                    />

                <input
                      type="hidden"
                      name="id_productor"
                      className="form-control"
                      value={idProductor}
                    />
                </div>
                <div className="col-sm-12">
                <input
                      type="hidden"
                      name="id_producto_comercial"
                      className="form-control"
                      value={idProductoComercial}
                    />
                </div>
                <div className="col-12">
                <p>{'Costo actual'}</p>
                  <div className="input-group">
                    <input
                      type="number"
                      name="costo"
                      className="form-control"
                      placeholder="Escriba un costo"
                      value={costo}
                      onChange={(event) => {
                        setCosto(event.target.value);
                      }}
                    />
                  </div>
                  {errors?.costo && (
                    <div style={{color: 'red',paddingTop : 10,paddingLeft :5}} >{errors?.costo}</div>
                  )}
                </div>
              </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: "20px 20px 20px 20px" }}
                >
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: "20px 20px 20px 20px" }}
                >
                  <i className="fa fa-save"></i> Guardar costo
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalAdminCostos;
