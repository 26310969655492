import React, { useEffect, useState } from 'react';
import RiegosDiagnosticoInfo from './diagnostico/RiegosDiagnosticoInfo.jsx';
import Modal from 'react-bootstrap/Modal';
import RecomendacionesRegistrar from './recomendaciones/RecomendacionesRegistrar.jsx';
import RecomendacionesTabla from './recomendaciones/RecomendacionesTabla.jsx';

const RiegosRecomendaciones = (props) => {
  const [modal, setModal] = useState(false);
  const [idRiegoRecomendacion, setIdRiegoRecomendacion] = useState(0);
  const [reload, setReload] = useState(0);

  const onComplete = () => {
    setReload(reload + 1);
    setModal(false);
  };

  const onUpdateClick = (id_recomendacion_riego) => {
    setIdRiegoRecomendacion(id_recomendacion_riego);
    setModal(true);
  };



  return (
    <>

      <div className="row">
        <div className="col col-md-12">
          <RiegosDiagnosticoInfo id_productor={props.id_productor} diagnostico_inicial={props.diagnostico_inicial} />

          <div className="row">
            <div className="col col-md-2 offset-md-10">
              <a className='btn btn-info' onClick={() => setModal(!modal)}>Registrar lectura</a>
            </div>
          </div>

          <RecomendacionesTabla
            onUpdateClick={(id_recomendacion_riego) => onUpdateClick(id_recomendacion_riego)}
            id_productor={props.id_productor}
            id_ciclo={props.id_ciclo}
            reload={reload}
          />
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Registar / Actualizar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RecomendacionesRegistrar
            id_ciclo={props.id_ciclo}
            huertas={props.huertas}
            etapas={props.etapas}
            lecturasEtos={props.lecturasEtos}
            diagnosticos={props.diagnosticos}
            id_recomendacion_riego={idRiegoRecomendacion}
            id_productor={props.id_productor}
            onComplete={(result) => onComplete(result)}
            nombre_productor={props.nombre_productor}
          />
        </Modal.Body>
      </Modal>

    </>
  );
};
export default RiegosRecomendaciones;
