import React, { useState,useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Api from '../../../api/global.service';
import conviertArray from '../../helpers/conviertArray'; 
import BlockUi from 'react-block-ui';

const FormMultiSelect = (props) => {
  const isMulti = (typeof props.isMulti !== 'undefined') ? props.isMulti : false; 
  const [blocking, setBlocking] = useState(false);
  const [state, setState] = useState({
		items : [],
        blocking: true
	});

  const onFinish = (_items) => {
    setBlocking(false);
    props.onFinish(_items);
};

useEffect(() => {
  setBlocking(true);
    Api.getAll(props.url).then(response => {
        let _items = conviertArray(response.data.data);
        setState({items : _items,blocking : false});
        onFinish(_items);
    }).catch(e => {
  console.log(e);
    });
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);



  return (
    <>
          <label>{props.label}</label>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <Controller
              as={ <Select 
                placeholder="" 
                onChange={ ([option]) => option.value }
                name={props.name}
                isClearable
                isMulti={isMulti}
                className={`${props.error ? "is-invalid selectError" : "select"}`}
                getOptionLabel ={(option)=>option[props.labelArray]}
                getOptionValue ={(option)=>option[props.keyArray]}

              />}
              name={props.name}
              options={state.items}
              control={props.control}
            />     
            </BlockUi> 
        {props.error && <div className="invalid-feedback">{props.error.message}</div>}
    </>
  );
};

export default FormMultiSelect;