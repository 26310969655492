import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const FertilizantesNutrientesForm = (props) => {
		const schema = yup.object().shape({
			uuid: yup.string(),
			id_producto_comercial: yup.number().min(0),
			id_nutriente: yup.number().min(1),
			porcentaje: yup.number('Porcentaje requerido').min(1,'Porcentaje requerido').required('Porcentaje requerido'),
			otros_nutrientes: yup.string(),
		});

		const [blocking, setBlocking] = useState(true);
		const [btncancelar, setBtncancelar] = useState(false);
		const [state] = useState({
			id : (props.nutriente.id > 0) ? props.nutriente.id : 0,
			id_producto_comercial : props.id_producto_comercial,
			id_nutriente : props.nutriente.id_nutriente,
			porcentaje : props.nutriente.porcentaje,
			otros_nutrientes : props.nutriente.otros_nutrientes
		});
		const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: state
	});

	
	const onSubmit = values => {
		if(values.id > 0){
			actualizarNutriente(values);
		}else{
			crearNutriente(values);
		}
	}

	
	const crearNutriente = (values) => {
		Swal.showLoading();
			Api.create(ApiUrl.catalogs.fertilizantes.nutrientes,{
				id : values.id,
				id_producto_comercial : props.id_producto_comercial,
				id_nutriente : values.id_nutriente,
				porcentaje : values.porcentaje,
				otros_nutrientes : values.otros_nutrientes
			}).then(response => {
				reset(state);
				cancelarEdit();

				let _result = response.data;
				if(_result.success === 1){
					Swal.close();
					Swal.fire(
						'Guardado!',
						'Nutriente creado.',
						'success'
					);
					props.onChangeElementos();
				}
			}).catch(e => {
				Swal.fire(
				'Error!',
				'Error al crear el nutriente.',
				'error'
				);
			});
			
		}
		

		const actualizarNutriente = values => {
			Swal.showLoading();
			console.log('actualizarNutriente');
			console.log(values);
			Api.update(ApiUrl.catalogs.fertilizantes.nutrientes,values.id, values).then(response => {
				let _result = response.data;
				if(_result.success === 1){
					cancelarEdit();
					Swal.fire({
						icon: 'success',
						title: '',
						text: _result.message,
					  })
					  props.onChangeElementos();
				}else{
					Swal.fire({
						icon: 'error',
						title: '',
						text: _result.message,
					  })
				}
			}).catch(e => {
				Swal.fire({
					icon: 'error',
					title: '',
					text: e,
				  })
			});
		}

		
		const cancelarEdit = () => {
			setBtncancelar(false);
			reset({
				id : props.nutriente.id,
				id_producto_comercial : props.nutriente.id_producto_comercial,
				id_nutriente : 0,
				porcentaje : '',
				otros_nutrientes : '',
			});
		}

		const selectFinish = () => {
			reset(props.nutriente);
			setBlocking(false);
		};

	return (
		<> 
			<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormInput 
					id="id"
					type="hidden"
					name="id"
					label="id"
					error={errors.id}
					register={register}
					/>
			
			<FormInput 
					id="uuid"
					type="hidden"
					name="uuid"
					label="uuid"
					error={errors.uuid}
					register={register}
					/>

			<FormInput 
					id="id_producto_comercial"
					type="hidden"
					name="id_producto_comercial"
					label="id_producto_comercial"
					error={errors.id_producto_comercial}
					register={register}
					/>


			<div className="row">

			<div className="col-md-4">
				<FormSelectApi 
					id="id_nutriente"
					name="id_nutriente"
					label="Nutriente"
					url="catalogos/nutrientes"
					keyArray="id_nutriente"
					labelArray="nombre_nutriente"
					error={errors.unidad}
					onFinish={selectFinish}
					register={register}
				/>
			</div>

			<div className="col-md-4">
				<FormInput 
					id="porcentaje"
					type="number"
					name="porcentaje"
					label="PORCENTAJE"
					step="0.01"
					error={errors.porcentaje}
					register={register}
					/>
			</div>



			<div className="col-md-4">
				<FormInput 
				id="otros_nutrientes"
				type="text"
				name="otros_nutrientes"
				label="OTROS NUTRIENTES"
				error={errors.otros_nutrientes}
				register={register}
				/>
			</div>

			</div>

	
	
				<div className="row" >
				<div className="col-md-4 offset-md-8">
					<div className="row" >
						<div className="col-md-12">
							<button className="btn btn-primary btn-block" type="submit">{(btncancelar) ? 'Actualizar' : 'Guardar'}</button>
							{btncancelar && (
								<button className="btn btn-warning btn-block" onClick={cancelarEdit}>Cancelar</button>
							)}
						</div>
	
					</div>
				</div>
				</div>
			</form>		
			</BlockUi>
		</>
	);
};
export default FertilizantesNutrientesForm;