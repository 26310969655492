import React from "react";

const RecomendacionRiegoHtml = (props) => {
    return (
        <p>
            <p>{'💧🍓 Previsión semanal de riego del ' + props.fecha + ' ' + props.fecha_fin + ' para ' + props.nombre_productor}</p>
            <p>📡 <strong>Estación meteorológica:</strong> {props.nombre_zona} </p>
            <p>🍃 <strong>Etapa del cultivo:</strong> {props.nombre_etapa} </p>
            <p><strong>ET0: {props.et0} mm</strong>🌡 (Evapotranspiración de la planta)</p>
            <p class="ql-align-justify">💧 <strong>RIEGO:</strong> Se estiman unas necesidades de riego semanal de: <strong>{props.horas_riego} horas divididas en los riegos de esta semana (Consideré la previsión climática)</strong></p> 
            <p class="ql-align-justify">🗓 <strong>Intervalos de riego:</strong> se recomienda <strong>dividir el tiempo de riego</strong> en el mayor número de riegos posibles en la semana por las altas temperaturas ☀ </p>
            <p class="ql-align-justify">🌱 <strong>CONTENIDO HUMEDAD EN EL SUELO DE SU HUERTA (TENSIOMETRO)</strong> 👇🏻 </p>
        </p>
        )
}

export default RecomendacionRiegoHtml;