import xlsx from "json-as-xlsx";
import moment from 'moment';
const HuertasExportar = (content) => {
    //{ label: 'Age', value: row => (row.age + ' years') }, // Run functions
    //{ label: 'Phone', value: row => (row.more ? row.more.phone || '' : '') }, // Deep props
    let columns = [
        { label: 'ID Huerta', value: 'id_huerta' },
        { label: 'Huerta', value: 'nombre_huerta' },
        { label: 'Propietarios', value: 'propietarios' },
        { label: 'Tamaño', value: 'total_hectareas' },
        { label: 'Origen del agua', value: 'origen_agua' },
        { label: 'Fecha registro', value: 'created_at' },
        { label: 'Latitud', value: 'latitud' },
        { label: 'Longitud', value: 'longitud' }
      ]
      
      let settings = {
        sheetName: 'Huertas',
        fileName: 'Huertas_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      let download = true;
      xlsx(columns, content, settings, download);
  };
  export default HuertasExportar;
