import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import FormInput from "../../nuup/componentes/Forms/FormInput.jsx";
import MoistureGraph from "./MoistureGraph.jsx";
import FormCheckbox from "../../nuup/componentes/Forms/FormCheckbox.jsx";
import { format, startOfMonth, lastDayOfMonth } from "date-fns";

const today = new Date();

const ArablePilot = () => {
  const [data, setData] = useState({
    limite_humedad: localStorage.getItem("limite_humedad") || 19,
    limite_seco: localStorage.getItem("limite_seco") || 14,
    avg_10cm: true,
    avg_20cm: true,
    avg_30cm: true,
    fechaInicial:
      localStorage.getItem("fechaInicial") ||
      format(startOfMonth(today), "yyyy-MM-dd"),
    fechaFinal:
      localStorage.getItem("fechaFinal") ||
      format(lastDayOfMonth(today), "yyyy-MM-dd"),
  });

  const { register, handleSubmit, errors, getValues } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        limite_humedad: yup.number().min(yup.ref("limite_seco")).max(50),
        limite_seco: yup.number().min(0).max(yup.ref("limite_humedad")),
        fechaInicial: yup.date().required("Fecha inicial es requerido"),
        fechaFinal: yup.date().required("Fecha final es requerido"),
      })
    ),
    mode: "onBlur",
    defaultValues: {
      avg_10cm: true,
      avg_20cm: true,
      avg_30cm: true,
      limite_humedad: localStorage.getItem("limite_humedad") || 19,
      limite_seco: localStorage.getItem("limite_seco") || 14,
      fechaInicial:
        localStorage.getItem("fechaInicial") ||
        format(startOfMonth(today), "yyyy-MM-dd"),
      fechaFinal:
        localStorage.getItem("fechaFinal") ||
        format(startOfMonth(today), "yyyy-MM-dd"),
    },
  });

  const onConfirm = () => {
    const values = getValues();
    setData({
      limite_humedad: values.limite_humedad,
      limite_seco: values.limite_seco,
      avg_10cm: values.avg_10cm,
      avg_20cm: values.avg_20cm,
      avg_30cm: values.avg_30cm,
      fechaInicial: values.fechaInicial,
      fechaFinal: values.fechaFinal,
    });
    localStorage.setItem("limite_humedad", values.limite_humedad);
    localStorage.setItem("limite_seco", values.limite_seco);
    localStorage.setItem("fechaInicial", values.fechaInicial);
    localStorage.setItem("fechaFinal", values.fechaFinal);
  };

  return (
    <Panel>
      <PanelHeader>Piloto ARABLE</PanelHeader>
      <PanelBody>
        <div className="row">
          <form onSubmit={handleSubmit(onConfirm)} className="container mb-5">
            <div className="row pl-3">
              <div className="col-md-4">
                <div className="row">
                  <FormInput
                    id="fechaInicial"
                    type="date"
                    name="fechaInicial"
                    label="Fecha Inicial"
                    error={errors.fechaInicial}
                    register={register}
                  />
                </div>
                <div className="row">
                  <FormInput
                    id="fechaFinal"
                    type="date"
                    name="fechaFinal"
                    label="Fecha Final"
                    error={errors.fechaFinal}
                    register={register}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <FormInput
                    id="limite_humedad"
                    type="number"
                    name="limite_humedad"
                    label="Capacidad de Campo"
                    error={errors.limite_humedad}
                    register={register}
                  />
                </div>
                <div className="row">
                  <FormInput
                    id="limite_seco"
                    type="number"
                    name="limite_seco"
                    label="Límite de Recarga Inferior"
                    error={errors.limite_seco}
                    register={register}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <FormCheckbox
                    id="avg_10cm"
                    name="avg_10cm"
                    label="10 cm Avg"
                    error={errors.avg_10cm}
                    register={register}
                  />
                  <FormCheckbox
                    id="avg_20cm"
                    name="avg_20cm"
                    label="20 cm Avg"
                    error={errors.avg_20cm}
                    register={register}
                  />
                  <FormCheckbox
                    id="avg_30cm"
                    name="avg_30cm"
                    label="30 cm Avg"
                    error={errors.avg_30cm}
                    register={register}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <button className="btn btn-primary btn-block" type="submit">
                Actualizar Valores
              </button>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h4>Piloto Ramiro - DIARIO</h4>
            <MoistureGraph
              limite_seco={data.limite_seco}
              limite_humedad={data.limite_humedad}
              avg_10cm={data.avg_10cm}
              avg_20cm={data.avg_20cm}
              avg_30cm={data.avg_30cm}
              fechaInicial={data.fechaInicial}
              fechaFinal={data.fechaFinal}
              url={`https://api-user.arable.cloud/api/v2/sites/data/sentek_daily?temp=C&pres=mb&size=mm&speed=mps&site_id=6126db609784c101dcda113a&limit=1000&start_time=${data.fechaInicial}&end_time=${data.fechaFinal}&local_time=America/Mexico_City`}
            />
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="row">
          <div className="col-md-12">
            <h4>Piloto Ramiro - POR HORA</h4>
            <MoistureGraph
              limite_seco={data.limite_seco}
              limite_humedad={data.limite_humedad}
              avg_10cm={data.avg_10cm}
              avg_20cm={data.avg_20cm}
              avg_30cm={data.avg_30cm}
              fechaInicial={data.fechaInicial}
              fechaFinal={data.fechaFinal}
              url={`https://api-user.arable.cloud/api/v2/sites/data/sentek_hourly?temp=C&pres=mb&size=mm&speed=mps&site_id=6126db609784c101dcda113a&limit=1000&start_time=${data.fechaInicial}&end_time=${data.fechaFinal}&local_time=America/Mexico_City`}
            />
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
};

export default ArablePilot;
