import xlsx from "json-as-xlsx";
import moment from 'moment';
const LecturasCaudalimetrosExportar = (content) => {
    let columns = [
        { label: 'id_sector_lectura_caudalimetro', value: 'id_sector_lectura_caudalimetro' },
        { label: 'id_productor', value: 'id_productor' },
        { label: 'Productor', value: row => (row.productor_nombres + ' ' + row.productor_apellido_paterno + ' ' + ((row.productor_apellido_materno === null) ? '' : row.productor_apellido_materno)) },
        { label: 'id_huerta', value: 'id_huerta' },
        { label: 'nombre_huerta', value: 'nombre_huerta' },
        { label: 'superficie', value: 'superficie' },
        { label: 'fecha_lectura', value: 'fecha_lectura' },
        { label: 'evento', value: 'evento' },
        { label: 'valor', value: 'Lectura caudalímetro Tratamiento (m3)	' },
        { label: 'Incremento consumo en cintilla (m3)', value: 'incremento_consumo' },
        { label: 'Total acumulado por cintilla (m3)', value: 'total_acumulado' },
        { label: 'Consumo de agua acumulado del Sector 1 (m3/sector)', value: 'consumo_acumulado' },
        { label: 'Consumo de agua acumulado equivalente por hectárea (m3/ha)', value: 'consumo_acumulado_hectarea' },
        { label: 'etapa', value: 'nombre_etapa' },
        { label: 'Costo energético calculado', value: 'costo_energetico' },
        { label: 'id_tecnico', value: 'id_tecnico' },
        { label: 'Técnico', value: row => (row.tecnico_nombres + ' ' + row.tecnico_apellido_paterno + ' ' + ((row.tecnico_apellido_materno === null) ? '' : row.tecnico_apellido_materno)) },
        { label: 'created_at', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'LecturasCaudalimetros',
        fileName: 'LecturasCaudalimetros_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default LecturasCaudalimetrosExportar;
