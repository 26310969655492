import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import { useEffect } from "react";

const PlaguicidasTabla = (props) => {
  const [datosFiltrados, setDatosFiltrados] = useState([]);

  const columns = [
    {
      name: "PLAGUICIDA",
      selector: "nombre_producto_comercial",
      sortable: true,
      maxWidth: '275px'
    },
    {
      name: "OPCIONES",
      button: true,
      cell: (row) => {
        if (row.existe === 0) {
          return (
            <button
              className="btn btn-warning"
              onClick={() => props.onAgrega(row.id_producto_comercial)}
            >
              <i className="fa fa-plus"></i>
            </button>
          );
        }
        if (row.existe === 1) {
          return (
            <button
              className="btn btn-danger"
              onClick={() => props.onQuita(row.id_producto_comercial)}
            >
              <i className="fa fa-trash"></i>
            </button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    setDatosFiltrados(props.data);
  }, [props.data]);

  return (
    <>
      <BlockUi
        tag="div"
        message=""
        blocking={datosFiltrados.length === 0}
        renderChildren={true}
      >
        <DataTable
          pagination
          columns={columns}
          data={datosFiltrados}
          noDataComponent={"Sin datos por mostrar"}
        />
      </BlockUi>
    </>
  );
};
export default PlaguicidasTabla;
