import React from "react";

const RADIAN = Math.PI / 180;
export const renderLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  label,
  value,
  fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.15;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      fontSize={16}
      x={x}
      y={y}
      fill={fill}
      strokeWidth={3}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {label + " (" + value + ")"}
    </text>
  );
};

export default renderLabel;
