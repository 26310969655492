import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import BlockUi from "react-block-ui";
import { cleanData } from "./DataHelpers";
import { Colores } from "./Colores";
import useFetchGraphData from "./useFetchGraphData";

export const GraficaBarrasHorizGral = ({
  url,
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
  dataProcessing = cleanData,
  preData = null,
}) => {
  const [blocking, data] = useFetchGraphData(url, fechaInicial, fechaFinal, dataProcessing, preData);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={500}
          height={300}
          data={data}
          layout="vertical"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <YAxis dataKey="label" type="category" width={150} />
          <XAxis type="number" />
          <Tooltip />
          <Bar dataKey="total" fill="#000">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={Colores[index % Colores.length]}
              />
            ))}
            <LabelList dataKey="total" fill={"#000"} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </BlockUi>
  );
};

export default GraficaBarrasHorizGral;
