import React, { useState, forwardRef, useImperativeHandle,useEffect } from "react";
import ApiUrl from "../../../constants/Api";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import { publish } from '../.././../components/events';
import Api from '../../../api/global.service';
import FertilizantesForm from '../../catalogos/fertilizantes/FertilizantesForm';

const ModalNuevoProductoComercialFertilizante = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [idCiclo, setIdCiclo] = useState(0);
  const [path, setPath] = useState('');
  const [id, setId] = useState(0);
  const [idProductor, setIdProductor] = useState(0);
  const [productoresFaltantes, setProductoresFaltantes] = useState([]);
  const [idProductoComercial, setIdProductoComercial] = useState(0);
  const [nombreProductoComercial, setNombreProductoComercial] = useState(0);
  const [costo, setCosto] = useState(0);
  const [errors, setErrors] = useState(null);

  const [data] = useState({
    id: id,
    id_productor: idProductor,
    id_producto_comercial: idProductoComercial,
    costo: costo,
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdCiclo: (id) => setIdCiclo(id),
    setProductoresFaltantes: (productoresFaltantes) => setProductoresFaltantes(productoresFaltantes),
    setPath: (path) => setPath(path)
  }));

  const showHandler = (show = true) => {
    setShow(true);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
    setErrors(null);
  };

  const onFinish = (data) => {
    setShow(false);
    setErrors(null);
    publish(props.publish,data);
  };

  return (
    <div
      className="modal"
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <div
        className="modal-dialog"
        style={{
          top: 50,
          right: 25,
          position: "absolute",
          width: 400,
          height: "calc(100% - 100px)",
        }}
      >
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header" style={{ flex: "inherit" }}>
            <h4 className="modal-title">{props.title}</h4>
            <button
              onClick={() => closeHandler()}
              type="button"
              className="btn"
              style={{ borderRadius: "20px 20px 20px 20px" }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <FertilizantesForm onFinish={(data) => onFinish(data)} redirect={props.redirect} />
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalNuevoProductoComercialFertilizante;
