import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectData from '../../../nuup/componentes/Forms/FormSelectData.jsx';
import FormSelectApiNested from '../../../nuup/componentes/Forms/FormSelectApiNested.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const RiegosDiagnosticosRegistrar = (props) => {
	const [blocking, setBlocking] = useState(false);
	const { id_sector_diagnostico_riego, id_productor,id_ciclo } = props;
	const [idProductor, setIdProductor] = useState(id_productor);
	const [idHuerta, setIdHuerta] = useState(0);
	const [infoRefistro, setInfoRefistro] = useState({});

	

	const [data] = useState({
		id_sector_diagnostico_riego: id_sector_diagnostico_riego,
		uuid: "",
		id_huerta: "",
		id_sector: "",
		id_productor: id_productor,
		id_ciclo: id_ciclo,
		fecha_registro: "",
		no_sectores: "",
		camas_no: "",
		camas_largo: "",
		camas_ancho: "",
		camas_distancha: "",
		no_cintillas_cama: "",
		distancia_goteros: "",
		distancia_fuente: "",
		tipo_inyector: "",
		marca_cintilla: "",
		tipo_gotero: "",
		gasto_optimo: "",
		presion_recomendada: "",
		volumen_pozo: "",
		pi_tiempo: "",
		pi_presion: "",
		pi_volumen: "",
		pf_tiempo: "",
		pf_presion: "",
		pf_volumen: "",
		ui_tiempo: "",
		ui_presion: "",
		ui_volumen: "",
		uf_tiempo: "",
		uf_presion: "",
		uf_volumen: "",
		costo_m3: "",
		comentarios: ""
	});


	const schema = yup.object().shape({
		id_sector_diagnostico_riego: yup.number().min(0),
		uuid: yup.string(),
		id_huerta: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_sector: yup.number().integer().transform(value => (isNaN(value) ? undefined : value)),
		id_productor: yup.number().integer().min(1, 'Requerido').required('Requerido'),
		id_ciclo: yup.number().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		fecha_registro: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Requerido'),
		no_sectores: yup.number().min(1, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		camas_no: yup.number().min(1, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		camas_largo: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		camas_distancha: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		no_cintillas_cama: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		distancia_goteros: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		distancia_fuente: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		tipo_inyector: yup.string().required('Requerido'),
		marca_cintilla: yup.string().required('Requerido'),
		tipo_gotero: yup.string(),
		gasto_optimo: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		presion_recomendada: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		volumen_pozo: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		pi_tiempo: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		pi_presion: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		pi_volumen: yup.number().min(0.001, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		pf_tiempo: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		pf_presion: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		pf_volumen: yup.number().min(0.001, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		ui_tiempo: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		ui_presion: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		ui_volumen: yup.number().min(0.001, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		uf_tiempo: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		uf_presion: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		uf_volumen: yup.number().min(0.001, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		costo_m3: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		comentarios: yup.string()
	});


	const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});



	const selectFinish = () => {
		if (id_sector_diagnostico_riego > 0) { obtenerRegistro(); }
	};

	const obtenerRegistro = () => {
		setBlocking(true);
		setTimeout(function () {
			Api.getAll(ApiUrl.riegos.diagnosticos.general + '/' + id_sector_diagnostico_riego + '/show').then(response => {
				let diagnosticoData = response.data.data;
				reset(diagnosticoData[0]);
				setInfoRefistro(diagnosticoData[0]);
				setIdProductor(diagnosticoData[0].id_productor);
				setIdHuerta(diagnosticoData[0].id_huerta);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		}, 5000);
	}

	const creaRegistro = values => {
		setBlocking(true);
		Api.create(ApiUrl.riegos.diagnosticos.general, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				onComplete();
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}

	const actualizaRegistro = values => {
		setBlocking(true);
		Api.update(ApiUrl.riegos.diagnosticos.general, id_sector_diagnostico_riego, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				})
				onComplete();
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}

	const onSubmit = values => {
		if (id_sector_diagnostico_riego > 0) {
			actualizaRegistro(values);
		} else {
			creaRegistro(values);
		}

	}

	useEffect(() => {
		if (id_sector_diagnostico_riego === '0') {
			reset(data);
		} else {
			obtenerRegistro();
		}
		// eslint-disable-next-line
	}, [id_sector_diagnostico_riego]);

	const onComplete = () => {
		props.onComplete();
	};



	return (
		<>
			<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

				<form onSubmit={handleSubmit(onSubmit)}>

					<div className="form-group row">

						<FormInput
							id="id_productor"
							type="hidden"
							name="id_productor"
							label="Productor"
							error={errors.id_productor}
							register={register}
						/>

						<FormInput
							id="id_ciclo"
							type="hidden"
							name="id_ciclo"
							label="Ciclo"
							error={errors.id_ciclo}
							register={register}
						/>


						<div className="col-sm-4">
							<FormInput
								id="fecha_registro"
								type="date"
								name="fecha_registro"
								label="Fecha de registro"
								error={errors.fecha_registro}
								register={register}
							/>
						</div>

						<div className="col-sm-4">
							<FormSelectData
								id="id_huerta"
								name="id_huerta"
								label="Huerta"
								keyArray="id_huerta"
								labelArray="nombre_huerta"
								items={props.huertas}
								error={errors.id_huerta}
								register={register}
								onChange={(value) => setIdHuerta(value)}
							/>
						</div>

						<div className="col-sm-4">
							<FormSelectApiNested
								id="id_sector"
								name="id_sector"
								label="Sector"
								url={ApiUrl.riegos.sectoresproductor}
								search={{ key: 'id_huerta', value: idHuerta }}
								valueSearch={idHuerta}
								keyArray="id_sector"
								labelArray="datos_sector"
								error={errors.id_sector}
								register={register}
								onFinish={() => selectFinish()}
								onChange={(value) => { }}
							/>

						</div>

					</div>


					<div className="form-group row">

					<div className="col-sm-3">
							<FormInput
								id="no_sectores"
								type="number"
								name="no_sectores"
								label="No. de sectores"
								error={errors.no_sectores}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-3">
							<FormInput
								id="camas_no"
								type="number"
								name="camas_no"
								label="No. De camas"
								error={errors.camas_no}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-3">
							<FormInput
								id="camas_largo"
								type="number"
								name="camas_largo"
								label="Largo de camas (m)"
								error={errors.camas_largo}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-3">
							<FormInput
								id="camas_distancha"
								type="number"
								name="camas_distancha"
								label="Distancia entre camas (m)"
								error={errors.camas_distancha}
								register={register}
								step="0.01"
							/>
						</div>

					</div>

					<div className="form-group row">

						<div className="col-sm-4">
							<FormInput
								id="no_cintillas_cama"
								type="number"
								name="no_cintillas_cama"
								label="No. Cintillas/cama"
								error={errors.no_cintillas_cama}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-4">
							<FormInput
								id="distancia_goteros"
								type="number"
								name="distancia_goteros"
								label="Distancia entre goteros (m)"
								error={errors.distancia_goteros}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-4">
							<FormInput
								id="distancia_fuente"
								type="number"
								name="distancia_fuente"
								label="Distancia hasta fuente (m)"
								error={errors.distancia_fuente}
								register={register}
								step="0.01"
							/>
						</div>

					</div>


					<div className="form-group row">

						<div className="col-sm-4">
							<FormInput
								id="tipo_inyector"
								type="text"
								name="tipo_inyector"
								label="Tipo de inyector"
								error={errors.tipo_inyector}
								register={register}
							/>
						</div>

						<div className="col-sm-4">
							<FormInput
								id="marca_cintilla"
								type="text"
								name="marca_cintilla"
								label="Marca cintilla"
								error={errors.marca_cintilla}
								register={register}
							/>
						</div>

						<div className="col-sm-4">
							<FormInput
								id="tipo_gotero"
								type="text"
								name="tipo_gotero"
								label="Tipo de gotero"
								error={errors.tipo_gotero}
								register={register}
							/>
						</div>

					</div>

					<div className="form-group row">

						<div className="col-sm-4">
							<FormInput
								id="gasto_optimo"
								type="number"
								name="gasto_optimo"
								label="Gasto optimo (L)"
								error={errors.gasto_optimo}
								register={register}
								step="0.01"
							/>
						</div>

						<div className="col-sm-4">
							<FormInput
								id="presion_recomendada"
								type="number"
								name="presion_recomendada"
								label="Presión recomedada (PSI)"
								error={errors.presion_recomendada}
								register={register}
								step="0.01"
							/>
						</div>
						<div className="col-sm-4">
							<FormInput
								id="volumen_pozo"
								type="number"
								name="volumen_pozo"
								label="Volumen pozo (Pulgadas)"
								error={errors.volumen_pozo}
								register={register}
								step="0.01"
							/>
						</div>

					</div>




					<FormInput
						id="uuid"
						type="hidden"
						name="uuid"
						label="UUID"
						error={errors.uuid}
						register={register}
					/>


					<div className="row">
						<div className="col-md-12">
							<table className='table table-condensed'>
								<thead>
									<tr>
										<th></th>
										<th></th>
										<th>PI</th>
										<th>PF</th>
										<th>UI</th>
										<th>UF</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td></td>
										<td>Tiempo (min)</td>
										<td><FormInput
											id="pi_tiempo"
											type="number"
											name="pi_tiempo"
											label=""
											error={errors.pi_tiempo}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="pf_tiempo"
											type="number"
											name="pf_tiempo"
											label=""
											error={errors.pf_tiempo}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="ui_tiempo"
											type="number"
											name="ui_tiempo"
											label=""
											error={errors.ui_tiempo}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="uf_tiempo"
											type="number"
											name="uf_tiempo"
											label=""
											error={errors.uf_tiempo}
											register={register}
											step="0.01"
										/></td>
									</tr>
									<tr>
										<td></td>
										<td>Presión (Psi)</td>
										<td><FormInput
											id="pi_presion"
											type="number"
											name="pi_presion"
											label=""
											error={errors.pi_presion}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="pf_presion"
											type="number"
											name="pf_presion"
											label=""
											error={errors.pf_presion}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="ui_presion"
											type="number"
											name="ui_presion"
											label=""
											error={errors.ui_presion}
											register={register}
											step="0.01"
										/></td>
										<td><FormInput
											id="uf_presion"
											type="number"
											name="uf_presion"
											label=""
											error={errors.uf_presion}
											register={register}
											step="0.01"
										/></td>
									</tr>
									<tr>
										<td></td>
										<td>Volumen (L)</td>
										<td><FormInput
											id="pi_volumen"
											type="number"
											name="pi_volumen"
											label=""
											error={errors.pi_volumen}
											register={register}
											step="0.001"
										/></td>
										<td><FormInput
											id="pf_volumen"
											type="number"
											name="pf_volumen"
											label=""
											error={errors.pf_volumen}
											register={register}
											step="0.001"
										/></td>
										<td><FormInput
											id="ui_volumen"
											type="number"
											name="ui_volumen"
											label=""
											error={errors.ui_volumen}
											register={register}
											step="0.001"
										/></td>
										<td><FormInput
											id="uf_volumen"
											type="number"
											name="uf_volumen"
											label=""
											error={errors.uf_volumen}
											register={register}
											step="0.001"
										/></td>
									</tr>

								</tbody>
							</table>
						</div>

						<div className="col-md-12">


							<div className="form-group row">

							

							<div className="col-md-6">
									<FormInput
										id="costo_m3"
										type="number"
										name="costo_m3"
										label="Costo m3"
										error={errors.costo_m3}
										register={register}
										step="0.01"
									/>
								</div>

								<div className="col-md-6">
									<FormInput
										id="comentarios"
										type="text"
										name="comentarios"
										label="Comentarios"
										error={errors.comentarios}
										register={register}
									/>
								</div>
							</div>
						</div>


					</div>

					<div className="row">
						<div className="col-md-6 offset-md-3">
							<button className="btn btn-primary btn-block" type="submit">Guardar información</button>
						</div>
					</div>
				</form>
			</BlockUi>

			<div className="col-md-12">
				{id_sector_diagnostico_riego > 0 && (
					<>
						<hr />
						<div className="row">
							<div className="col-md-12">

								<table className='table table-bordered'>
									<tbody>
										<tr>
											<th>Superficie m2</th>
											<td>{infoRefistro.superficie}</td>
										</tr>
									</tbody>
								</table>

								<table className='table table-bordered'>
									<thead>
										<tr>
											<th></th>
											<th>PI</th>
											<th>PF</th>
											<th>UI</th>
											<th>UF</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>Gasto L/h</th>
											<td>{infoRefistro.pi_gasto}</td>
											<td>{infoRefistro.pf_gasto}</td>
											<td>{infoRefistro.ui_gasto}</td>
											<td>{infoRefistro.uf_gasto}</td>
										</tr>
									</tbody>
								</table>

								<table className='table table-bordered'>
									<tbody>
										<tr>
											<th>Presión prom (Psi)</th>
											<td>{infoRefistro.presion_prom}</td>
										</tr>

										<tr>
											<th>Gasto prom L/h</th>
											<td>{infoRefistro.gasto_prom}</td>
										</tr>
										<tr>
											<th>% Eficiencia</th>
											<td>{infoRefistro.porcentaje_eficiencia}</td>
										</tr>
										<tr>
											<th>% Uniformidad</th>
											<td>{infoRefistro.porcentaje_uniformidad}</td>
										</tr>
										<tr>
											<th>Lamina riego 𝑚^3 /h</th>
											<td>{infoRefistro.lamina_riego}</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</>

				)}
			</div>
		</>
	);
};
export default RiegosDiagnosticosRegistrar;
