import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import {Export} from '../../../nuup/componentes/Tablas/exportarCsv';
import BlockUi from 'react-block-ui';
import AvisosVistosExportar from './AvisosVistosExportar';
var dataArray = [];
const AvisosVistosTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [state, setState] = useState({
	id_aviso : 0,
	avisos: [],
	currentUsuario: null,
	currentIndex: -1
	});

	const columns = [

		{
			name: 'Nombres',
			selector: 'nombre',
			sortable: true,
			maxWidth: '250px',
		},
		{
			name: 'Apellido',
			selector: 'apellido',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'Estación',
			selector: 'estacion',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'Fecha Envío',
			selector: 'enviado',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'Avisos enviados',
			selector: 'enviados',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'Avisos vistos',
			selector: 'vistos',
			sortable: true,
			maxWidth: '250px',
			right: false
		}
	];



	useEffect(() => {
		obtenerAvisosVistos();
	}, []);

	  const obtenerAvisosVistos = ()  => {
		setBlocking(true);	
		Api.getAll(ApiUrl.avisos.reporteVistos).then(response => {
			  dataArray = response.data.data; 
			  setState({
			  avisos: response.data.data
			});
			setBlocking(false);	
		  })
		  .catch(e => {
			setBlocking(false);	
			console.log(e);
		  });
	  }


	const actionsMemo = React.useMemo(() => <><Export onExport={() => AvisosVistosExportar(dataArray)} /></>, []);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <DataTable
            pagination
            columns={columns}
            data={state.avisos}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
			actions={actionsMemo}
          />
		</BlockUi>
    );
  };
export default AvisosVistosTabla;
