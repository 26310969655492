import xlsx from "json-as-xlsx";
import moment from 'moment';
const RiegosDiagnosticosExportar = (content) => {
    let columns = [
        { label: 'id_sector_diagnostico_riego', value: 'id_sector_diagnostico_riego' },
        { label: 'uuid', value: 'uuid' },
        { label: 'id_productor', value: 'id_productor' },
        { label: 'Productor', value: row => (row.productor_nombres + ' ' + row.productor_apellido_paterno + ' ' + ((row.productor_apellido_materno === null) ? '' : row.productor_apellido_materno)) },
        { label: 'ciclo', value: 'ciclo' },
        { label: 'fecha_registro', value: 'fecha_registro' },
        { label: 'id_huerta', value: 'id_huerta' },
        { label: 'nombre_huerta', value: 'nombre_huerta' },
        { label: 'superficie', value: 'superficie' },
        { label: 'camas_no', value: 'camas_no' },
        { label: 'camas_largo', value: 'camas_largo' },
        { label: 'camas_ancho', value: 'camas_ancho' },
        { label: 'camas_distancha', value: 'camas_distancha' },
        { label: 'no_cintillas_cama', value: 'no_cintillas_cama' },
        { label: 'distancia_goteros', value: 'distancia_goteros' },
        { label: 'distancia_fuente', value: 'distancia_fuente' },
        { label: 'tipo_inyector', value: 'tipo_inyector' },
        { label: 'marca_cintilla', value: 'marca_cintilla' },
        { label: 'tipo_gotero', value: 'tipo_gotero' },
        { label: 'gasto_optimo', value: 'gasto_optimo' },
        { label: 'presion_recomendada', value: 'presion_recomendada' },
        { label: 'volumen_pozo', value: 'volumen_pozo' },
        { label: 'pi_tiempo', value: 'pi_tiempo' },
        { label: 'pi_presion', value: 'pi_presion' },
        { label: 'pi_volumen', value: 'pi_volumen' },
        { label: 'pf_tiempo', value: 'pf_tiempo' },
        { label: 'pf_presion', value: 'pf_presion' },
        { label: 'pf_volumen', value: 'pf_volumen' },
        { label: 'ui_tiempo', value: 'ui_tiempo' },
        { label: 'ui_presion', value: 'ui_presion' },
        { label: 'ui_volumen', value: 'ui_volumen' },
        { label: 'uf_tiempo', value: 'uf_tiempo' },
        { label: 'uf_presion', value: 'uf_presion' },
        { label: 'uf_volumen', value: 'uf_volumen' },
        { label: 'pi_gasto', value: 'pi_gasto' },
        { label: 'pf_gasto', value: 'pf_gasto' },
        { label: 'ui_gasto', value: 'ui_gasto' },
        { label: 'uf_gasto', value: 'uf_gasto' },
        { label: 'presion_prom', value: 'presion_prom' },
        { label: 'gasto_prom', value: 'gasto_prom' },
        { label: 'porcentaje_eficiencia', value: 'porcentaje_eficiencia' },
        { label: 'porcentaje_uniformidad', value: 'porcentaje_uniformidad' },
        { label: 'lamina_riego', value: 'lamina_riego' },
        { label: 'costo_m3', value: 'costo_m3' },
        { label: 'comentarios', value: 'comentarios' },
        { label: 'id_tecnico', value: 'id_tecnico' },
        { label: 'Técnico', value: row => (row.tecnico_nombres + ' ' + row.tecnico_apellido_paterno + ' ' + ((row.tecnico_apellido_materno === null) ? '' : row.tecnico_apellido_materno)) },
        { label: 'created_at', value: 'created_at' }
      ]
      
      let settings = {
        sheetName: 'RiegosDiagnosticos',
        fileName: 'RiegosDiagnosticos_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default RiegosDiagnosticosExportar;
