import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormInputInline from '../../nuup/componentes/Forms/FormInputInline.jsx';
import FormInputTextArea from '../../nuup/componentes/Forms/FormInputTextArea.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";

import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import '../../nuup/styles/Formularios.css';
import MultiSelect from "@kenshooui/react-multi-select";
import changeKeyObjects from '../../nuup/helpers/changeKeyObjects';

const labels = {
  id_campania: "",
  nombre_campania: "Nombre de la Campaña",
  descripcion_campania: "Descripción de la Campaña",
  fecha_inicio: "Fecha de Inicio de la Campaña",
  fecha_final: "Fecha del Final de la Campaña",
  productores: "Seleccione Productores",
  hitos: "Hitos de la Campaña",
  puntos_por_actividad: "Registro de aplicación",
  puntos_por_registro: "Otros registros (riego, preparación, plantación/siembra y actividad huerta)",
  puntos_por_registro_cosecha: "Registro de Cosecha",
  puntos_por_compartir: "Compartir tus registros",
  puntos_por_difundir: "Recomendar a otro productor la APP",
  puntos_por_huertas: "Registro de huerta",
  puntos_por_reg_costos: "Registro de Gastos/Ingresos",
}

const CampaniasRegistrar = () => {
    const { id_campania } = useParams();
    const [blocking, setBlocking] = useState(false);
    const [modal, setModal] = useState(false)
    const [productores, setProductores] = useState({items : [],selectedItems : []});

    const schema = yup.object().shape({
        id_campania: yup.number().min(0),
        nombre_campania: yup.string().required('Nombre Requerido'),
        descripcion_campania: yup.string(),
        fecha_inicio: yup.date().required('Fecha de inicio requerida'),
        fecha_final: yup.date().required('Fecha final requerida'),
        puntos_por_registro: yup.number().min(0),
        puntos_por_registro_cosecha: yup.number().min(0),
        puntos_por_actividad: yup.number().min(0),
        puntos_por_compartir: yup.number().min(0),
        puntos_por_difundir: yup.number().min(0),
        puntos_por_huertas: yup.number().min(0),
        puntos_por_reg_costos: yup.number().min(0),
    });

    useEffect(() => {
      if(id_campania !== '0'){
        obtenerCampania()
      }
    // eslint-disable-next-line
    },[id_campania,productores]);


    const obtenerProductoresSeleccionados = (data_users) => {
      let prodSeleccionados = [];
      for (let index = 0; index < productores.items.length; index++) {
        const element = productores.items[index];
        for (let d = 0; d < data_users.length; d++) {
          if(parseInt(data_users[d]) === element.id){
            prodSeleccionados.push({
              id : element.id,
              label : element.productor
            });
          }
        }
      }
      return prodSeleccionados;
    }


    const obtenerCampania = () => {
			setBlocking(true);
			Api.getAll(ApiUrl.concursos.campanias + '/' + id_campania).then(response => {
				let campaniaData = response.data.data;
				reset(campaniaData);
        setValue('id_user',obtenerProductoresSeleccionados(response.data.data_users));
				setBlocking(false);
			}).catch(e => {
        setBlocking(false);
        let errorMessage = e
        if (e.response?.status === 404) {
          errorMessage = `No se encontró la campaña con id ${id_campania}`
        }
				console.log(e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: JSON.stringify(errorMessage),
          confirmButtonText: 'Regresar a Listado',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location = '/administracion/campanias'; 
          }
        })
			});
	}

  const { register, handleSubmit, errors,setError,reset,control,setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
          id_campania : id_campania,
          descripcion_campania: "",
          fecha_inicio:  "",
          fecha_final:  "",
          id_user: [],
          puntos_por_registro: 0,
          puntos_por_registro_cosecha: 0,
          puntos_por_actividad: 0,
          puntos_por_compartir: 0,
          puntos_por_difundir: 0,
          puntos_por_huertas: 0,
          puntos_por_reg_costos: 0,
        }
    });

    const creaCampania = values => {
      setBlocking(true);
      // values = {...values, file: ""}
      Api.create(ApiUrl.concursos.campanias,values).then(response => {
        setBlocking(false);
        let _result = response.data;
        if(_result.success === 1){
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Campaña creada correctamente',
            confirmButtonText: 'Continuar',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = '/administracion/campanias'; 
            }
          })
        } else{
          Swal.fire({
            icon: 'error',
            title: '',
            text: _result.message,
            showDenyButton: true,
            denyButtonText: `Regresar a Listado`,
          }).then((result) => {
            if (result.isDenied) {
              window.location = '/administracion/campanias'; 
            }
          })
        }
      }).catch(e => {
        setBlocking(false);
        let errorMessage = e
        if (e.response?.status === 422) {
          errorMessage = e.response.data.message
          for (let key in errorMessage) {
            setError(key, {
              type: "server",
              message: errorMessage[key]
            })
          }
          errorMessage = Object.values(errorMessage).join(" / ")
        }
        
        setModal(false)
        Swal.fire({
          icon: 'error',
          title: '',
          text: JSON.stringify(errorMessage),
        })
      });
    }


    const actualizaCampania = values => {
        Swal.showLoading();
        Api.update(ApiUrl.concursos.campanias, values.id_campania, values).then(response => {
            let _result = response.data;
            if(_result.success === 1){
              setModal(false)
                Swal.fire({
                    icon: 'success',
                    title: '',
                    confirmButtonText: 'Continuar',
                    text: _result.message,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location = '/administracion/campanias'; 
                    }
                  })
                }else{
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: _result.message,
                  })
            }
        }).catch(e => {
          setBlocking(false);
          let errorMessage = e
          if (e.response?.status === 422) {
            errorMessage = e.response.data.message
            for (let key in errorMessage) {
              setError(key, {
                type: "server",
                message: errorMessage[key]
              })
            }
            errorMessage = Object.values(errorMessage).join(" / ")
          }
          
          setModal(false)
          Swal.fire({
            icon: 'error',
            title: '',
            text: JSON.stringify(errorMessage),
          })
        });
    }

    const onSubmit = () => {
      let values = getValues();

      if(values.id_user.length > 0){
        if (id_campania > 0) {
          actualizaCampania(values);
        } else {
          creaCampania(values);
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'Seleccione al menos un productor',
          confirmButtonText: 'Regresar a Listado',
        }).then((result) => {
          if (result.isConfirmed) {
            setModal(false)
          }
        })
      }

    }

    const onConfirm = () => {
      setModal(true)
    }

    const obtenerProductores = ()  => {
      setBlocking(true);
      Api.getAll(ApiUrl.bitacoras.soloproductores)
        .then(response => {
        setProductores({...productores,
          items: response.data.data
        });
        setBlocking(false);
      })
        .catch(e => {
        setBlocking(false);
        });
      }

      useEffect(() => {
        obtenerProductores();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, []);

    return (
      <>
        <div>
          <Modal
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Revisar Información Antes de Enviar
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup >
                {Object.keys(getValues()).map((key, ind) => {
                  if(key !== 'id_user'){
                    return (
                      <ListGroup.Item key={ind}>
                        {labels[key]}&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>{getValues()[key]}</strong>
                      </ListGroup.Item>
                    )
                  }

                })}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModal(false)}>Seguir Editando</Button>
              <Button variant="primary" onClick={onSubmit}>ENVIAR</Button>
            </Modal.Footer>
          </Modal>

          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item"><Link to="/administracion/campanias">Listado de Campañas</Link></li>
            <li className="breadcrumb-item active">Registrar Campaña</li>
          </ol>
          <h1 className="page-header">Registrar Campaña<small></small></h1>

          <div className="row">
            <div className="col-md-12">
              <Panel>
                <PanelHeader>Datos de la Campaña</PanelHeader>
                <PanelBody>
                  <BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>
                    <form onSubmit={handleSubmit(onConfirm)}>
                     {id_campania !== 0 ? <input {...register('id_campania')} type="hidden" />: null} 
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <FormInput 
                                id="nombre_campania"
                                type="text"
                                name="nombre_campania"
                                label={labels.nombre_campania}
                                error={errors.nombre_campania}
                                register={register}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-md-12">
                              <FormInputTextArea 
                                id="descripcion_campania"
                                type="text"
                                name="descripcion_campania"
                                label={labels.descripcion_campania}
                                error={errors.descripcion_campania}
                                register={register}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-md-12">
                            <h5>{labels.productores}</h5>
                            <Controller
                                name="id_user"
                                control={control}
                                render={({ onChange, ref, value }) => (
                                  <MultiSelect
                                    items={changeKeyObjects(productores.items, { id: "id", productor: "label" })}
                                    onChange={onChange}
                                    ref={ref}
                                    showSearch
                                    showSelectAll
                                    showSelectedItems
                                    selectedItems={value}
                                    messages={{
                                      searchPlaceholder: "Buscar...",
                                      noItemsMessage: "Sin elementos...",
                                      noneSelectedMessage: "Ninguno seleccionado",
                                      selectedMessage: "seleccionados",
                                      selectAllMessage: "Seleccionar todos",
                                      clearAllMessage: "Quitar todos",
                                    }}
                                  />
                                )}/>
                            </div>
                          </div>



                          <div className="form-group row">
                            <div className="col-sm-6">
                              <FormInput 
                                id="fecha_inicio"
                                type="date"
                                name="fecha_inicio"
                                label={labels.fecha_inicio}
                                error={errors.fecha_inicio}
                                register={register}
                              />
                            </div>
                            <div className="col-sm-6">
                              <FormInput 
                                id="fecha_final"
                                type="date"
                                name="fecha_final"
                                label={labels.fecha_final}
                                error={errors.fecha_final}
                                register={register}
                              />
                            </div>
                          </div>
                          <div className="form-group col-auto">
                            <h5>{labels.hitos}</h5>
                            <FormInputInline 
                              id="puntos_por_actividad"
                              type="number"
                              name="puntos_por_actividad"
                              label={labels.puntos_por_actividad}
                              error={errors.puntos_por_actividad}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_registro"
                              type="number"
                              name="puntos_por_registro"
                              label={labels.puntos_por_registro}
                              error={errors.puntos_por_registro}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_registro_cosecha"
                              type="number"
                              name="puntos_por_registro_cosecha"
                              label={labels.puntos_por_registro_cosecha}
                              error={errors.puntos_por_registro_cosecha}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_compartir"
                              type="number"
                              name="puntos_por_compartir"
                              label={labels.puntos_por_compartir}
                              error={errors.puntos_por_compartir}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_difundir"
                              type="number"
                              name="puntos_por_difundir"
                              label={labels.puntos_por_difundir}
                              error={errors.puntos_por_difundir}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_huertas"
                              type="number"
                              name="puntos_por_huertas"
                              label={labels.puntos_por_huertas}
                              error={errors.puntos_por_huertas}
                              register={register}
                            />
                            <FormInputInline 
                              id="puntos_por_reg_costos"
                              type="number"
                              name="puntos_por_reg_costos"
                              label={labels.puntos_por_reg_costos}
                              error={errors.puntos_por_reg_costos}
                              register={register}
                            />
                          </div>
                          <div className="form-group col-auto">
                          </div>
                          <div className="row">
                            <div className="col-md-3 offset-md-9">
                              <button className="btn btn-primary btn-block" type="submit">{id_campania !== '0' ? "Actualizar Campaña" : "Crear Campaña"}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </BlockUi>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </>
    );
};

export default CampaniasRegistrar;
