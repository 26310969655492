import React, { useContext, createContext, useState, } from "react";
import { Api } from "./../../app-controller.js";
import ApiUrl from "../../constants/Api";

import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

const authContext = createContext();

/**
 * ProvideAuth wraps all our authentication methods
 */
export function ProvideAuth({ children }) {
  const [user, setUser] = useState(() => {
    //if session is closed then user will be null
      if(localStorage.sesion === undefined){
          return {}; 
      }else{
        if(localStorage.sesion.email_verified_at ==null){
          //Api.user_profile=response;
          //localStorage.sesion=JSON.stringify(response);
          Redirect("/confirm"); 
        }else{
          Redirect("/login"); 
        } 
      }
    return JSON.parse(localStorage.sesion);
  });
  
  const login = (state) => {
    //call to function login in API
    return Api.fetch(ApiUrl.user.login, state, "POST")
      .then((response) => {
        //setting user and saving token and user to mantain session
        if(response.success === 1){
          let esAdmin = false;
          let usuario = response.data.user;
          let roles = usuario.roles;
          for (let r = 0; r < roles.length; r++) {
            if(roles[r].id === 1){
              esAdmin = true;
            }            
          }

          if(esAdmin){
            setUser(usuario);
            localStorage.token = response.data.token;
            localStorage.user = JSON.stringify(usuario);
            localStorage.grupos =  JSON.stringify(response.data.grupos);
            localStorage.accesos =  JSON.stringify(response.data.accesos);
            localStorage.sesion = JSON.stringify(usuario);
            Api.user_profile = usuario;
            return usuario;
          }else{
            Swal.fire({
              title: 'Error',
              text: 'Acceso no permitido',
              icon: 'error',
              showCancelButton: false,
              cancelButtonColor: 'green',
              confirmButtonText: 'Ok'
            });
            return false;
          }
        }else{
            Swal.fire({
              title: 'Error',
              text: response.data.error,
              icon: 'error',
              showCancelButton: false,
              cancelButtonColor: 'green',
              confirmButtonText: 'Ok'
            });
          return {};
        }

      });
  };

  const logout = () => {
    return Api.fetch("auth/logout", null, "GET")
      .then((data) => {
        localStorage.clear("user");
        localStorage.clear("grupos");
        localStorage.clear("accesos");
        localStorage.clear("sesion");
      });
  };

  const profile = () => {
    return Api.fetch("auth/profile", null, "POST")
      .then((data) => {
        return data;
      });
  }

  const checkUser = () => {
    return JSON.parse(localStorage.user);
  }

  const auth = { user, login, logout, profile,checkUser };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/** 
 * useAuth hook enables any component to get the current auth state and re-render if it changes.
**/

export function useAuth() {
  return useContext(authContext);
}

