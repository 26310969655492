import React, { useEffect,useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormInputFile from '../../nuup/componentes/Forms/FormInputFile.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import changeKeyObjects from '../../nuup/helpers/changeKeyObjects';
import selectArrayToArray from '../../nuup/helpers/selectArrayToArray';
import '../../nuup/styles/Formularios.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AvisoRegistrarModalConf from './AvisoRegistrarModalConf'
import { countActiveInactive, countActiveUsers } from '../../nuup/componentes/Graficas/DataHelpers'
import { format, sub} from "date-fns";
const today = new Date();
const threeMonthsAgo = sub(today, { months: 3 });

const AvisosRegistrar = () => {
	const { id_aviso } = useParams();
	const [blocking, setBlocking] = useState(false);
	const [productores, setProductores] = useState({items : [],selectedItems : []});
	const [productoresInactivos, setProductoresInactivos] = useState({items : [],selectedItems : []});
	const [aliados, setAliados] = useState({items : [],selectedItems : []});
	const [zonas, setZonas] = useState({items : [],selectedItems : []});
	const [key, setKey] = useState('productor');
	const [tipo, setTipo] = useState(1); // 1 Por productor, 2 Por Zona, 3 Por Nivel
	// eslint-disable-next-line
	const [file,setFile] = useState();
	// eslint-disable-next-line
	const [aviso,setAviso] = useState('<div><b>Temperatura</b></div><div><b><br></b></div><div><b>máxima</b></div><div><b><font face="Arial" color="#ff0000"><br></font></b></div><div><b><font face="Arial" color="#ff0000">prueba</font></b><font color="#ff0000" face="Arial"><b>🖐</b></font></div>');

	const schema = yup.object().shape({
		id_aviso: yup.number().min(0),
		uuid: yup.string(),
		id_avisos_categoria: yup.number().min(1),
		titulo: yup.string().required('Título requerido'),
		aviso: yup.string().required('Aviso requerido'),
		file: yup.mixed().test('fileFormat', 'Solo se permiten archivos .jpg,jpeg,png', (value) => {
			if (!value.length) return true;
          return value[0] && ["image/jpg", "image/jpeg", "image/png"].includes(value[0].type);
        }),
	});

	const { register, handleSubmit, errors,reset,control,setValue, getValues } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: {
			id_aviso : id_aviso,
			uuid: "",
			id_avisos_categoria: 0,
			titulo: "",
			aviso: "",
			file: "",
			id_user: [],
			id_zona: [],
			id_nivel: [],
			id_aliado: [],
			id_user_inactivo: [],
		}
	});

	const [state, setState] = useState({
		fechaInicial: format(threeMonthsAgo, "yyyy-MM-dd"),
		fechaFinal: format(today, "yyyy-MM-dd"),
	  });


	useEffect(() => {
		obtenerProductores();
		obtenerProductoresInactivos();
		obtenerZonas();
		obtenerAliados();
		if (id_aviso > 0) {obtenerAviso();}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	const obtenerProductores = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.bitacoras.soloproductores)
		  .then(response => {
			setProductores({...productores,
				items: response.data.data
			});
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }

	  const obtenerProductoresInactivos = ()  => {
		setBlocking(true);
		Api.getAll('graficas/productores_activos', {
			fechaInicial: state.fechaInicial,
			fechaFinal: state.fechaFinal,
		  })
			.then((response) => {
			  const activeUsers = countActiveUsers(response.data)
			  setProductoresInactivos({...productoresInactivos,
				items: activeUsers.listadoInactivos
			});

			  setBlocking(false)
			})
			.catch((e) => {
			  setBlocking(false)
			})
	  }

	  const obtenerZonas = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.catalogs.formularios.zonas)
		  .then(response => {
			setZonas({...zonas,
				items: response.data.data
			});
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }


	  const obtenerAliados = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.bitacoras.soloaliados)
		  .then(response => {
			setAliados({...aliados,
				items: response.data.data
			});
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }



	const obtenerAviso = () => {
		setBlocking(true);
		setTimeout(function(){ 
			Api.getAll(ApiUrl.avisos.general + '/' + id_aviso).then(response => {
				setBlocking(false);
				let avisoData = response.data.data;
				reset(avisoData[0]);
				console.log(avisoData[0].aviso);
				setAviso(avisoData[0].aviso);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		 }, 3000);
	}

	const creaAviso = values => {
		setBlocking(true);
		const formData = new FormData();
		for (let i = 0; i < values.file.length; i++) {
			formData.append('files[]', values.file[i]);
		}	
		formData.append('id_avisos_categoria',values.id_avisos_categoria);
		formData.append('titulo',values.titulo);
		formData.append('aviso',values.aviso);
		formData.append('id_user_inactivo',selectArrayToArray(values.id_user_inactivo,'id'));
		formData.append('id_zona',selectArrayToArray(values.id_zona,'id'));
		formData.append('id_aliado',selectArrayToArray(values.id_aliado,'id'));
		formData.append('tipo_envio',tipo);
		let id_users = selectArrayToArray(values.id_user,'id');
		formData.append('id_users',id_users.join(","));

		Api.createMultiPart(ApiUrl.avisos.general,formData).then(response => {
			
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: 'Archivo cargado correctamente',
					confirmButtonText: 'Continuar',
					backdrop: false
				  }).then((result) => {
					if (result.isConfirmed) {
						window.location = '/administracion/avisos';
						setBlocking(false); 
					}
				  })
				
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
				  setBlocking(false);
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}


	const actualizaAviso = values => {
		console.log('actualizaAviso');
		console.log(values);
		Swal.showLoading();
		console.log(values);
		Api.update(ApiUrl.avisos.general,values.uuid, values).then(response => {
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				window.location = '/administracion/avisos'; 
				}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			Swal.fire({
				icon: 'error',
				title: '',
				text: e?.response?.data?.message || e,
			  })
		});
	}

	const [modal, setModal] = useState(false)	

	const onSubmit = () => {
		if (id_aviso > 0) {
			actualizaAviso(getValues());
		} else {
			creaAviso(getValues());
		}
	}

  const onConfirm = () => {
    setModal(true)
  }

	const onChangeTab = (eventKey) => {
		setKey(eventKey);
		switch (eventKey) {
			case 'productor':
				setTipo(1);
				setValue('id_zona', [], { shouldValidate: true });
				setValue('id_aliado', [], { shouldValidate: true });
				setValue('id_user_inactivo', [], { shouldValidate: true });
				break;
			case 'zona':
				setTipo(2);
				setValue('id_user', [], { shouldValidate: true });
				setValue('id_aliado', [], { shouldValidate: true });
				setValue('id_user_inactivo', [], { shouldValidate: true });
				break;
			case 'aliado':
				setTipo(3);
				setValue('id_user', [], { shouldValidate: true });
				setValue('id_zona', [], { shouldValidate: true });
				setValue('id_user_inactivo', [], { shouldValidate: true });
				break;
			case 'productorInactivo':
				setTipo(4);
				setValue('id_zona', [], { shouldValidate: true });
				setValue('id_aliado', [], { shouldValidate: true });
				setValue('id_user', [], { shouldValidate: true });
				break;
			default:
				break;
		}
		
	}

	const onChangeFile = (e) => {
		setFile(e.target.files);
	  }

	// Toolbar for editing QUILL
	const modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
			[{ 'script': 'sub'}, { 'script': 'super' }],
			['link', 'image'],
			[{ 'color': [] }, { 'background': [] }],
			[{ 'align': [] }],
			['clean']
		]
	}


	return (
		<>
		<div>
			<Modal
				show={modal}
				onHide={() => setModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size="lg"
			>
				<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>
					<Modal.Header closeButton>
						<Modal.Title>
							Revisar Información Antes de Enviar
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AvisoRegistrarModalConf values={getValues()}/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => setModal(false)}>Seguir Editando</Button>
						<Button variant="primary" onClick={onSubmit}>ENVIAR</Button>
					</Modal.Footer>
				</BlockUi>
			</Modal>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/videos">Registrar aviso</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de avisos <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del aviso</PanelHeader>
					<PanelBody>


					<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>


					<form onSubmit={handleSubmit(onConfirm)}>
						<FormInput 
							id="uuid"
							type="hidden"
							name="uuid"
							label="UUID"
							error={errors.uuid}
							register={register}
						/>
	
					<div className="form-group row">
						<div className="col-sm-6">

						<div className="form-group row">
							<div className="col-sm-12">
								<FormSelectApi 
									id="id_avisos_categoria"
									name="id_avisos_categoria"
									label="Categoría"
									url={ApiUrl.catalogs.formularios.categoriasavisos}
									keyArray="id_avisos_categoria"
									labelArray="nombre_categoria"
									error={errors.id_avisos_categoria}
									register={register}
									onChange={() => {}}
								/>
							</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-12">
									<FormInput 
										id="titulo"
										type="text"
										name="titulo"
										label="Título"
										error={errors.titulo}
										register={register}
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-12">
									<FormInputFile 
										id="file"
										name="file"
										label=""
										keyArray="file"
										labelArray="tipo_documento"
										error={errors.file}
										register={register}
										onChange={onChangeFile}
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
					    <Controller
							control={control}
							name="aviso"
							error={errors.aviso}
							render={({ onChange, onBlur, value }) => (
								<ReactQuill
									theme="snow"
									modules={modules}
									onChange={(aviso, delta, source, editor) => onChange(aviso)}
									value={value || ''}

								/>
							)}
                                />
					
							{errors.aviso && <p className="text-danger">Descripción del aviso es requerido</p>}
						</div>
					</div>



						<div className="form-group row">
							<div className="col-md-12">
							<Tabs onSelect={onChangeTab} defaultActiveKey={key} id="uncontrolled-tab-example">
								<Tab tabClassName={ key === 'productor' ? 'tab-active' : 'tab'} eventKey="productor" title="Por productor">
								
								<Controller
									name="id_user"
									control={control}
									render={({ onChange, ref, value }) => (
										<MultiSelect
											items={changeKeyObjects(productores.items, { id: "id", productor: "label" })}
											onChange={onChange}
											ref={ref}
											showSearch
											showSelectAll
											showSelectedItems
											selectedItems={value}
											messages={{
												searchPlaceholder: "Buscar...",
												noItemsMessage: "Sin elementos...",
												noneSelectedMessage: "Ninguno seleccionado",
												selectedMessage: "seleccionados",
												selectAllMessage: "Seleccionar todos",
												clearAllMessage: "Quitar todos",
											}}
										/>
									)}/>
							
								</Tab>

								<Tab tabClassName={ key === 'productorInactivo' ? 'tab-active' : 'tab'} eventKey="productorInactivo" title="Por productor Inactivo">
								
								<Controller
									name="id_user_inactivo"
									control={control}
									render={({ onChange, ref, value }) => (
										<MultiSelect
											items={changeKeyObjects(productoresInactivos.items, { id: "id", full_name: "label" })}
											onChange={onChange}
											ref={ref}
											showSearch
											showSelectAll
											showSelectedItems
											selectedItems={value}
											messages={{
												searchPlaceholder: "Buscar...",
												noItemsMessage: "Sin elementos...",
												noneSelectedMessage: "Ninguno seleccionado",
												selectedMessage: "seleccionados",
												selectAllMessage: "Seleccionar todos",
												clearAllMessage: "Quitar todos",
											}}
										/>
									)}/>
							
								</Tab>

								<Tab tabClassName={ key === 'zona' ? 'tab-active' : 'tab'} eventKey="zona" title="Por zona">

								<Controller
									name="id_zona"
									control={control}
									render={({ onChange, ref, value }) => (
										<MultiSelect
											items={changeKeyObjects(zonas.items, { id_zona: "id", nombre_zona: "label" })}
											onChange={onChange}
											ref={ref}
											showSearch
											showSelectAll
											showSelectedItems
											selectedItems={value}
											messages={{
												searchPlaceholder: "Buscar...",
												noItemsMessage: "Sin elementos...",
												noneSelectedMessage: "Ninguno seleccionado",
												selectedMessage: "seleccionados",
												selectAllMessage: "Seleccionar todos",
												clearAllMessage: "Quitar todos",
											}}
										/>
									)}/>
							
								</Tab>

								<Tab tabClassName={ key === 'aliado' ? 'tab-active' : 'tab'} eventKey="aliado" title="Por Aliado">

								<Controller
									name="id_aliado"
									control={control}
									render={({ onChange, ref, value }) => (
										<MultiSelect
											items={changeKeyObjects(aliados.items, { id: "id", productor: "label" })}
											onChange={onChange}
											ref={ref}
											showSearch
											showSelectAll
											showSelectedItems
											selectedItems={value}
											messages={{
												searchPlaceholder: "Buscar...",
												noItemsMessage: "Sin elementos...",
												noneSelectedMessage: "Ninguno seleccionado",
												selectedMessage: "seleccionados",
												selectAllMessage: "Seleccionar todos",
												clearAllMessage: "Quitar todos",
											}}
										/>
									)}/>
							
								</Tab>

								</Tabs>

							</div>
						</div>


						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar aviso</button>
						</div>
					</div>
					</form>
					</BlockUi>

					</PanelBody>
				</Panel>
			</div>
			</div>
			</div>
		</>
	);
};
export default AvisosRegistrar;
