    import React from "react";
	import inArray from '.././../helpers/inArray';
    export const convertArrayOfObjectsToCSV = (array,skip) => {
		let result;
		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		let _keys = [];
		const keys = Object.keys(array[0]);

		keys.forEach(element => {
			if(!inArray(element,skip)){
				_keys.push(element);
			}
		});


		result = '';
		result += _keys.join(columnDelimiter);
		result += lineDelimiter;

		array.forEach(item => {
		  let ctr = 0;
		  _keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;
	  
			result += item[key];
			
			ctr++;
		  });
		  result += lineDelimiter;
		});
	  
		return result;
		
	  }
	  
	  export const downloadCSV = (array,filename = 'archivo',skip = []) => {
        const timestamp = Math.round(new Date() / 1000);
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array,skip);
		if (csv == null) return;
	  	  
		if (!csv.match(/^data:text\/csv/i)) {
		  csv = `data:text/csv;charset=utf-8,${csv}`;
		}
	  
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename + '_' + timestamp + '.csv');
		link.click();
		
	  }
	  

	  export const Export = ({ onExport }) => (
		<button className="btn btn-warning" onClick={e => onExport(e.target.value)}>Exportar</button>
	  );