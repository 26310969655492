import { Link } from 'react-router-dom'
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx'
import React, { useEffect, useState } from 'react'
import Api from '../../api/global.service'
import DataTable from 'react-data-table-component'
import 'react-data-table-component-extensions/dist/index.css'
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral'
import '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui'
import ActivosExportar from './ActivosExportar'
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas'

var dataArray = []
const ReporteActivos = (props) => {
  const [blocking, setBlocking] = useState(true)
  const [datosFiltrados, setDatosFiltrados] = useState([])

  const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
    {
      name: 'USUARIO',
      selector: 'id_user',
      sortable: true,
      maxWidth: '200px',
    },

    {
      name: 'TIPO',
      selector: 'name',
      sortable: true,
      maxWidth: '200px',
      right: false,
    },

    {
      name: 'CREADO',
      selector: 'created_at',
      sortable: true,
      maxWidth: '250px',
      right: false,
    },
  ]

  const fechaInicial = '2020-04-01'
  const fechaFinal = '2022-05-24'

  useEffect(() => {
    obtenerUsuarios()
  }, [])

  const obtenerUsuarios = () => {
    setBlocking(true)
    // Api.getAll(ApiUrl.usuarios.listado + '/0')
    //   .then((response) => {
    //     console.log('response.data USUARIOS', response.data )
    //     dataArray = response.data.data
    //     setDatosFiltrados(dataArray)
    //     setBlocking(false)
    //   })
    //   .catch((e) => {
    //     setBlocking(false)
    //   })

    Api.getAll('graficas/activos_listado', {
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
    })
      .then((response) => {
        dataArray = response.data.data
        const corregidos = dataArray.map((item) => {return {...item, id: item.key}}) 
        setDatosFiltrados(corregidos)
        setBlocking(false)
        // const activeUsers = countActiveUsers(response.data)
        // console.log('activeUsers.listadoInactivos', activeUsers.listadoInactivos)
        // setProductoresInactivos(activeUsers.listadoInactivos)
        // setActiveUsers(activeUsers.totales)
        // setActiveInactiveUsers(countActiveInactive(response.data))
        // setBlocking(false)
      })
      .catch((e) => {
        setBlocking(false)
      })

  }

  const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Administración de usuarios</Link>
        </li>
        <li className="breadcrumb-item active">Listado de usuarios</li>
      </ol>
      <h1 className="page-header">
        Administración de usuarios <small></small>
      </h1>
      <Panel>
        <PanelHeader>Usuarios registrados</PanelHeader>
        <PanelBody>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <HerramientasTablas
              txtBusqueda="Buscar usuario"
              datosTabla={dataArray}
              urlRegistrar="/administracion/usuarios/registrar/0"
              columnasFiltrar={[
                'id_user',
                'name',
                'created_at',
              ]}
              exportar={ActivosExportar}
              onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
            />
            <DataTable
              noHeader
              pagination
              defaultSortAsc={false}
              columns={columns}
              data={datosFiltrados}
              Clicked
              noDataComponent={'Sin datos por mostrar'}
              customStyles={estiloGeneral}
              highlightOnHover
            />

          </BlockUi>
        </PanelBody>
      </Panel>
    </div>
  )
}
export default ReporteActivos
