import React from "react";
import { Link } from "react-router-dom";
import filterDataTables from "../../../helpers/filterDataTables";

import "./HerramientasTablas.css";

export default function HerramientasTablas(props) {
  const {
    txtBusqueda = "Buscar...",
    datosTabla = [],
    urlRegistrar = "",
    columnasFiltrar = [],
    exportar,
    onFiltrar,
  } = props;

  const handleChange = (e) => {
    onFiltrar(filterDataTables(datosTabla, e.target.value, columnasFiltrar));
  };

  const exportarRegistros = () => {
    exportar(datosTabla);
  };

  return (
    <>
      <div
        className="herramientas-tabla-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div style={{ flexGrow: 1, marginRight: "1rem", maxWidth: "300px" }}>
          <input
            onChange={(e) => handleChange(e)}
            type="text"
            className="form-control"
            placeholder={txtBusqueda}
          />
        </div>

        <div style={{ display: "flex", gap: "0.5rem" }}>
          {urlRegistrar && urlRegistrar.length > 0 && (
            <Link className="btn btn-secondary" to={urlRegistrar}>
              <i className="fa fa-plus" /> Agregar
            </Link>
          )}

          {exportar && (
            <button className="btn btn-primary" onClick={exportarRegistros}>
              <i className="fa fa-download" /> Descargar
            </button>
          )}
        </div>
      </div>
    </>
  );
}
