import React from 'react';

export default ({ children, ...rest }) => (
  <button 
    className="btn btn-xs btn-danger" 
    title="Eliminar" 
    {...rest}
  >
    {children}
  </button>
)