import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectData from '../../../nuup/componentes/Forms/FormSelectData.jsx';
import FormSelectApiNested from '../../../nuup/componentes/Forms/FormSelectApiNested.jsx';
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';


const LecturasCaudalimetrosRegistrar = (props) => {
	const [blocking, setBlocking] = useState(false);
	const { id_sector_lectura_caudalimetro,id_productor,id_ciclo,huertas,etapas } = props;

	const [idProductor, setIdProductor] = useState(id_productor);
	const [infoRefistro, setInfoRefistro] = useState({});
	const [idHuerta, setIdHuerta] = useState(0);


	const [data] = useState({
		id_sector_lectura_caudalimetro: id_sector_lectura_caudalimetro,
		uuid: "",
		id_huerta: "",
		id_sector: "",
		id_productor: id_productor,
		id_ciclo: id_ciclo,
		fecha_lectura: "",
		valor: "",
		evento: "",
		id_etapa: ""
	});


	const schema = yup.object().shape({
		id_sector_lectura_caudalimetro: yup.number().min(0),
		uuid: yup.string(),
		id_huerta: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_sector: yup.number().integer().transform(value => (isNaN(value) ? undefined : value)),
		id_productor: yup.number().integer().min(1, 'Requerido').required('Requerido'),
		id_ciclo: yup.number().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		fecha_lectura: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Requerido'),
		evento: yup.string(),
		valor: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_etapa: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido')
	});


	const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});



	const selectFinish = () => {
		if (id_sector_lectura_caudalimetro > 0) { obtenerRegistro(); }
	};

	const obtenerRegistro = () => {
		setBlocking(true);
		setTimeout(function () {
			Api.getAll(ApiUrl.riegos.lecturasCaudalimetros.general + '/' + id_sector_lectura_caudalimetro + '/show').then(response => {
				let lecturaData = response.data.data;
				reset(lecturaData[0]);
				setInfoRefistro(lecturaData[0]);
				setIdProductor(lecturaData[0].id_productor);
				setIdHuerta(lecturaData[0].id_huerta);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		}, 5000);
	}

	const creaRegistro = values => {
		setBlocking(true);
		Api.create(ApiUrl.riegos.lecturasCaudalimetros.general, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire(
					'Registrado!',
					'Registro creado exitosamente.',
					'success'
				);
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}

	const actualizaRegistro = values => {
		setBlocking(true);
		Api.update(ApiUrl.riegos.lecturasCaudalimetros.general, id_sector_lectura_caudalimetro, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				})
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}
	

	const onSubmit = values => {
		if (id_sector_lectura_caudalimetro > 0) {
			actualizaRegistro(values);
		} else {
			creaRegistro(values);
		}

	}

	useEffect(() => {
		if (id_sector_lectura_caudalimetro === '0') {
			reset(data);
		} else {
			obtenerRegistro();
		}
		// eslint-disable-next-line
	}, [id_sector_lectura_caudalimetro]);

	const onComplete = values => {
		props.onComplete(values);
	};

	return (
		<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

			<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
					<FormInput
						id="id_productor"
						type="hidden"
						name="id_productor"
						label="Productor"
						error={errors.id_productor}
						register={register}
					/>
					
					<FormInput
						id="id_ciclo"
						type="hidden"
						name="id_ciclo"
						label="Productor"
						error={errors.id_ciclo}
						register={register}
					/>



					<div className="col-sm-6">
						<FormSelectData
							id="id_huerta"
							name="id_huerta"
							label="Huerta"
							keyArray="id_huerta"
							labelArray="nombre_huerta"
							items={huertas}
							error={errors.id_huerta}
							register={register}
							onChange={(value) => setIdHuerta(value)}
						/>
					</div>

					<div className="col-sm-6">
						<FormSelectApiNested
							id="id_sector"
							name="id_sector"
							label="Sector"
							url={ApiUrl.riegos.sectoresproductor}
							search={{ key: 'id_huerta', value: idHuerta }}
							valueSearch={idHuerta}
							keyArray="id_sector"
							labelArray="datos_sector"
							error={errors.id_sector}
							register={register}
							onFinish={() => selectFinish()}
							onChange={(value) => { }}
						/>

					</div>

				</div>

				<div className="form-group row">

					<div className="col-sm-6">
						<FormInput
							id="fecha_lectura"
							type="date"
							name="fecha_lectura"
							label="Fecha de lectura"
							error={errors.fecha_lectura}
							register={register}
						/>
					</div>

					<div className="col-sm-6">
					<FormInput
							id="evento"
							type="text"
							name="evento"
							label="Evento"
							error={errors.evento}
							register={register}
						/>
					</div>

				</div>


				<div className="form-group row">
					<div className="col-sm-6">
						<FormSelectData
							id="id_etapa"
							name="id_etapa"
							label="Etapa"
							keyArray="id_etapa"
							labelArray="nombre_etapa"
							items={etapas}
							error={errors.id_etapa}
							register={register}
							onChange={() => {}}
						/>
					</div>
					<div className="col-sm-6">
						<FormInput
							id="valor"
							type="number"
							name="valor"
							label="Valor"
							error={errors.valor}
							register={register}
							step="0.01"
						/>
					</div>

				</div>




				<FormInput
					id="uuid"
					type="hidden"
					name="uuid"
					label="UUID"
					error={errors.uuid}
					register={register}
				/>


				<div className="row">
					<div className="col-md-6 offset-md-3">
						<button className="btn btn-primary btn-block" type="submit">Guardar información</button>
					</div>
				</div>
			</form>
		</BlockUi>
	);
};
export default LecturasCaudalimetrosRegistrar;
