//const version = 'v1';
export default {
    Url : process.env.REACT_APP_API_URL,
    user : {
        login : 'auth/login',
        info : 'user/info',
        perfil : 'user/perfil',
    },
    catalogs : {
        formularios : {
            categoriasvideos : 'catalogos/formularios/videos/categorias',
            categoriasavisos : 'catalogos/formularios/avisos/categorias',
            sistemasriego : 'catalogos/formularios/huertas/sistemasriego',
            propietarios : 'catalogos/formularios/huertas/propietarios',
            estados : 'catalogos/formularios/huertas/estados',
            municipios : 'catalogos/formularios/huertas/municipios',
            localidades : 'catalogos/formularios/huertas/localidades',
            cultivos : 'catalogos/formularios/sectores/cultivos',
            variedades : 'catalogos/formularios/sectores/variedades',
            concursos : 'catalogos/formularios/concursos/categorias',
            zonas : 'zonas',
            niveles : 'niveles',
            tipo_productor: 'tipo_productor',
            etapas : 'catalogos/formularios/etapas',
            tipostensiometros : 'catalogos/formularios/tipostensiometros'
        },
        fertilizantes : {
            general : 'administracion/catalogos/fertilizantes',
            nutrientes : 'administracion/catalogos/fertilizantes_nutriente',
            precios : 'administracion/catalogos/fertilizantes_precios',
        },
        plaguicidas : {
            general : 'administracion/catalogos/plaguicidas',
            precios : 'administracion/catalogos/plaguicidas_precios',
        },
        sinc : {
            general : 'administracion/catalogos/sinc-catalogos',
        }
    },
    reportes : {
        huertas : 'administracion/exportar/huertas',
        costos : 'administracion/reportes/costos/pdf'
    },
    bitacoras : {
        productores : 'administracion/reportes/bitacoras/productores',
        soloproductores : 'administracion/reportes/bitacoras/soloproductores',
        soloaliados : 'administracion/reportes/bitacoras/soloaliados',
        exportar : 'administracion/reportes/bitacoras/exportar',
    },
    huertas : 'administracion/huertas',
    sectores : 'administracion/sectores',
    videos : 'administracion/videos',
    avisos : {
        general : 'administracion/avisos',
        reporteVistos : 'administracion/avisosvistos',
        reporteVistosDetalle : 'administracion/avisosvistos/detalle'
    },
    concursos : {
        general : 'administracion/concursos',
        campanias : 'administracion/campanias',
        campaniaEstatus : 'campanias/estatus'
        
    },
    usuarios : {
        general : 'administracion/usuarios',
        listado : 'administracion/usuarios/listado'
    },
    versiones : 'administracion/versiones',
    riegos : {
        ciclos : 'administracion/riegos/ciclos',
        productores : 'administracion/riegos/productores',
        sectoresproductor : 'administracion/riegos/sectores',
        sectoresdiagnostico : 'administracion/riegos/sectoresdiagnostico',
        diagnosticos : {
            general : 'administracion/riegos/diagnosticos',
            listado : 'administracion/riegos/diagnosticos/listado',
        },
        lecturasTensiometros : {
            general : 'administracion/riegos/lecturastensiometros',
            listado : 'administracion/riegos/lecturastensiometros/listado'
        },
        lecturasCaudalimetros : {
            general : 'administracion/riegos/lecturascaudalimetros',
            listado : 'administracion/riegos/lecturascaudalimetros/listado'
        },
        recomendaciones : {
            general : 'administracion/riegos/recomendaciones',
            estaciones : 'administracion/riegos/recomendaciones/estaciones',
            zonas : 'administracion/riegos/recomendaciones/zonas',
            etos : 'administracion/riegos/recomendaciones/etos'
        }
    },
    costos : {
        plaguicidas : 'administracion/costos/plaguicidas',
        fertilizantes : 'administracion/costos/fertilizantes',
    }
};
