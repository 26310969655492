import xlsx from "json-as-xlsx";
import moment from 'moment';
const UsuariosExportar = (content) => {
    let columns = [
        { label: 'ID', value: 'key' },
        { label: 'ID USUARIO', value: 'id_user' },
        { label: 'Tipo', value: 'name' },
        { label: 'Fecha registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Historico',
        fileName: 'Historico_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      console.log('content.length', content.length)
     xlsx(columns, content, settings, true);
  };
  export default UsuariosExportar;
