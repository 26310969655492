import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';

import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';



const LecturasEtosRegistrar = (props) => {
	const [blocking, setBlocking] = useState(false);
	const { id_recomendacion_riego_eto, id_zona, id_ciclo } = props;

	const [idZona, setIdZona] = useState(id_zona);
	const [infoRefistro, setInfoRefistro] = useState({});
	const [idHuerta, setIdHuerta] = useState(0);


	const [data] = useState({
		id_recomendacion_riego_eto: id_recomendacion_riego_eto,
		id_zona: id_zona,
		id_ciclo: id_ciclo,
		fecha: "",
		fecha_fin: "",
		semana: "",
		eto: ""
	});


	const schema = yup.object().shape({
		id_recomendacion_riego_eto: yup.number().min(0),
		id_zona: yup.number().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_ciclo: yup.number().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		fecha: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Requerido'),
		fecha_fin: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Requerido'),
		semana: yup.number().min(1, 'Requerido').max(52, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		eto: yup.number().min(0.01, 'Número no válido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido')
	});


	const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});



	const selectFinish = () => {
		if (id_recomendacion_riego_eto > 0) { obtenerRegistro(); }
	};

	const obtenerRegistro = () => {
		setBlocking(true);
		setTimeout(function () {
			Api.getAll(ApiUrl.riegos.recomendaciones.etos + '/' + id_recomendacion_riego_eto + '/show').then(response => {
				let lecturaData = response.data.data;
				reset(lecturaData[0]);
				setInfoRefistro(lecturaData[0]);
				setIdZona(lecturaData[0].id_zona);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		}, 5000);
	}

	const creaRegistro = values => {
		console.log('creaRegistro');
		setBlocking(true);
		Api.create(ApiUrl.riegos.recomendaciones.etos, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire(
					'Registrado!',
					'Registro creado exitosamente.',
					'success'
				);
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}

	const actualizaRegistro = values => {
		setBlocking(true);
		Api.update(ApiUrl.riegos.recomendaciones.etos, id_recomendacion_riego_eto, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				})
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}


	const onSubmit = values => {
		if (id_recomendacion_riego_eto > 0) {
			actualizaRegistro(values);
		} else {
			creaRegistro(values);
		}

	}

	useEffect(() => {
		if (id_recomendacion_riego_eto === '0') {
			reset(data);
		} else {
			obtenerRegistro();
		}
		// eslint-disable-next-line
	}, [id_recomendacion_riego_eto]);

	const onComplete = values => {
		props.onComplete(values);
	};

	return (
		<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

			<form onSubmit={handleSubmit(onSubmit)}>

				<FormInput
					id="id_zona"
					type="hidden"
					name="id_zona"
					label="Productor"
					error={errors.id_zona}
					register={register}
				/>
				<FormInput
					id="id_ciclo"
					type="hidden"
					name="id_ciclo"
					label="Ciclo"
					error={errors.id_ciclo}
					register={register}
				/>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormSelectApi
							id="id_ciclo"
							name="id_ciclo"
							label="Ciclo"
							url={ApiUrl.riegos.ciclos}
							keyArray="id_ciclo"
							labelArray="nombre_ciclo"
							error={errors.id_ciclo}
							onFinish={() => { }}
							register={register}
							onChange={(value) => { }}
						/>
					</div>

					<div className="col-sm-6">
						<FormInput
							id="semana"
							type="number"
							name="semana"
							label="Semana"
							error={errors.semana}
							register={register}
						/>
					</div>


				</div>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput
							id="fecha"
							type="date"
							name="fecha"
							label="Fecha Inicio"
							error={errors.fecha}
							register={register}
						/>
					</div>

					<div className="col-sm-6">
						<FormInput
							id="fecha_fin"
							type="date"
							name="fecha_fin"
							label="Fecha Fin"
							error={errors.fecha_fin}
							register={register}
						/>
					</div>

				</div>

				<div className="form-group row">
					<div className="col-sm-12">
						<FormInput
							id="eto"
							type="number"
							name="eto"
							label="Valor"
							error={errors.eto}
							register={register}
							step="0.01"
						/>
					</div>

				</div>


				<div className="row">
					<div className="col-md-6 offset-md-3">
						<button className="btn btn-primary btn-block" type="submit">Guardar información</button>
					</div>
				</div>
			</form>
		</BlockUi>
	);
};
export default LecturasEtosRegistrar;
