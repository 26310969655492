import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import Modal from 'react-bootstrap/Modal';
import EditButton from '../../../nuup/componentes/Generales/EditButton';
import FertilizantesNutrientesForm from './FertilizantesNutrientesForm';

const FertilizantesNutrientesTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [nutriente, setNutriente] = useState([]);
	const [state, setState] = useState({
	id_producto_comercial : 0,
	nutrientes: [],
	});

	const columns = [
		{
			name: 'ID',
			selector: 'id',
			maxWidth: '100px',
			sortable: true,
			right: false
		},

		{
			name: 'NUTRIENTE',
			selector: 'nutriente.nombre_nutriente',
			maxWidth: '150px',
			sortable: true,
			right: false
		},

		{
			name: 'PORCENTAJE',
			selector: 'porcentaje',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			maxWidth: '300px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><EditButton onClick={() =>onEditNutriente(row)} ><i className="fa fa-edit"></i></EditButton> <DeleteButton onClick={() => eliminaNutriente(row.id)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];

	const onEditNutriente = (data) => {
		setNutriente(data);
		setShow(true);
	}



	useEffect(() => {
		obtenerNutrientes();
	// eslint-disable-next-line react-hooks/exhaustive-deps	
	},[]);


	  const obtenerNutrientes = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.catalogs.fertilizantes.nutrientes + '/' + props.id_producto_comercial)
		  .then(response => {
			setBlocking(false);
			hideFormEdit();
			setState({
			  nutrientes: response.data.data
			});
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaNutriente = (id_producto_comercial_precio) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.catalogs.fertilizantes.nutrientes, id_producto_comercial_precio)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						hideFormEdit();
						Swal.fire(
						  'Eliminado!',
						  'Nutriente eliminado.',
						  'success'
						);
					}
				obtenerNutrientes();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el nutriente.',
					'error'
				  );
				  obtenerNutrientes();
				});

			}
		  })

	};


	const showForm = () => {
		setNutriente([]);
		setShow(true);
	};


	const hideFormEdit = () => {
		setNutriente([]);
		setShow(false);
	};

	const onChangeElementos = () => {
		obtenerNutrientes();
	};


	
	const actionsMemo = React.useMemo(() => <><button onClick={() =>showForm()} className="btn btn-secondary">Registrar</button></>, []);

	return (
			<>
			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Registrar / Actualizar nutriente
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FertilizantesNutrientesForm onChangeElementos={onChangeElementos} id_producto_comercial={props.id_producto_comercial} nutriente={nutriente} />
              </Modal.Body>
            </Modal>


		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<DataTable
				pagination
				columns={columns}
				data={state.nutrientes}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				actions={actionsMemo}
			/>
		</BlockUi>
		</>
    );
  };
export default FertilizantesNutrientesTabla;
