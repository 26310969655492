import React, { useState } from 'react'
import Api from '../../api/global.service'
import ApiUrl from '../../constants/Api'
import DataTable from 'react-data-table-component'
import Swal from 'sweetalert2'
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton'
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral'
import '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui'
// import AvisosExportar from './AvisosExportar'
import ModalDetalleVisto from './ModalDetalleVisto'
import Carousel from 'react-bootstrap/Carousel'
// import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas'
import { useFetchWithPaginate } from '../../nuup/hooks/useFetchWithPaginate'
import { Link } from "react-router-dom";

const AvisosTabla = (props) => {
  const [verDetalles, setVerDetalles] = useState(false)
  const [idAviso, setIdAviso] = useState(false)
  const [
    blocking,
    setBlocking,
    datosFiltrados,
    obtenerDatosFiltrados,
    setPage,
    totalRows,
    perPage,
    handlePerRowsChange,
  ] = useFetchWithPaginate(ApiUrl.avisos.general)

  const getImages = (row) => {
    if (row.imagen === null) {
      return 'N/A'
    }

    const imageURLs = row.imagen.split(',')
    if (imageURLs.length === 1) {
      return <img alt="Imagen" className="img-thumbnail" src={ApiUrl.Url + imageURLs[0]} />
    }

    // for more than one image use Carousel
    return (
      <Carousel interval={null}>
        {imageURLs.map((imURL) => {
          return (
            <Carousel.Item>
              <img alt="Imagen" className="img-thumbnail" src={ApiUrl.Url + imURL} />
            </Carousel.Item>
          )
        })}
      </Carousel>
    )
  }

  const columns = [
    {
      name: 'ID',
      selector: 'id_aviso',
      maxWidth: '100px',
      sortable: true,
      right: false,
    },
    {
      name: 'CATEGORÍA',
      selector: 'nombre_categoria',
      sortable: true,
      maxWidth: '100px',
    },

    {
      name: 'TÍTULO',
      selector: 'titulo',
      sortable: true,
      maxWidth: '350px',
      right: false,
      wrap: true,
    },
    {
      name: 'IMAGEN',
      // sortable: true,
      maxWidth: '250px',
      right: false,
      cell: (row) => getImages(row),
    },
    {
      name: 'ENVIADOS / VISTOS',
      sortable: true,
      maxWidth: '150px',
      right: false,
      cell: (row) => (
        <button
          onClick={() => abrirDetalle(row.id_aviso)}
          className={getColorPorcenrtaje(row.porcentaje_visto)}
        >
          {row.enviados + ' / ' + row.visto + ' (' + row.porcentaje_visto + '%)'}
        </button>
      ),
    },
    {
      name: 'CREADO',
      selector: 'created_at',
      sortable: true,
      maxWidth: '150px',
      right: false,
      wrap: true
    },
    {
      name: 'OPCIONES',
      button: true,
      cell: (row) => (
        <>
          <Link className="btn btn-xs btn-warning mx-2" to={'avisos/registrar/' + row.id_aviso}>
            <i className="fa fa-edit"></i>
          </Link>
          <DeleteButton onClick={() => eliminaAviso(row.uuid)}>
            <i className="fa fa-trash"></i>
          </DeleteButton>
        </>
      ),
    },
  ]

  // Avisos Content column is hideable
  const ExpandableComponent = ({ data }) => <div>{data.aviso.replace(/(<([^>]+)>)/gi, '')}</div>

  const onCerrarModal = (id_aviso) => {
    setVerDetalles(false)
    setIdAviso(0)
  }

  const abrirDetalle = (id_aviso) => {
    setVerDetalles(true)
    setIdAviso(id_aviso)
  }

  const getColorPorcenrtaje = (valor) => {
    let color = 'btn btn-success'
    if (valor >= 0 && valor <= 33) {
      color = 'btn btn-danger'
    } else if (valor > 33 && valor <= 70) {
      color = 'btn btn-warning'
    }
    return color
  }

  const eliminaAviso = (uuid) => {
    Swal.fire({
      title: '¿Esta seguro?',
      text: 'El registro se eliminará',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        setBlocking(true)
        Api.delete(ApiUrl.avisos.general, uuid)
          .then((response) => {
            setBlocking(false)
            let _result = response.data
            if (_result.success === 1) {
              setBlocking(false)
              Swal.fire('Eliminado!', 'Aviso eliminado.', 'success')
            }
            obtenerDatosFiltrados()
          })
          .catch((e) => {
            setBlocking(false)
            Swal.fire('Error!', 'Error al eliminar el aviso.', 'error')
            obtenerDatosFiltrados()
          })
      }
    })
  }

  return (
    <>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        {/* <HerramientasTablas 
				txtBusqueda="Buscar avisos" 
				datosTabla={datosFiltrados} 
				urlRegistrar="/administracion/avisos/registrar/0" 
				columnasFiltrar={["id_aviso","titulo","aviso","nombre_categoria","porcentaje_visto","created_at"]}
				// exportar={AvisosExportar}
				onFiltrar={(datosFiltrados) => setDatosFiltrados(datosFiltrados)}
			/> */}

        <DataTable
          pagination
          columns={columns}
          data={datosFiltrados}
          Clicked
          noDataComponent={'Sin datos por mostrar'}
          customStyles={estiloGeneral}
          expandableRows
          expandableRowsComponent={<ExpandableComponent />}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={handlePerRowsChange}
          onSort={obtenerDatosFiltrados}
          sortServer
        />
      </BlockUi>
      <ModalDetalleVisto aviso={idAviso} show={verDetalles} onCerrarModal={onCerrarModal} />
    </>
  )
}
export default AvisosTabla
