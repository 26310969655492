import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormInputFile from '../../nuup/componentes/Forms/FormInputFile.jsx';
import FormInputTextArea from '../../nuup/componentes/Forms/FormInputTextArea.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Swal from "sweetalert2";
// import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"

import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import '../../nuup/styles/Formularios.css';

const labels = {
  id_concurso: "",
  id_campania: "Selecciona la Campaña a la que corresponde",
  nombre_concurso: "Nombre del Concurso",
  descripcion_concurso: "Descripción del Concurso",
  fecha_inicio: "Fecha de Inicio del Concurso",
  fecha_final: "Fecha del Final del Concurso",
  id_cat_modal_concurso: "Selecciona la Modalidad de Concurso",
  puntos_para_ganar: "Puntos para ganar Concurso",
  posibles_ganadores: "Posibles ganadores del Concurso",
  premio: "Premio",
  file: "Imagen del Premio",
}

const ConcursosRegistrar = () => {
  const { id_concurso } = useParams();
  const [blocking, setBlocking] = useState(false);
  const [modal, setModal] = useState(false)
  const [fetchedCampanias, setFetchedCampanias] = useState(false)
  const [fetchedModalidades, setFetchedModalidades] = useState(false)  

  // eslint-disable-next-line
  const [file,setFile] = useState();

  const schema = yup.object().shape({
    id_concurso: yup.number().min(0),
    id_campania: yup.number().required('Elegir una campaña'),
    nombre_concurso: yup.string().required('Nombre Requerido'),
    descripcion_concurso: yup.string(),
    fecha_inicio: yup.date().required('Fecha de inicio requerida'),
    fecha_final: yup.date().required('Fecha final requerida'),
    // actualizar el max dinámicamente ??
    id_cat_modal_concurso: yup.number().required('Elegir una modalidad'),
    puntos_para_ganar: yup.number().min(0),
    posibles_ganadores: yup.number().min(0),
    file: yup.mixed().test('fileFormat', 'Solo se permiten archivos .jpg,jpeg,png', (value) => {
      if (!value.length) return true;
      return value[0] && ["image/jpg", "image/jpeg", "image/png"].includes(value[0].type);
    }),
  });

  useEffect(() => {
    if(id_concurso  !== '0' && fetchedCampanias && fetchedModalidades){
      obtenerConcurso()
    }
  // eslint-disable-next-line
  },[id_concurso, fetchedCampanias, fetchedModalidades]);

  const obtenerConcurso = () => {
    setBlocking(true);
    Api.getAll(ApiUrl.concursos.general + '/' + id_concurso).then(response => {
      let concursoData = response.data.data;
      reset(concursoData);
      setBlocking(false);
    }).catch(e => {
      setBlocking(false);
      let errorMessage = e
      if (e.response?.status === 404) {
        errorMessage = `No se encontró el concurso con id ${id_concurso}`
      }
      console.log(e);
      Swal.fire({
        icon: 'error',
        title: '',
        text: JSON.stringify(errorMessage),
        confirmButtonText: 'Regresar a Listado',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = '/administracion/concursos'; 
        }
      })
    });
}

  const { register, handleSubmit, setError, errors, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      id_concurso : id_concurso,
      descripcion_concurso: "",
      fecha_inicio:  "",
      fecha_final:  "",
      id_cat_modal_concurso: 1,
      puntos_para_ganar: 0,
      posibles_ganadores: 0,
      file: "",
    }
  });


  // useEffect(() => {
  //   // Para editar!
  //   // if (id_concurso > 0) {obtenerConcurso();}
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, []);

  // const obtenerConcurso = () => {
  //     setBlocking(true);
  //     setTimeout(function(){ 
  //         Api.getAll(ApiUrl.Concursos.general + '/' + id_aviso).then(response => {
  //             setBlocking(false);
  //             let avisoData = response.data.data;
  //             reset(avisoData[0]);
  //             console.log(avisoData[0].aviso);
  //             setAviso(avisoData[0].aviso);
  //         }).catch(e => {
  //             setBlocking(false);
  //             console.log(e);
  //         });
  //      }, 3000);
  // }

  const creaConcurso = values => {
    setBlocking(true);
    const formData = new FormData();
    for (let i = 0; i < values.file.length; i++) {
      formData.append('files[]', values.file[i]);
    }	
    formData.append('id_campania',values.id_campania);
    formData.append('nombre_concurso',values.nombre_concurso);
    formData.append('descripcion_concurso',values.descripcion_concurso);
    formData.append('fecha_inicio',values.fecha_inicio);
    formData.append('fecha_final',values.fecha_final);
    formData.append('id_cat_modal_concurso',values.id_cat_modal_concurso);
    formData.append('puntos_para_ganar',values.puntos_para_ganar);
    formData.append('posibles_ganadores',values.posibles_ganadores);

    // values = {...values, file: ""}
    Api.createMultiPart(ApiUrl.concursos.general,formData).then(response => {
      setBlocking(false);
      let _result = response.data;
      if(_result.success === 1){
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Concurso creado correctamente',
        })
        window.location = '/administracion/concursos'; 
      } else{
        Swal.fire({
          icon: 'error',
          title: '',
          text: _result.message,
        })
      }
    }).catch(e => {
      setBlocking(false);
      let errorMessage = e
      if (e.response?.status === 422) {
        errorMessage = e.response.data.message
        for (let key in errorMessage) {
          setError(key, {
            type: "server",
            message: errorMessage[key]
          })
        }
        errorMessage = Object.values(errorMessage).join(" / ")
      }
      
      setModal(false)
      Swal.fire({
        icon: 'error',
        title: '',
        text: JSON.stringify(errorMessage),
      })
    });
  }


  const actualizaConcurso = values => {
    Swal.showLoading();
    Api.update(ApiUrl.concursos.general, values.id_concurso, values).then(response => {
        let _result = response.data;
        if(_result.success === 1){
          setModal(false)
            Swal.fire({
                icon: 'success',
                title: '',
                confirmButtonText: 'Continuar',
                text: _result.message,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location = '/administracion/concursos'; 
                }
              })
            }else{
            Swal.fire({
                icon: 'error',
                title: '',
                text: _result.message,
              })
        }
    }).catch(e => {
      setBlocking(false);
      let errorMessage = e
      if (e.response?.status === 422) {
        errorMessage = e.response.data.message
        for (let key in errorMessage) {
          setError(key, {
            type: "server",
            message: errorMessage[key]
          })
        }
        errorMessage = Object.values(errorMessage).join(" / ")
      }
      
      setModal(false)
      Swal.fire({
        icon: 'error',
        title: '',
        text: JSON.stringify(errorMessage),
      })
    });
  }

  const onSubmit = () => {
    if (id_concurso > 0) {
      actualizaConcurso(getValues());
    } else {
      creaConcurso(getValues());
    }
  }

  const onConfirm = () => {
    setModal(true)
  }

  const onChangeFile = (e) => {
    setFile(e.target.files);
  }

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={() => setModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Revisar Información Antes de Enviar
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <ListGroup >
              {Object.keys(getValues()).map((key, ind) => {
                if (key === 'file') {
                  return (
                    <ListGroup variant="flush" key={ind}>
                      { Array.from(getValues().file).map((it, ind) => {
                        return (
                          <ListGroup.Item key={ind}>{it.name}</ListGroup.Item>
                        )
                      })}
                    </ListGroup>
                  )
                }
                return (
                  <ListGroup.Item>
                    {labels[key]}&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>{getValues()[key]}</strong>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModal(false)}>Seguir Editando</Button>
            <Button variant="primary" onClick={onSubmit}>ENVIAR</Button>
          </Modal.Footer>
        </Modal>

        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item"><Link to="/administracion/concursos">Listado de Concursos</Link></li>
          <li className="breadcrumb-item active">Registrar Concurso</li>
        </ol>
        <h1 className="page-header">Administración de Concursos <small></small></h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelHeader>Datos del Concurso</PanelHeader>
              <PanelBody>
                <BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>
                  <form onSubmit={handleSubmit(onConfirm)}>
                  {id_concurso !== 0 ? <input {...register('id_concurso')} type="hidden" />: null} 
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <div className="form-group row">
                            <div className="col-md-12">
                              <FormSelectApi 
                                id="id_campania"
                                name="id_campania"
                                label={labels.id_campania}
                                url={ApiUrl.concursos.campanias}
                                keyArray="id_campania"
                                labelArray="nombre_campania"
                                error={errors.id_campania}
                                onFinish={() => setFetchedCampanias(true)}
                                register={register}
                              />
                            </div>
                          </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <FormInput 
                              id="nombre_concurso"
                              type="text"
                              name="nombre_concurso"
                              label={labels.nombre_concurso}
                              error={errors.nombre_concurso}
                              register={register}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <FormInputTextArea 
                              id="descripcion_concurso"
                              type="text"
                              name="descripcion_concurso"
                              label={labels.descripcion_concurso}
                              error={errors.descripcion_concurso}
                              register={register}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <FormInput 
                              id="fecha_inicio"
                              type="date"
                              name="fecha_inicio"
                              label={labels.fecha_inicio}
                              error={errors.fecha_inicio}
                              register={register}
                            />
                          </div>
                          <div className="col-sm-6">
                            <FormInput 
                              id="fecha_final"
                              type="date"
                              name="fecha_final"
                              label={labels.fecha_final}
                              error={errors.fecha_final}
                              register={register}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <FormSelectApi 
                              id="id_cat_modal_concurso"
                              name="id_cat_modal_concurso"
                              label={labels.id_cat_modal_concurso}
                              url={ApiUrl.catalogs.formularios.concursos}
                              keyArray="id_cat_modal_concurso"
                              labelArray="nombre_modalidad"
                              error={errors.id_cat_modal_concurso}
                              onFinish={() => setFetchedModalidades(true)}
                              register={register}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <FormInput 
                              id="puntos_para_ganar"
                              type="number"
                              name="puntos_para_ganar"
                              label={labels.puntos_para_ganar}
                              error={errors.puntos_para_ganar}
                              register={register}
                            />
                          </div>
                          <div className="col-sm-6">
                            <FormInput 
                              id="posibles_ganadores"
                              type="number"
                              name="posibles_ganadores"
                              label={labels.posibles_ganadores}
                              error={errors.posibles_ganadores}
                              register={register}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <FormInputFile 
                              id="file"
                              name="file"
                              label={labels.file}
                              keyArray="file"
                              labelArray="tipo_documento"
                              error={errors.file}
                              register={register}
                              onChange={onChangeFile}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 offset-md-9">
                            <button className="btn btn-primary btn-block" type="submit">{id_concurso !== 0 ? "Actualizar Concurso" : "Crear Concurso"}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConcursosRegistrar;
