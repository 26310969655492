import React, { useEffect, useState } from 'react';
import LecturasTensiometrosTabla from './lecturastensiometros/LecturasTensiometrosTabla.jsx';
import Modal from 'react-bootstrap/Modal';
import LecturasTensiometrosRegistrar from './lecturastensiometros/LecturasTensiometrosRegistrar.jsx';

const RiegosTensiometros = (props) => {
  const [modal, setModal] = useState(false);
  const [idSectorLecturaTensiometro, setIdSectorLecturaCaudalimetro] = useState(0);
  const [reload, setReload] = useState(0);

  const onComplete = () => {
    setReload(reload + 1);
    setModal(false);
  };

  const onUpdateClick = (id_sector_lectura_tensiometro) => {
    setIdSectorLecturaCaudalimetro(id_sector_lectura_tensiometro);
    setModal(true);
  };



  return (
    <>

      <div className="row">
        <div className="col col-md-12">

          <div className="row">
            <div className="col col-md-2 offset-md-10">
              <a className='btn btn-info' onClick={() => setModal(!modal)}>Registrar lectura</a>
            </div>
          </div>

          <LecturasTensiometrosTabla
            onUpdateClick={(id_sector_lectura_tensiometro) => onUpdateClick(id_sector_lectura_tensiometro)}
            id_productor={props.id_productor}
            id_ciclo={props.id_ciclo}
            reload={reload}
          />
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Registar / Actualizar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LecturasTensiometrosRegistrar
            id_ciclo={props.id_ciclo}
            huertas={props.huertas}
            id_sector_lectura_tensiometro={idSectorLecturaTensiometro}
            tipos_tensiometros={props.tipos_tensiometros}
            id_productor={props.id_productor}
            onComplete={(result) => onComplete(result)}
          />
        </Modal.Body>
      </Modal>

    </>
  );
};
export default RiegosTensiometros;
