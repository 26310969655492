import React,{useState, useEffect} from 'react';
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
  Brush
} from "recharts";
import BlockUi from 'react-block-ui';


const MoistureGraph = ({
  limite_humedad,
  limite_seco,
  avg_10cm,
  avg_20cm,
  avg_30cm,
  fechaInicial,
  fechaFinal,
  url
}) => {
  const [data, setData] = useState(null);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    setBlocking(true);
    // const url = `https://api-user.arable.cloud/api/v2/sites/data/sentek_daily?temp=C&pres=mb&size=mm&speed=mps&site_id=6126db609784c101dcda113a&limit=1000&start_time=${fechaInicial}&end_time=${fechaFinal}&local_time=America/Mexico_City`;
    axios
      .get(url, {
        headers: {
          Authorization: `Apikey ${process.env.REACT_APP_ARABLE_TOKEN}`,
        },
      })
      .then((res) => {
        setBlocking(false);
        const processedData = res.data.map((it) => {
          return {
            name: it.time.substr(0, 10),
            moisture_0_mean: parseFloat(it.moisture_0_mean),
            moisture_1_mean: parseFloat(it.moisture_1_mean),
            moisture_2_mean: parseFloat(it.moisture_2_mean),
          }
        });
        setData(processedData);
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("Falló la conexión con Arable");
        setBlocking(false);
      });
  }, [avg_10cm, avg_20cm, avg_30cm, fechaInicial, fechaFinal, url]);

  return (
      <BlockUi tag="div" blocking={blocking}>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              angle={-90}
              textAnchor="end"
              height={80}
              padding={{ top: 20 }}
            />
            <YAxis width={20} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <ReferenceArea
              y1={limite_humedad}
              y2={limite_seco}
              stroke="green"
              fill="#32CD324A"
              strokeWidth={2}
              label="Zona de Confort"
            />
            <ReferenceArea
              y1={0}
              y2={limite_seco}
              stroke="red"
              fill="#FF000045"
              strokeWidth={2}
              label="Suelo demasiado Seco"
            />
            <ReferenceArea
              y1={limite_humedad}
              y2={60}
              stroke="blue"
              fill="#0000FF47"
              strokeWidth={2}
              label="Suelo demasiado Húmedo"
            />
            {avg_30cm && (<Line
              name="30 cm"
              type="monotone"
              dataKey="moisture_0_mean"
              stroke="green"
              activeDot={{ r: 8 }}
            />)}
            {avg_20cm && (<Line
              name="20 cm"
              type="monotone"
              dataKey="moisture_1_mean"
              stroke="brown"
              activeDot={{ r: 8 }}
              />)}
            {avg_10cm && (<Line
              name="10 cm"
              type="monotone"
              dataKey="moisture_2_mean"
              stroke="black"
              activeDot={{ r: 8 }}
              />)}
            <Brush dataKey="name" height={30} stroke="green" />
          </LineChart>
        </ResponsiveContainer>
      </BlockUi>
  );
};

export default MoistureGraph;