import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import LecturasTensiometrosTabla from './LecturasTensiometrosTabla.jsx';

const RiegosDiagnosticosListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Lecturas de tensiómetros</Link></li>
        <li className="breadcrumb-item active">Listado de lecturas</li>
      </ol>
      <h1 className="page-header">Lecturas de tensiómetros <small></small></h1>
      <Panel>
        <PanelHeader>Listado de lecturas</PanelHeader>
        <PanelBody>
            <LecturasTensiometrosTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default RiegosDiagnosticosListar;
