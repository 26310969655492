import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable,{defaultThemes } from 'react-data-table-component';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

const AvisosDetalleVistosTabla = (props) => {
	const [blocking, setBlocking] = useState(false);

	const [state, setState] = useState({
	id_aviso : 0,
	avisos: [],
	currentUsuario: null,
	currentIndex: -1
	});



	const columns = [

		{
			name: 'NOMBRE',
			sortable: true,
			maxWidth: '250px',
			cell: row => <div><div style={{ fontWeight: 700 }}>{row.nombre + ' ' + row.apellido1}</div></div>
		},
				
		{
			name: 'ENVIADO',
			selector: 'enviado',
			sortable: true,
			maxWidth: '250px',
			right: false
		},

		{
			name: 'VISTO',
			selector: 'visto',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
	
		{
			name: 'TARDÓ',
			sortable: true,
			maxWidth: '200px',
			right: false,
			cell: row => <div><div style={{ fontWeight: 700 }}>{getTardoEnVer(row.visto,row.dias,row.horas,row.minutos,row.segundos)}</div></div>
		},
	];


	const getTardoEnVer = (visto,dias,horas,minutos,segundos)  => {
		let result = 'N/A';
		let continuar = true;
		if(visto === 1 && continuar === true){
			if(dias > 0){
				continuar = false;
				result = dias + ' dia(s)';
			}
	
			if(horas > 0 && continuar === true){
				continuar = false;
				result = horas + ' hora(s)';
			}
	
			if(minutos > 0 && continuar === true){
				continuar = false;
				result = minutos + ' minuto(s)';
			}
	
			if(segundos > 0 && continuar === true){
				continuar = false;
				result = segundos + ' segundos';
			}
	
		}
	
		return result;
	}
	

	const conditionalRowStyles = [
		{
		  when: row => row.visto === '0',
		  style: {
			backgroundColor: 'rgba(215, 44, 44, 0.4)',
			color: 'black',
			'&:hover': {
			  cursor: 'pointer',
			},
		  },
		},
		{
			when: row => row.visto === '1',
			style: {
			  backgroundColor: 'rgba(63, 195, 128, 0.4)',
			  color: 'black',
			  '&:hover': {
				cursor: 'pointer',
			  },
			},
		  }
		
	  ];

	  const customStyles = {
		cells: {
		  style: {
			'&:not(:last-of-type)': {
			  borderRightStyle: 'solid',
			  borderRightWidth: '1px',
			  borderRightColor: defaultThemes.default.divider.default,
			},
		  },
		},
	  };

	useEffect(() => {
		obtenerDetalles();
	// eslint-disable-next-line react-hooks/exhaustive-deps	 	
	},[]);

	  const obtenerDetalles = ()  => {
		setBlocking(true);	
		Api.getAll(ApiUrl.avisos.reporteVistosDetalle + '/' + props.id_aviso).then(response => {
			  setState({
			  avisos: response.data.data
			});
			setBlocking(false);	
		  })
		  .catch(e => {
			setBlocking(false);	
			console.log(e);
		  });
	  }

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <DataTable
			pagination
			columns={columns}
			data={state.avisos}
			Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={customStyles}
			conditionalRowStyles={conditionalRowStyles}
          />
		</BlockUi>
	);
  };
export default AvisosDetalleVistosTabla;
