import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const FertilizantesPreciosForm = (props) => {
		const schema = yup.object().shape({
			id_producto_comercial_precio: yup.number().min(0),
			id_producto_comercial: yup.number().min(0),
			id_unidad: yup.number('Seleccione una unidad').min(1,'Seleccione una unidad').required('Seleccione una unidad'),
			precio: yup.number('Precio requerido').min(1,'Precio requerido').required('Precio requerido')
		});

		const [blocking, setBlocking] = useState(true);
		const [btncancelar, setBtncancelar] = useState(false);
		const [state] = useState({
			id_producto_comercial_precio : (props.precio.id_producto_comercial_precio > 0) ? props.precio.id_producto_comercial_precio : 0,
			id_producto_comercial : props.id_producto_comercial,
			cantidad : '',
			id_unidad : 0,
			precio : '',
		});
		const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: state
	});

	
	const onSubmit = values => {
		if(values.id_producto_comercial_precio > 0){
			actualizarPrecio(values);
		}else{
			crearPrecio(values);
		}
	}

	
	const crearPrecio = (values) => {
		setBlocking(true);
			Api.create(ApiUrl.catalogs.fertilizantes.precios,{
				id_producto_comercial_precio : values.id_producto_comercial_precio,
				id_producto_comercial : props.id_producto_comercial,
				cantidad : values.cantidad,
				id_unidad : values.id_unidad,
				precio : values.precio
			}).then(response => {
				setBlocking(false);
				reset(state);
				cancelarEdit();
				let _result = response.data;
				if(_result.success === 1){
					Swal.fire(
						'Guardado!',
						'Precio creado.',
						'success'
					);
					props.onChangeElementos();
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire(
				'Error!',
				'Error al crear el precio.',
				'error'
				);
			});
			
		}
		

		const actualizarPrecio = values => {
			setBlocking(true);
			Api.update(ApiUrl.catalogs.fertilizantes.precios,values.id_producto_comercial_precio, values).then(response => {
				setBlocking(false);
				let _result = response.data;
				if(_result.success === 1){
					cancelarEdit();
					Swal.fire({
						icon: 'success',
						title: '',
						text: _result.message,
					  })
					  props.onChangeElementos();
				}else{
					Swal.fire({
						icon: 'error',
						title: '',
						text: _result.message,
					  })
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire({
					icon: 'error',
					title: '',
					text: e,
				  })
			});
		}

		
		const cancelarEdit = () => {
			setBtncancelar(false);
			reset({
				id_producto_comercial_precio : props.precio.id_producto_comercial_precio,
				id_producto_comercial : props.precio.id_producto_comercial,
				cantidad : '',
				id_unidad : 0,
				precio : '',
			});
		}

		const selectFinish = () => {
			reset(props.precio);
			setBlocking(false);
		};

	return (
		<> 
			<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormInput 
					id="id_producto_comercial_precio"
					type="hidden"
					name="id_producto_comercial_precio"
					label="id_producto_comercial_precio"
					error={errors.id_producto_comercial_precio}
					register={register}
					/>
			
			<FormInput 
					id="id_producto_comercial"
					type="hidden"
					name="id_producto_comercial"
					label="id_producto_comercial"
					error={errors.id_producto_comercial}
					register={register}
					/>


			<div className="row">

			<div className="col-md-4">
				<FormInput 
					id="cantidad"
					type="number"
					name="cantidad"
					label="CANTIDAD"
					error={errors.cantidad}
					register={register}
					/>
			</div>

			<div className="col-md-4">
				<FormSelectApi 
					id="id_unidad"
					name="id_unidad"
					label="Unidad"
					url="catalogos/precios"
					keyArray="id_unidad"
					labelArray="nombre_unidad"
					error={errors.unidad}
					onFinish={selectFinish}
					register={register}
				/>
			</div>

			<div className="col-md-4">
				<FormInput 
				id="precio"
				type="number"
				name="precio"
				label="Precio"
				step="0.01"
				error={errors.precio}
				register={register}
				/>
			</div>

			</div>

	
	
				<div className="row" >
				<div className="col-md-4 offset-md-8">
					<div className="row" >
						<div className="col-md-12">
							<button className="btn btn-primary btn-block" type="submit">{(btncancelar) ? 'Actualizar' : 'Guardar'}</button>
							{btncancelar && (
								<button className="btn btn-warning btn-block" onClick={cancelarEdit}>Cancelar</button>
							)}
						</div>
	
					</div>
				</div>
				</div>
			</form>		
			</BlockUi>
		</>
	);
};
export default FertilizantesPreciosForm;
