import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import BlockUi from 'react-block-ui'
import Api from '../../../api/global.service'
import renderLabel from './RenderLabel'
import { coloresPastel } from './Colores'
import { format } from "date-fns";
import { countActiveInactive, countActiveUsers } from './DataHelpers'

export const GraficasActivos = ({ fechaInicial, fechaFinal }) => {
  const [blocking, setBlocking] = useState(true)
  const [activeUsers, setActiveUsers] = useState([])
  const [activeInactiveUsers, setActiveInactiveUsers] = useState([])
  const [productoresInactivos, setProductoresInactivos] = useState([])

  useEffect(() => {
    Api.getAll('graficas/productores_activos', {
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
    })
      .then((response) => {
        const activeUsers = countActiveUsers(response.data)
        setProductoresInactivos(activeUsers.listadoInactivos)
        setActiveUsers(activeUsers.totales)
        setActiveInactiveUsers(countActiveInactive(response.data))
        setBlocking(false)
      })
      .catch((e) => {
        setBlocking(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal])

  return (
    <BlockUi tag="div" blocking={blocking}>
      {/*
      <div className="row">
        <div className="col-md-12">
          <h4>Productores por actividad en el periodo del: "{format(new Date(fechaInicial), "dd MMM yyyy")}" al "{format(new Date(fechaFinal), "dd MMM yyyy")}"</h4>
          <p style={{ marginBottom: 5 }}>
            <span style={{ color: '#47AB52' }}> Sólo Bitácora:</span> Productores que crearon al
            menos una bitácora (Aplicación, cosecha, riego, actividad huerta, preparación, plantación, gasto o ingreso), pero no consultaron clima (aviso ó de tiempo real) en el periodo seleccionado
          </p>
          <p style={{ marginBottom: 5 }}>
            <span style={{ color: '#FFC107' }}>Sólo Clima:</span> Productores que hicieron al menos
            una consulta de clima (aviso ó de tiempo real), pero no crearon bitácoras, en el periodo seleccionado.
          </p>
          <p style={{ marginBottom: 5 }}>
            <span style={{ color: '#E74155' }}>Clima y Bitácora:</span> Productores que hicieron al
            menos una consulta de clima (aviso ó de tiempo real) y crearon al menos una bitácora, en el periodo seleccionado
          </p>

          <ResponsiveContainer width="100%" height={200}>
            <PieChart width={'100%'} height={200}>
              <Pie
                data={activeUsers}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="total"
                label={renderLabel}
                legendType="square"
                // labelLine
                nameKey="label"
              >
                {activeUsers.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={coloresPastel[index % coloresPastel.length]}
                    stroke={'#fff'}
                    strokeWidth={2}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <br />
      <hr />
      <br />
      */}
      {/* <div className="row">
        <div className="col-md-12">
          <h4>Productores Activos VS Inactivos</h4>
          <p style={{ marginBottom: 5 }}>
            <span style={{ color: '#E74155' }}>Activos:</span> Productores que hicieron al menos una
            consulta de clima , ó crearon al menos una bitácora, en el periodo seleccionado.
          </p>
          <p style={{ marginBottom: 5 }}>
            <span style={{ color: '#47AB52' }}> Inactivos:</span> Productores que no hicieron
            ninguna consulta a clima (aviso ó de tiempo real), ni crearon ninguna bitácora, en el periodo seleccionado.
          </p>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart width={'100%'} height={200}>
              <Pie
                data={activeInactiveUsers}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="total"
                label={renderLabel}
                legendType="square"
                nameKey="label"
              >
                {activeInactiveUsers.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={coloresPastel[index % coloresPastel.length]}
                    stroke={'#fff'}
                    strokeWidth={2}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <h4>Listado de productores Inactivos entre el "{format(new Date(fechaInicial), "dd MMM yyyy")}" y el "{format(new Date(fechaFinal), "dd MMM yyyy")}".</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Teléfono</th>
              </tr>
            </thead>
            <tbody>
              {productoresInactivos.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.full_name}</td>
                  <td>{item.phone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </BlockUi>
  )
}
