import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from "recharts";
import BlockUi from "react-block-ui";
import Api from "../../../api/global.service";
import { format } from "date-fns";
import { getDateRange, createNewDeletedTotalUsersArray } from "./DataHelpers";
import moment from 'moment'
import xlsx from "json-as-xlsx";

export const GraficaProductoresTotal = ({
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
}) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [labels, setLabels] = useState(false);

  useEffect(() => {
    const dateRange = getDateRange(fechaInicial, fechaFinal);
    Api.getAll("graficas/total_productores_registrados", { fechaInicial: fechaInicial, fechaFinal: fechaFinal })
      .then((response) => {
        setBlocking(false);
        setBlocking(false);
        setTotal(response.data.total_final.total);
        setData(
          createNewDeletedTotalUsersArray(
            response.data.creados,
            response.data.borrados,
            dateRange,
            response.data.total_inicio,
            response.data.total_final
          )
        );
      })
      .catch((e) => {
        setBlocking(false);
        console.log("Error", e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal]);

  const exportarGrafica = () => {
      let columns = [
          { label: 'Semana', value: 'label' },
          { label: 'Nuevos', value: 'new_users' },
          { label: 'Borrados', value: 'deleted_users' },
          { label: 'Acumulados', value: 'total_users' },
        ];
        let settings = {
          sheetName: 'ProductoresMT',
          fileName: `ProductoresMT_de_${fechaInicial}_a_${fechaFinal}_generado_${moment().format("YYYYMMDD_HHmmss")}` ,
          extraLength: 3,
          writeOptions: {}
        }
        
       xlsx(columns, data, settings, true);
    };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <button
        onClick={() => setLabels(!labels)}
        className={`btn mx-2 ${labels ? "btn-secondary" : "btn-light"} `}
      >
        {labels ? "esconder valores" : "Ver valores"}
      </button>
      <button
        onClick={() => exportarGrafica()}
        className={`btn`}
      >
        <i className="fa fa-download" />  Descargar
      </button>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 45,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" iconSize={20} />
          <XAxis
            dataKey="label"
            angle={-90}
            textAnchor="end"
            height={80}
            padding={{ top: 20, left: 15, right: 15 }}
          />
          <YAxis width={30} />
          <Legend verticalAlign="top" height={36} iconSize={28}/>
          <Tooltip />
          <Area
            name="Usuarios totales acumulados"
            type="linear"
            dataKey="total_users"
            stackId="1"
            stroke="#7BBD40"
            fill="#7BBD40"
          >
            {labels && <LabelList dataKey="total_users" position="top" />}
          </Area>

          <Area
            name="Usuarios borrados por semana"
            type="linear"
            dataKey="deleted_users"
            stackId="2"
            stroke="#A7A7AA"
            fill="#A7A7AA"
          >
            {labels && <LabelList dataKey="deleted_users" position="bottom" />}
          </Area>
          <Area
            name="Usuarios nuevos por semana"
            type="linear"
            dataKey="new_users"
            stackId="3"
            stroke="#d94959"
            fill="#d94959"
          >
            {labels && <LabelList dataKey="new_users" position="top" />}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
      <h2>
        Total de: {total} productores al {format(new Date(fechaFinal), "dd MMM yyyy")}{" "}
      </h2>
    </BlockUi>
  );
};

export default GraficaProductoresTotal;
