import xlsx from "json-as-xlsx";
import moment from 'moment';
const VideosExportar = (content) => {
    //{ label: 'Age', value: row => (row.age + ' years') }, // Run functions
    //{ label: 'Phone', value: row => (row.more ? row.more.phone || '' : '') }, // Deep props

 
    let columns = [
        { label: 'ID Video', value: 'id_video' },
        { label: 'Título', value: 'titulo' },
        { label: 'Descripción', value: 'descripcion' },
        { label: 'Url', value: row => ('https://www.youtube.com/watch?v=' + row.url) }, // Run functions
        { label: 'Fecha registro', value: 'created_at' }
      ]
      
      let settings = {
        sheetName: 'Videos',
        fileName: 'Videos_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default VideosExportar;
