import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import PlaguicidasPreciosTabla from './PlaguicidasPreciosTabla';

const PlaguicidasRegistrar = () => {
	const [blocking, setBlocking] = useState(false);
	const { id_producto_comercial } = useParams();

	const [data] = useState({
		id_producto_comercial : id_producto_comercial,
		uuid: "",
		tipo: 0,
		nombre_producto_comercial: "",
		ingredientes_activos: "",
		formulacion : "",
		unidad_formulacion : 0,
		intervalo_seguridad : "",
		intervalo_seguridad_unidad : 0,
		intervalo_reentrada : "",
		intervalo_reentrada_unidad : 0
	});
	

	const schema = yup.object().shape({
		id_producto_comercial: yup.number().min(0),
		uuid: yup.string(),
		tipo: yup.number('Seleccione un tipo').min(1,'Seleccione un tipo').required('Seleccione un tipo'),
		nombre_producto_comercial: yup.string().required('Nombre es requerido'),
		ingredientes_activos: yup.string().required('Ingredientes activos es requerido'),
		formulacion: yup.string().required('Formulación es requerido'),
		unidad_formulacion: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		intervalo_seguridad: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		intervalo_seguridad_unidad: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		intervalo_reentrada: yup.number().transform(value => (isNaN(value) ? undefined : value)),
		intervalo_reentrada_unidad: yup.number().transform(value => (isNaN(value) ? undefined : value))
	});


	const { register, handleSubmit, errors,reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});



	const selectFinish = () => {
		console.log('OBTENER INFORMACIÓN DEL PLAGUICIDA');
		if (id_producto_comercial > 0) {obtenerPlaguicida();}
	};

	const obtenerPlaguicida = () => {
		setBlocking(true);
		setTimeout(function(){ 
			Api.getAll(ApiUrl.catalogs.plaguicidas.general + '/' + id_producto_comercial).then(response => {
				let plaguicidaData = response.data.data;
				plaguicidaData[0].tipo = plaguicidaData[0].tipo.id_tipo_producto;
				reset(plaguicidaData[0]);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		 }, 5000);
	}

	const creaPlaguicida = values => {
		setBlocking(true);
		Api.create(ApiUrl.catalogs.plaguicidas.general,values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				window.location.href = '/administracion/catalogos/plaguicidas/registrar/' + _result.data.id_producto_comercial;
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const actualizaPlaguicida = values => {
		setBlocking(true);
		Api.update(ApiUrl.catalogs.plaguicidas.general,values.id_producto_comercial, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location.reload(); 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const onSubmit = values => {
		if (id_producto_comercial > 0) {
			actualizaPlaguicida(values);
		} else {
			creaPlaguicida(values);
		}

	}

	useEffect(() => {
		if(id_producto_comercial === '0'){
			reset(data);
		}
		// eslint-disable-next-line
	},[id_producto_comercial]);



	return (
		<>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/catalogos/plaguicidas">Registrar plaguicida</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de plaguicidas <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del plaguicida</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

					<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
					<div className="col-sm-4">
						<FormSelectApi 
							id="tipo"
							name="tipo"
							label="Tipo de producto"
							url="catalogos/tipoproductos"
							keyArray="id_tipo_producto"
							labelArray="nombre_tipo_producto"
							error={errors.tipo}
							onFinish={() => {}}
							register={register}
						/>
					</div>

					<div className="col-sm-4">
						<FormInput 
							id="nombre_producto_comercial"
							type="text"
							name="nombre_producto_comercial"
							label="Nombre"
							error={errors.nombre_producto_comercial}
							register={register}
						/>
					</div>
					<div className="col-sm-4">
						<FormInput 
							id="ingredientes_activos"
							type="text"
							name="ingredientes_activos"
							label="Ingredientes activos"
							error={errors.ingredientes_activos}
							register={register}
						/>
					</div>

				</div>


				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput 
							id="formulacion"
							type="text"
							name="formulacion"
							label="Formulación"
							error={errors.formulacion}
							register={register}
						/>
					</div>
					<div className="col-sm-6">
					<FormSelectApi 
							id="unidad_formulacion"
							name="unidad_formulacion"
							label="Unidad Formulación"
							url="catalogos/formulacion"
							keyArray="id_unidad"
							labelArray="nombre_unidad"
							error={errors.unidad_formulacion}
							onFinish={() => {}}
							register={register}
						/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput 
							id="intervalo_seguridad"
							type="text"
							name="intervalo_seguridad"
							label="Intervalo de seguridad"
							error={errors.intervalo_seguridad}
							register={register}
						/>
					</div>
					<div className="col-sm-6">
					<FormSelectApi 
							id="intervalo_seguridad_unidad"
							name="intervalo_seguridad_unidad"
							label="Unidad intervalo de seguridad"
							url="catalogos/int"
							keyArray="id_unidad"
							labelArray="nombre_unidad"
							error={errors.intervalo_seguridad_unidad}
							onFinish={() => {}}
							register={register}
						/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormInput 
							id="intervalo_reentrada"
							type="text"
							name="intervalo_reentrada"
							label="Intervalo de reentrada"
							error={errors.intervalo_reentrada}
							onFinish={() => {}}
							register={register}
						/>
					</div>
					<div className="col-sm-6">
					<FormSelectApi 
							id="intervalo_reentrada_unidad"
							name="intervalo_reentrada_unidad"
							label="Unidad intervalo de reentrada"
							url="catalogos/int"
							keyArray="id_unidad"
							labelArray="nombre_unidad"
							error={errors.intervalo_reentrada_unidad}
							onFinish={selectFinish}
							register={register}
						/>
					</div>
				</div>

				<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="id_producto_comercial"
									type="hidden"
									name="id_producto_comercial"
									label="id_producto_comercial"
									error={errors.id_producto_comercial}
									register={register}
								/>
							</div>
						</div>


						<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="uuid"
									type="hidden"
									name="uuid"
									label="UUID"
									error={errors.uuid}
									register={register}
								/>
							</div>
						</div>

						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar plaguicida</button>
						</div>
					</div>
					</form>
					</BlockUi>

					</PanelBody>
				</Panel>
			</div>
			</div>

			{id_producto_comercial > 0 && (
			<div className="row">
				<div className="col-md-12">

					<Panel>
						<PanelHeader>Precios del sistema</PanelHeader>
						<PanelBody>
							<PlaguicidasPreciosTabla id_producto_comercial={id_producto_comercial} />
						</PanelBody>

					</Panel>
				</div>

			</div>
			)}
		</>
	);
};
export default PlaguicidasRegistrar;
