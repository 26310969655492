import { useState, useEffect } from 'react'
import Api from '../../api/global.service'

export const useFetchWithPaginate = (urlPath) => {
  const [blocking, setBlocking] = useState(false)
  const [datosFiltrados, setDatosFiltrados] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  useEffect(() => {
    obtenerDatosFiltrados()
  }, [page])

  const obtenerDatosFiltrados = (column = null, sortDirection = null) => {
    setBlocking(true)

    const url = `${urlPath}?page=${page}&limit=${perPage}`
    Api.getAll(url, { perPage: perPage, column: column?.selector, sortDirection: sortDirection })
      .then((response) => {
        setDatosFiltrados(response.data.data)
        setTotalRows(response.data.total)
        setBlocking(false)
      })
      .catch((e) => {
        setBlocking(false)
        console.log(e)
      })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setBlocking(true)
    const url = `${urlPath}?page=${page}&limit=${newPerPage}`
    Api.getAll(url, { perPage: newPerPage })
      .then((response) => {
        setDatosFiltrados(response.data.data)
        setPerPage(newPerPage)
        setBlocking(false)
      })
      .catch((e) => {
        setBlocking(false)
        console.log(e)
      })
  }

  return [
    blocking,
    setBlocking,
    datosFiltrados,
    obtenerDatosFiltrados,
    setPage,
    totalRows,
    perPage,
    handlePerRowsChange,
  ]
}
