import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import BlockUi from "react-block-ui";
import Api from "../../../api/global.service";
import conviertArray from "../../helpers/conviertArray";
import { mainRed } from "./Colores";
import exportarGrafica from "./exportarGrafica";

export const GraficaAreaGral = ({
  url,
  labelsKey = "label",
  dataKey = "total",
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
  percentage = false,
}) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState(false);

  useEffect(() => {
    let label = [];
    let data = [];
    Api.getAll(url, { fechaInicial: fechaInicial, fechaFinal: fechaFinal })
      .then((response) => {
        setBlocking(false);
        let _items = conviertArray(response.data.data);
        for (var i in _items) {
          label.push(_items[i][labelsKey]);
          data.push(_items[i][dataKey]);
        }
        setBlocking(false);
        setData(response.data.data);
      })
      .catch((e) => {
        setBlocking(false);
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <button
        onClick={() => setLabels(!labels)}
        className={` mx-2 btn ${labels ? "btn-secondary" : "btn-light"} `}
      >
        {labels ? "esconder valores" : "Ver valores"}
      </button>
      <button
        onClick={() => exportarGrafica(data,url,fechaInicial,fechaFinal,labelsKey,dataKey,percentage)}
        className={`btn`}
      >
        <i className="fa fa-download" />  Descargar
      </button>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="label"
            angle={-90}
            textAnchor="end"
            height={80}
            padding={{ top: 20, left: 15, right: 15 }}
          />
          <YAxis
            tickFormatter={(decimal, fixed = 0) =>
              percentage ? `${decimal}%` : decimal
            }
            domain={percentage ? [0, 100] : [0, "auto"]}
          />
          <Tooltip />
          <Area type="linear" dataKey="total" stroke={mainRed} fill={mainRed}>
            {labels && <LabelList dataKey={dataKey} position="top" />}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </BlockUi>
  );
};

export default GraficaAreaGral;
