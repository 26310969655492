import xlsx from "json-as-xlsx";
import moment from 'moment';
const RecomendacionesExportar = (content) => {

    const getTiempoRiego = (tiempo_riego_semana_minutos) => {
      let minutos = tiempo_riego_semana_minutos % 60;
      let horas = (tiempo_riego_semana_minutos - minutos) / 60;
      return (horas < 10 ? "0" : "") + horas.toString() + ":" + (minutos < 10 ? "0" : "") + parseInt(minutos).toString();
    }

    let columns = [
        { label: 'id_recomendacion_riego', value: 'id_recomendacion_riego' },
        { label: 'Ciclo', value: 'ciclo' },
        { label: 'id_productor', value: 'id_productor' },
        { label: 'Productor', value: row => (row.productor_nombres + ' ' + row.productor_apellido_paterno + ' ' + ((row.productor_apellido_materno === null) ? '' : row.productor_apellido_materno)) },
        { label: 'id_huerta', value: 'id_huerta' },
        { label: 'Huerta', value: 'nombre_huerta' },
        { label: 'Evento', value: 'evento' },
        { label: 'Etapa', value: 'nombre_etapa' },
        { label: 'KC', value: 'kc' },
        { label: 'ETO', value: 'eto' },
        { label: 'Tiempo de riego', value: row => getTiempoRiego(row.tiempo_riego_semana_minutos) },
        { label: 'Recomendacion', value: 'recomendacion' },
        { label: 'created_at', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'RecomendacionesRiego',
        fileName: 'RecomendacionesRiego_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default RecomendacionesExportar;
