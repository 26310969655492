import xlsx from "json-as-xlsx";
import moment from 'moment';
const LecturasTensiometrosExportar = (content) => {
    let columns = [
        { label: 'id_sector_lectura_tensiometro', value: 'id_sector_lectura_tensiometro' },
        { label: 'id_productor', value: 'id_productor' },
        { label: 'Productor', value: row => (row.productor_nombres + ' ' + row.productor_apellido_paterno + ' ' + ((row.productor_apellido_materno === null) ? '' : row.productor_apellido_materno)) },
        { label: 'id_huerta', value: 'id_huerta' },
        { label: 'nombre_huerta', value: 'nombre_huerta' },
        { label: 'superficie', value: 'superficie' },
        { label: 'evento', value: 'evento' },
        { label: 'fecha_lectura', value: 'fecha_lectura' },
        { label: 'exceso_humedad', value: 'exceso_humedad' },
        { label: 'max_suelo_seco', value: 'max_suelo_seco' },
        { label: 'valor', value: 'valor' },
        { label: 'id_tecnico', value: 'id_tecnico' },
        { label: 'Técnico', value: row => (row.tecnico_nombres + ' ' + row.tecnico_apellido_paterno + ' ' + ((row.tecnico_apellido_materno === null) ? '' : row.tecnico_apellido_materno)) },
        { label: 'created_at', value: 'created_at' }
      ]
      
      let settings = {
        sheetName: 'LecturasTensiometros',
        fileName: 'LecturasTensiometros_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default LecturasTensiometrosExportar;
