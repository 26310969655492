import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import { Link } from "react-router-dom";
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const CampaniasTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'ID',
			selector: 'id_campania',
			maxWidth: '100px',
			sortable: true,
			right: false
		},	
		{
			name: 'TÍTULO',
			selector: 'nombre_campania',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
   	{
			name: 'INICIO',
			selector: 'fecha_inicio',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
    {
			name: 'FIN',
			selector: 'fecha_final',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => (
          <>
						<Link 
              className="btn btn-xs btn-primary" 
              to={'campanias/estatus/' + row.id_campania}
			 			  title="Estatus de Campaña"
            >
              <i className="fa fa-chart-bar"></i>
            </Link> 
            <Link 
              className="btn btn-xs btn-warning mx-2" 
              to={'campanias/registrar/' + row.id_campania}
			  title="Editar"
            >
              <i className="fa fa-edit"></i>
            </Link> 
            <DeleteButton 
              onClick={() => eliminaCampania(row.id_campania)}
            >
              <i className="fa fa-trash"></i>
            </DeleteButton>
          </>
        ),
		},
	];

  const eliminaCampania = (id_campania) => {		
		console.log(id_campania);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "Se eliminarán también sus concursos y premios asignados",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.concursos.campanias, id_campania)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Campania eliminada.',
						  'success'
						);
					}
				obtenerCampanias();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar la campaña.',
					'error'
				  );
				  obtenerCampanias();
				});

			}
		  })
	};


	// const onCerrarModal = (id_campania)  => {
	// 	setVerDetalles(false);
	// 	setIdAviso(0);
	// }

	// const abrirDetalle = (id_campania)  => {
	// 	setVerDetalles(true);
	// 	setIdAviso(id_campania);
	// }

	useEffect(() => {
		obtenerCampanias();
	}, []);

  const obtenerCampanias = ()  => {
    setBlocking(true);	
    Api.getAll(ApiUrl.concursos.campanias).then(response => {
        setDatosFiltrados(response.data.data)
        setBlocking(false);	
      })
      .catch(e => {
        setBlocking(false);	
        console.log(e);
      });
  }


	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}


	return (
		<>
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar concursos" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/campanias/registrar/0" 
				columnasFiltrar={["id_campania","nombre_campania","fecha_inicio","fecha_final"]}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
            noDataComponent={'Sin datos por mostrar'}
            customStyles={estiloGeneral}
          />
		</BlockUi>
    </>
	);
  };
export default CampaniasTabla;
