import React, {
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import { publish } from "../.././../components/events";
import Api from "../../../api/global.service";
import ProductoresTabla from "./ProductoresTabla";

const ModalAgregaProductor = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [idCiclo, setIdCiclo] = useState(0);
  const [path, setPath] = useState("");
  const [productoresFaltantes, setProductoresFaltantes] = useState([]);
  const [errors, setErrors] = useState(null);


  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdCiclo: (id) => setIdCiclo(id),
    setProductoresFaltantes: (productoresFaltantes) =>
      setProductoresFaltantes(productoresFaltantes),
    setPath: (path) => setPath(path),
  }));

  const showHandler = () => {
    setShow(true);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const agregaProductor = (id_productor) => {
    let data = { id_ciclo: idCiclo, id_productor: id_productor };
    Api.create(path + "/agrega_productor/" + idCiclo, data)
      .then((response) => {
        setBlocking(false);
        setShow(false);
        publish("AgregaProductor::onSave", data);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const quitarProductor = (id_productor) => {
    let data = { id_ciclo: idCiclo, id_productor: id_productor };
    Api.create(path + "/quita_productor/" + idCiclo, data)
      .then((response) => {
        setBlocking(false);
        setShow(false);
        publish("QuitaProductor::onSave", data);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  return (
    <div
      className="modal"
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <div
        className="modal-dialog"
        style={{
          top: 50,
          right: 25,
          position: "absolute",
          width: 400,
          height: "calc(100% - 100px)",
        }}
      >
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header" style={{ flex: "inherit" }}>
            <h4 className="modal-title">{props.title}</h4>
            <button
              onClick={() => closeHandler()}
              type="button"
              className="btn"
              style={{ borderRadius: "20px 20px 20px 20px" }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <div className="col-sm-12">
                <ProductoresTabla
                  data={productoresFaltantes}
                  onQuita={(id_productor) => quitarProductor(id_productor)}
                  onAgrega={(id_productor) => agregaProductor(id_productor)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalAgregaProductor;
