import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import Api from '../../api/global.service'
import ApiUrl from '../../constants/Api'
import 'bootstrap/dist/css/bootstrap.css'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx'
import FormSelect from '../../nuup/componentes/Forms/FormSelect.jsx'
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx'
import FormCheckbox from '../../nuup/componentes/Forms/FormCheckbox.jsx'
import { Link, useParams } from 'react-router-dom'
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx'
import BlockUi from 'react-block-ui'
import Swal from 'sweetalert2'
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import changeKeyObjects from '../../nuup/helpers/changeKeyObjects';

const UsuariosRegistrar = () => {
  const [blocking, setBlocking] = useState(true)
  const { id_usuario } = useParams()
  const [rol, setRol] = useState(2);
  const [productores, setProductores] = useState([]);

  const [data] = useState({
    id: id_usuario,
    uuid: '',
    email: '',
    username: '',
    firstname: '',
    lastname1: '',
    lastname2: '',
    nombre_facturable: '',
    gender: '',
    birthdate: '',
    rol: 2,
    id_zona: 0,
    id_nivel: 0,
    recomendado_por: 0,
    id_cat_tipo_productor: 0,
    lab_of_labs: false,
    aliado_de_donde: '',
    productores_asociados : []
  })

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = yup.object().shape({
    id: yup.number().min(0),
    uuid: yup.string(),
    email: yup
      .string()
      .email('Correo elecrónico no válido')
      .required('Correo elecrónico no válido'),
    username: yup.string().matches(phoneRegExp, 'Registro el número telefónico').required(),
    firstname: yup.string().required('Nombre(s) requerido'),
    lastname1: yup.string().required('Apellido paterno requerido'),
    lastname2: yup.string(),
    nombre_facturable: yup.string(),
    gender: yup.string()
      .when("rol", {
        is: '2',
        then: yup.string().required("Seleccione género")
      }),
    birthdate: yup.string(),
    rol: yup.number('Seleccione un rol').min(1, 'Seleccione un rol').required('Seleccione un rol'),
    id_zona: yup
      .number('Seleccione una zona')
      .when("rol", {
        is: '2',
        then: yup.number().min(1, 'Seleccione una zona').required("Seleccione una zona")
      }),
    id_nivel: yup
      .number('Seleccione un nivel')
      .when("rol", {
        is: '2',
        then: yup.number().min(1, 'Seleccione genero').required("Seleccione genero")
      }),
    aliado_de_donde: yup.string(),
    password: yup.string().when('uuid', {
      is: (uuid) => (uuid === null || uuid === ''),
      then: yup.string().required('Contraseña requerida')
    }),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Los campos de contraseña no coinciden'),
  })

  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: data,
  })

  const onFinish = () => {
    setBlocking(false)
    obtenerProductores()
    if (id_usuario > 0) {
        obtenerUsuario()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }

  const obtenerUsuario = () => {
    setBlocking(true)
    Api.getAll(ApiUrl.usuarios.listado + '/' + id_usuario)
      .then((response) => {
        let usuarioData = response.data.data[0]
        usuarioData = { ...usuarioData, lab_of_labs: usuarioData.lab_of_labs === "1" }
        setRol(Number(usuarioData.rol));
        reset(usuarioData)
        setBlocking(false)
        if(Number(usuarioData.rol) === 3){
          setValue('productores_asociados',changeKeyObjects(usuarioData.productoresAsociados, { id: "id", full_name: "label" }));
        }
      })
      .catch((e) => {
        setBlocking(false)
        console.log(e)
      })
  }

  const obtenerProductores = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.bitacoras.soloproductores)
		  .then(response => {
			setProductores(response.data.data);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }

  const creaUsuario = (values) => {
    Api.create(ApiUrl.usuarios.general, values)
      .then((response) => {
        let _result = response.data
        if (_result.success === 1) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: _result.message,
          })
          window.location = '/administracion/usuarios'
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: _result.message,
          })
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: 'error',
          title: '',
          text: e,
        })
      })
  }

  const actualizaUsuario = (values) => {

    console.log('actualizaUsuario');
    console.log(values);



    setBlocking(true)
    Api.update(ApiUrl.usuarios.general, values.uuid, values)
      .then((response) => {
        let _result = response.data
        setBlocking(false)
        if (_result.success === 1) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: _result.message,
          })
          window.location = '/administracion/usuarios'
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: _result.message,
          })
        }
      })
      .catch((e) => {
        setBlocking(false)
        Swal.fire({
          icon: 'error',
          title: '',
          text: e,
        })
      })
      

  }

  const onChangeRol = (rol) => {
    setRol(Number(rol));
  }

  const onSubmit = (values) => {
    if (id_usuario > 0) {
      actualizaUsuario(values)
    } else {
      creaUsuario(values)
    }
  }



  useEffect(() => {
    if (id_usuario === '0') {
      reset(data)
    }
    // eslint-disable-next-line
  }, [id_usuario])

  
  useEffect(() => {

    // eslint-disable-next-line
  }, [productores])

  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/administracion/usuarios">Administración de usuarios</Link>
          </li>
          <li className="breadcrumb-item active">Registrar/Actualizar usuarios</li>
        </ol>
        <h1 className="page-header">
          Administración de usuarios <small></small>
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelHeader>Datos del usuario</PanelHeader>
              <PanelBody>
                <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">

                      <div className="col-md-4">
                        <FormSelectApi
                          id="rol"
                          name="rol"
                          label="Rol"
                          url="roles"
                          keyArray="id"
                          labelArray="name"
                          error={errors.rol}
                          onFinish={() => { }}
                          register={register}
                          onChange={(value) => onChangeRol(value)}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormInput
                          id="email"
                          type="text"
                          name="email"
                          label="Correo electrónico"
                          error={errors.email}
                          register={register}
                        />
                      </div>

                      <div className="col-md-4">
                        <FormInput
                          id="username"
                          type="text"
                          name="username"
                          label="Teléfono (usuario de la app)"
                          error={errors.username}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-3">
                        <FormInput
                          id="firstname"
                          type="text"
                          name="firstname"
                          label="Nombres"
                          error={errors.firstname}
                          register={register}
                        />
                      </div>

                      <div className="col-sm-3">
                        <FormInput
                          id="lastname1"
                          type="text"
                          name="lastname1"
                          label="Apellido paterno"
                          error={errors.lastname1}
                          register={register}
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormInput
                          id="lastname2"
                          type="text"
                          name="lastname2"
                          label="Apellido materno"
                          error={errors.lastname2}
                          register={register}
                        />
                      </div>
                      <div className="col-sm-3">
                        <FormInput
                          id="nombre_facturable"
                          type="text"
                          name="nombre_facturable"
                          label="Nombre facturable"
                          error={errors.nombre_facturable}
                          register={register}
                        />
                      </div>
                    </div>

                    {rol === 2 && (
                      <>
                        <div className="form-group row">
                          <div className="col-md-6">
                            <FormInput
                              id="birthdate"
                              type="date"
                              name="birthdate"
                              label="Fecha de Nacimiento"
                              error={errors.birthdate}
                              register={register}
                            />
                          </div>
                          <div className="col-md-6">
                            <FormSelect
                              id="gender"
                              name="gender"
                              label="Género"
                              error={errors.gender}
                              register={register}
                            />
                          </div>
                        </div>


                        <div className="form-group row">
                          <div className="col-md-12">
                            <FormInput
                              id="address"
                              type="text"
                              name="address"
                              label="Dirección"
                              error={errors.address}
                              register={register}
                            />
                          </div>
                        </div>

                        <div className="form-group row">


                          <div className="col-md-4">
                            <FormSelectApi
                              id="id_nivel"
                              name="id_nivel"
                              label="Nivel"
                              url="niveles"
                              keyArray="id_nivel"
                              labelArray="nombre_nivel"
                              error={errors.id_nivel}
                              onFinish={() => { }}
                              register={register}
                              onChange={() => { }}
                            />
                          </div>

                          <div className="col-md-4">
                            <FormSelectApi
                              id="id_zona"
                              name="id_zona"
                              label="Zona"
                              url="zonas"
                              keyArray="id_zona"
                              labelArray="nombre_zona"
                              error={errors.zona}
                              onFinish={() => onFinish()}
                              register={register}
                              onChange={() => { }}
                            />
                          </div>
                        </div>


                        <div className="form-group row">
                          <div className="col-md-6">
                            <FormSelectApi
                              id="id_cat_tipo_productor"
                              name="id_cat_tipo_productor"
                              label="Tipo de Productor"
                              url="tipo_productor"
                              keyArray="id_cat_tipo_productor"
                              labelArray="nombre"
                              error={errors.zona}
                              onFinish={() => onFinish()}
                              register={register}
                              onChange={() => { }}
                            />
                          </div>
                          <div className="col-md-6">
                            <FormCheckbox
                              id="lab_of_labs"
                              name="lab_of_labs"
                              label="Lab of labs"
                              error={errors.lab_of_labs}
                              register={register}
                            />
                          </div>

                        </div>

                        <div className="form-group row">
                          <div className="col-md-4">
                            <FormSelectApi
                              id="recomendado_por"
                              name="recomendado_por"
                              label="Usuario que Recomienda"
                              url={ApiUrl.usuarios.listado + '/0'}
                              keyArray="id"
                              labelArray="firstname"
                              labelArray2="lastname1"
                              error={errors.recomendado_por}
                              onFinish={() => { }}
                              register={register}
                              onChange={() => { }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {rol === 4 && (
                      <div className="form-group row">
                        <div className="col-md-12">
                          <FormInput
                            id="aliado_de_donde"
                            type="aliado_de_donde"
                            name="aliado_de_donde"
                            label="Organización"
                            error={errors.aliado_de_donde}
                            register={register}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group row">
                      <div className="col-md-6">
                        <FormInput
                          id="password"
                          type="password"
                          name="password"
                          label="Contraseña"
                          error={errors.password}
                          register={register}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormInput
                          id="passwordConfirmation"
                          type="password"
                          name="passwordConfirmation"
                          label="Confirmar contraseña"
                          error={errors.passwordConfirmation}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <FormInput
                          id="uuid"
                          type="hidden"
                          name="uuid"
                          label="UUID"
                          error={errors.uuid}
                          register={register}
                        />
                      </div>
                    </div>
                    
                    {rol === 3 && (
                    <div>
                      <h5>Asociar productores</h5>
                    <Controller
                      name="productores_asociados"
                      control={control}
                      render={({ onChange, ref, value }) => (
                        <MultiSelect
                        items={changeKeyObjects(productores, { id: "id", productor: "label" })}
                        onChange={onChange}
                          ref={ref}
                          showSearch
                          showSelectAll
                          showSelectedItems
                          selectedItems={value}
                          messages={{
                            searchPlaceholder: "Buscar...",
                            noItemsMessage: "Sin elementos...",
                            noneSelectedMessage: "Ninguno seleccionado",
                            selectedMessage: "seleccionados",
                            selectAllMessage: "Seleccionar todos",
                            clearAllMessage: "Quitar todos",
                          }}
                        />
                      )} />
                    </div>
                    )}

                    <div className="row">
                      <div className="col-md-3 offset-md-9">
                        <button className="btn btn-primary btn-block" type="submit">
                          Guardar usuario
                        </button>
                      </div>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  )
}
export default UsuariosRegistrar
