import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useAuth } from "./../../../components/content/auth.jsx";
import { useHistory} from "react-router-dom";

class DropdownProfile extends React.Component {
	
	constructor(props) {
		super(props);

		if (props.auth.user.firstname === undefined) {
			this.props.history.push("login");
		}
		//checking if user is logged in,
		//if is logged in we check total of profiles the user has
		if(localStorage.grupos !== undefined){
			this.grupos = JSON.parse(localStorage.grupos);
		}else{
			this.grupos = [];
		}
		
		this.toggle = this.toggle.bind(this);
		this.logout = this.logout.bind(this);
		this.state = {
			dropdownOpen: false,
			grupos: this.grupos,
		};
		
	}

	logout(){
		this.props.auth.logout().then(response => {
			this.props.history.push("/login")
		  }).catch(errors => {
	  
		  });
	}


	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
  
	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle tag="a">
					<div className="image image-icon bg-black text-grey-darker">
						<i className="fa fa-user"></i>
					</div>
					<span className="d-none d-md-inline">{this.props.auth.user.firstname} {this.props.auth.user.lastname1} {this.props.auth.user.lastname2}</span> <b className="caret"></b>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-sright" tag="ul">
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={this.logout}>Cerrar sesión</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default (props) => { 
	const auth = useAuth();
	const history = useHistory();
	return <DropdownProfile auth={auth} history={history} {...props}/> ;
  };
