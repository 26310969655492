import React, { useEffect, useState } from "react";
import Api from "../../../api/global.service";
import ApiUrl from "../../../constants/Api";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import DeleteButton from "../../../nuup/componentes/Generales/DeleteButton";
import estiloGeneral from "../../../nuup/componentes/Tablas/estiloGeneral";
import "../../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";

var dataArray = [];
const SyncCatalogosTabla = (props) => {
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const columns = [
    {
      name: "ID",
      selector: "id_schedule",
      maxWidth: "100px",
      sortable: true,
      right: false,
    },

    {
      name: "NOMBRE",
      selector: "nombre",
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "CREADO",
      selector: "created_at",
      sortable: true,
      maxWidth: "300px",
      right: false,
    },
    {
      name: "OPCIONES",
      button: true,
      cell: (row) => (
        <>
          <DeleteButton onClick={() => eliminaSync(row.id_schedule)}>
            <i className="fa fa-trash"></i>
          </DeleteButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    obtenerSincCatalogos();
  }, []);

  const obtenerSincCatalogos = () => {
    setBlocking(true);
    Api.getAll(ApiUrl.catalogs.sinc.general)
      .then((response) => {
				console.log(response.data.data);
        const dataArray = response.data.data;
        setBlocking(false);
        setDatosFiltrados(dataArray);
        // console.log(response.data.data);
      })
      .catch((e) => {
        setBlocking(false);
      });
  };

  const eliminaSync = (id_schedule) => {
    Swal.fire({
      title: "¿Esta seguro?",
      text: "El registro se eliminará",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBlocking(true);
        Api.delete(ApiUrl.catalogs.sinc.general, id_schedule)
          .then((response) => {
            let _result = response.data;
            if (_result.success === 1) {
              setBlocking(false);
              Swal.fire("Eliminado!", "Sincronización eliminada.", "success");
            }
            obtenerSincCatalogos();
          })
          .catch((e) => {
            setBlocking(false);
            Swal.fire("Error!", "Error al eliminar el sincronización.", "error");
            obtenerSincCatalogos();
          });
      }
    });
  };
  const handleChange = (datosFiltrados) => {
    setDatosFiltrados(datosFiltrados);
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <HerramientasTablas
        txtBusqueda="Buscar plaguicida"
        datosTabla={dataArray}
        urlRegistrar="/administracion/catalogos/sinc-catalogos/registrar/0"
        columnasFiltrar={["id_schedule", "nombre", "created_at"]}
        exportar={() => {}}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
      />
      <DataTable
        noHeader
        pagination
        columns={columns}
        data={datosFiltrados}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
      />
    </BlockUi>
  );
};
export default SyncCatalogosTabla;
