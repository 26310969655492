import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectData from '../../../nuup/componentes/Forms/FormSelectData';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import FormSelectApiNested from '../../../nuup/componentes/Forms/FormSelectApiNested.jsx';

import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import RecomendacionRiegoHtml from './RecomendacionRiegoHtml';

const RecomendacionesRegistrar = (props) => {
	const [blocking, setBlocking] = useState(false);
	const { id_recomendacion_riego, id_productor, id_ciclo, huertas, etapas, lecturasEtos, diagnosticos } = props;

	const [idProductor, setIdProductor] = useState(id_productor);
	const [infoRefistro, setInfoRefistro] = useState({});
	const [idHuerta, setIdHuerta] = useState(0);
	const [idEtapa, setIdEtapa] = useState(0);
	const [idRecomendacionRiegoEto, setIdRecomendacionRiegoEto] = useState(0);
	const [diagnostico, setDiagnostico] = useState({});
	const [recomendacion, setRecomendacion] = useState({});

	const [dataRecomendacion, setDataRecomendacion] = useState({
		fecha : '',
		fecha_fin : '',
		et0 : '',
		nombre_productor : props.nombre_productor,
		horas_riego : '',
		nombre_zona : '',
		nombre_etapa : '',
	});

	/* ESTO VIENE DEL DIAGNÓSTICO
	 mm/hora =((L1/(L2*L3))*L4)/O1  ---- 11,7
	 mm_hora =	((CAUDAL_RPOMEDIO / (SEPARACION_CAMAS * SEPARACION_EMISORES )) * CINTAS_CAMA)/ FACTOR_CORRECION  ---- 11,7
	*/

	/*
	  etc_semana = ETO * KC
	*/

	/*
	  m3_ha = etc_semana * 10
	*/

	/* 
		tiempo_riego_semana =TEXTO(((G6/$I$2)/$O$2)/24;"h:mm")
		tiempo_riego_semana = (((etc_semana / mm_hora) / porcentaje_uniformidad) / 24)  --- minutos?
	*/

	const [data] = useState({
		id_recomendacion_riego: id_recomendacion_riego,
		id_recomendacion_riego_eto: "",
		id_ciclo: id_ciclo,
		id_productor: id_productor,
		id_huerta: "",
		evento: "",
		id_etapa: "",
		recomendacion: ""
	});

	const schema = yup.object().shape({
		id_recomendacion_riego: yup.number().min(0),
		id_recomendacion_riego_eto: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_ciclo: yup.number().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_productor: yup.number().integer().min(1, 'Requerido').required('Requerido'),
		id_huerta: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		id_sector: yup.number().integer().transform(value => (isNaN(value) ? undefined : value)),
		evento: yup.string(),
		id_etapa: yup.number().integer().min(1, 'Requerido').transform(value => (isNaN(value) ? undefined : value)).required('Requerido'),
		recomendacion: yup.string(),
	});


	const { register, handleSubmit, errors, reset, setValue } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});

	const selectFinish = () => {
		if (id_recomendacion_riego > 0) { obtenerRegistro(); }
	};

	const obtenerRegistro = () => {
		setBlocking(true);

		Api.getAll(ApiUrl.riegos.recomendaciones.general + '/' + id_recomendacion_riego + '/show').then(response => {
			let lecturaData = response.data.data;
			setIdRecomendacionRiegoEto(parseInt(lecturaData[0].id_recomendacion_riego_eto));
			setIdProductor(lecturaData[0].id_productor);
			setIdHuerta(lecturaData[0].id_huerta);
			setIdEtapa(lecturaData[0].id_etapa);
			setInfoRefistro(lecturaData[0]);

			setTimeout(() => {
				reset(lecturaData[0]);
			}, 7000);

			setBlocking(false);
		}).catch(e => {
			setBlocking(false);
			console.log(e);
		});
	}

	const creaRegistro = values => {
		let nuevosDatos = {
			values: values,
			recomendacion: recomendacion
		};
		setBlocking(true);
		Api.create(ApiUrl.riegos.recomendaciones.general, nuevosDatos).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire(
					'Registrado!',
					'Registro creado exitosamente.',
					'success'
				);
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}

	const actualizaRegistro = values => {
		setBlocking(true);
		Api.update(ApiUrl.riegos.recomendaciones.general, id_recomendacion_riego, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if (_result.success === 1) {
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				})
				onComplete(_result);
			} else {
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				})
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			})
		});
	}


	const onSubmit = values => {
		if (id_recomendacion_riego > 0) {
			actualizaRegistro(values);
		} else {
			creaRegistro(values);
		}
	}

	useEffect(() => {
		if (id_recomendacion_riego === '0') {
			reset(data);
		} else {
			obtenerRegistro();
		}
		// eslint-disable-next-line
	}, [id_recomendacion_riego]);

	const onComplete = values => {
		props.onComplete(values);
	};


	const getDiagnostico = () => {
		let diagnostico = diagnosticos.filter(diagnostico => diagnostico.id_huerta == idHuerta && diagnostico.id_ciclo == id_ciclo && diagnostico.id_productor == idProductor);
		if (typeof diagnostico[0].id_productor !== 'undefined') {
			setDiagnostico(diagnostico[0]);
		}
	}


	useEffect(() => {
		if (idProductor > 0 && id_ciclo > 0 && idHuerta > 0) { getDiagnostico(); }
	}, [idProductor, id_ciclo, idHuerta]);


	useEffect(() => {
		if (idEtapa > 0) {
			// buscar el KC de la etapa
			let etapa = etapas.filter(etapa => etapa.id_etapa == idEtapa);
			let eto = lecturasEtos.filter(lecturasEto => lecturasEto.id_recomendacion_riego_eto == idRecomendacionRiegoEto);
			if (typeof diagnostico.id_sector_diagnostico_riego !== 'undefined') {
				let mm_hora = ((diagnostico.gasto_prom / (diagnostico.camas_distancha * diagnostico.distancia_goteros)) * diagnostico.no_cintillas_cama) / diagnostico.factor_correccion_area_efectiva;
				let etc_semana = eto[0].eto * etapa[0].kc;
				let m3_ha = etc_semana * 10;
				let tiempo_riego_semana = (((etc_semana / mm_hora) / (diagnostico.porcentaje_uniformidad / 100)) / 24);
				let tiempo_riego_semana_minutos = tiempo_riego_semana * 24 * 60;
				let minutos = tiempo_riego_semana_minutos % 60;
				let horas = (tiempo_riego_semana_minutos - minutos) / 60;
				let tiempo_riego_semana_formato = (horas < 10 ? "0" : "") + horas.toString() + ":" + (minutos < 10 ? "0" : "") + parseInt(minutos).toString();
	
				setRecomendacion({
					mm_hora: mm_hora.toFixed(2),
					tiempo_riego_semana_formato: tiempo_riego_semana_formato,
					eto: eto[0].eto,
					kc: etapa[0].kc,
					etc_semana: etc_semana.toFixed(2),
					m3_ha: m3_ha.toFixed(2)
				});


				setDataRecomendacion({
					...dataRecomendacion,
					fecha : eto[0].fecha,
					fecha_fin : eto[0].fecha_fin,
					et0 : eto[0].eto,
					horas_riego : tiempo_riego_semana_formato,
					nombre_zona : eto[0].nombre_estacion,
					nombre_etapa : etapa[0].nombre_etapa,
				});
			

			}
		}
	}, [idEtapa, diagnostico]);

	const setEtapa = (value) => {
		setIdEtapa(value);
	}

	return (
		<BlockUi message="Cargando..." blocking={blocking} renderChildren={true}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group row">
					<FormInput
						id="id_productor"
						type="hidden"
						name="id_productor"
						label="Productor"
						error={errors.id_productor}
						register={register}
					/>

					<FormInput
						id="id_ciclo"
						type="hidden"
						name="id_ciclo"
						label="Productor"
						error={errors.id_ciclo}
						register={register}
					/>

					<div className="col-sm-6">

						<FormSelectData
							id="id_huerta"
							name="id_huerta"
							label="Huerta"
							keyArray="id_huerta"
							labelArray="nombre_huerta"
							items={huertas}
							error={errors.id_huerta}
							register={register}
							onChange={(value) => setIdHuerta(value)}
						/>

					</div>

					<div className="col-sm-6">
						<FormSelectApiNested
							id="id_sector"
							name="id_sector"
							label="Sector"
							url={ApiUrl.riegos.sectoresproductor}
							search={{ key: 'id_huerta', value: idHuerta }}
							valueSearch={idHuerta}
							keyArray="id_sector"
							labelArray="datos_sector"
							error={errors.id_sector}
							register={register}
							onFinish={() => { }}
							onChange={(value) => { }}
						/>

					</div>

				</div>

				{diagnostico.id_sector_diagnostico_riego && (
					<div className="form-group row">
						<div className="col-sm-12">
							<p>Diagnóstico de riego inical de la huerta</p>
							<table className='table table-bordered' style={{ fontSize: 10 }}>
								<thead>
									<tr>
										<th>Caudal promedio</th>
										<th>Separación entre camas</th>
										<th>Separación entre emisores</th>
										<th>Número cintas/cama</th>
										<th>Factor corrección área</th>
										<th>Uniformidad riego</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{diagnostico.gasto_prom}</td>
										<td>{diagnostico.camas_distancha}</td>
										<td>{diagnostico.distancia_goteros}</td>
										<td>{diagnostico.no_cintillas_cama}</td>
										<td>{diagnostico?.factor_correccion_area_efectiva ? (diagnostico.factor_correccion_area_efectiva * 100) : null}</td>
										<td>{diagnostico.porcentaje_uniformidad}</td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>
			   )}

				<div className="form-group row">
					<div className="col-sm-12">
						<FormSelectData
							id="id_recomendacion_riego_eto"
							label="Lectura ETO"
							keyArray="id_recomendacion_riego_eto"
							labelArray="eto_info"
							error={errors.id_recomendacion_riego_eto}
							items={lecturasEtos}
							register={register}
							onChange={(value) => setIdRecomendacionRiegoEto(value)}
						/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-6">
						<FormSelectData
							id="id_etapa"
							label="Etapa"
							keyArray="id_etapa"
							labelArray="nombre_etapa"
							error={errors.id_etapa}
							items={etapas}
							onFinish={() => { }}
							register={register}
							onChange={(value) => setEtapa(value)}
						/>
					</div>
					<div className="col-sm-6">
						<FormInput
							id="evento"
							type="text"
							name="evento"
							label="Evento"
							error={errors.evento}
							register={register}
						/>
					</div>

				</div>

				{recomendacion.eto && (
					<>
					<div className="form-group row">
						<div className="col-sm-12">
							<p>Datos calculados</p>
							<table className='table table-bordered' style={{ fontSize: 10 }}>
								<thead>
									<tr>
										<th>mm/hora</th>
										<th>Kc´</th>
										<th>Eto (mm)</th>
										<th>ETc/semana (mm)</th>
										<th>m3/ha</th>
										<th>Tiempo riego por semana (horas)</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{recomendacion.mm_hora}</td>
										<td>{recomendacion.kc}</td>
										<td>{recomendacion.eto}</td>
										<td>{recomendacion.etc_semana}</td>
										<td>{recomendacion.m3_ha}</td>
										<td>{recomendacion.tiempo_riego_semana_formato}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
						<RecomendacionRiegoHtml 
							fecha={dataRecomendacion.fecha}
							fecha_fin={dataRecomendacion.fecha_fin}
							et0={dataRecomendacion.et0}
							nombre_productor={dataRecomendacion.nombre_productor}
							horas_riego={dataRecomendacion.horas_riego} 
							nombre_zona={dataRecomendacion.nombre_zona}
							nombre_etapa={dataRecomendacion.nombre_etapa}
						/>
					</>
			   )}


				<div className="form-group row">
					<div className="col-sm-12">
						<FormInput
							id="recomendacion"
							type="text"
							name="recomendacion"
							label="Recomendación"
							error={errors.recomendacion}
							register={register}
						/>
					</div>
				</div>


				<div className="row">
					<div className="col-md-6 offset-md-3">
						<button className="btn btn-primary btn-block" type="submit">Guardar información</button>
					</div>
				</div>
			</form>
		</BlockUi>
	);
};
export default RecomendacionesRegistrar;
