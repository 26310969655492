import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import "react-data-table-component-extensions/dist/index.css";
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const VersionesHistorialTabla = (props) => {
	const [blocking, setBlocking] = useState(true);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'VERSION',
			selector: 'version',
			sortable: true,
			right: false
		},
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			right: false
		}
	];


	useEffect(() => {
		obtenerVersiones();
	// eslint-disable-next-line react-hooks/exhaustive-deps	 		
	}, []);

	  const obtenerVersiones = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.versiones + '/' + props.id_user)
		  .then(response => {
			  dataArray = response.data.data; 
			  setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<DataTable
				noHeader
				pagination
				defaultSortAsc={false}
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				highlightOnHover
			/>
		</BlockUi>
    );
  };
export default VersionesHistorialTabla;
