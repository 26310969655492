import React, { useEffect, useState } from "react";
import Api from "../../api/global.service";
import ApiUrl from "../../constants/Api";
import DataTable from "react-data-table-component";
import estiloGeneral from "../../nuup/componentes/Tablas/estiloGeneral";
import "../../nuup/styles/Tablas.css";
import BlockUi from "react-block-ui";
import HerramientasTablas from "../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas";

const RiegosListadoProductores = (props) => {
  const [blocking, setBlocking] = useState(true);
  const [columns, setColumns] = useState([
    {
      name: "Productor",
      selector: "full_name",
      sortable: true,
      right: false,
    },
  ]);
  const [dataArray, setDataArray] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const productorHandler = (row) => {
    props.onSelectItem(row);
  };

  useEffect(() => {
    if (props.productores.length) {
      setBlocking(false);
      setDataArray(props.productores);
      setDataFiltered(props.productores);
    }
  }, [props.productores]);

  const handleChange = (datosFiltrados) => {
    setDataFiltered(datosFiltrados);
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <HerramientasTablas
        txtBusqueda="Buscar productor..."
        datosTabla={dataArray}
        urlRegistrar=""
        columnasFiltrar={["full_name"]}
        //exportar={UsuariosExportar}
        onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
      />

      <DataTable
        noHeader
        pagination
        columns={columns}
        data={dataFiltered}
        Clicked
        noDataComponent={"Sin datos por mostrar"}
        customStyles={estiloGeneral}
        onRowClicked={productorHandler}
      />
    </BlockUi>
  );
};
export default RiegosListadoProductores;
