import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from "recharts";
import BlockUi from "react-block-ui";
import Api from "../../../api/global.service";
import { getDateRange } from "./DataHelpers";
import { getWeek } from "date-fns";

export const AreaProdPorSemana = ({
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
}) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState(false);

  // insert data of fetched created and deleted users per week in dateRange array
  const insertData = (users, dateRange) => {
    let weekUsers = 0;
    let temp_creados = [];
    const new_data = dateRange.map((item) => {
      temp_creados = users.find((d) => item.yearweek === d.yearweek);
      if (temp_creados) {
        weekUsers = parseInt(temp_creados.total);
      } else {
        weekUsers = 0;
      }
      return {
        ...item,
        total_users: weekUsers,
        semana: getWeek(new Date(item.created_at)),
      };
    });
    return new_data;
  };

  useEffect(() => {
    const dateRange = getDateRange(fechaInicial, fechaFinal);

    Api.getAll("graficas/productores_registrando_por_semana", {
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
    })
      .then((response) => {
        setData(insertData(response.data.data, dateRange));
        setBlocking(false);
      })
      .catch((e) => {
        setBlocking(false);
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal]);

  return (
    <BlockUi tag="div" blocking={blocking}>
      <h2>Número de Productores que registraron bitácoras cada semana</h2>
      <div className="col-md-12">
          <button
            onClick={() => setLabels(!labels)}
            className={`btn ${labels ? "btn-secondary" : "btn-light"} `}
          >
            {labels ? "esconder valores" : "Ver valores"}
          </button>
        </div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart width={500} height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" iconSize={20} />
          <XAxis
            dataKey="label"
            angle={-90}
            textAnchor="end"
            height={80}
            padding={{ top: 20, left: 15, right: 15 }}
          />
          <YAxis />
          <Legend verticalAlign="top" height={36} />
          <Tooltip />
          <Area
            name="Número de Productores"
            type="linear"
            dataKey="total_users"
            stackId="1"
            stroke="#7BBD40"
            fill="#7BBD40"
          >
            {labels && <LabelList dataKey="total_users" position="top" />}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
      <hr />
      <br />
    </BlockUi>
  );
};

export default AreaProdPorSemana;
