import React,{useState,useEffect} from 'react';

const FormSelectData = (props) => {


    return <>
            <div>
                <label htmlFor={props.id}>{props.label}</label>
                <select 
                    ref={props.register}
                    id={props.id}
                    name={props.id}
                    className={`form-control selectpicker ${props.error ? "is-invalid" : ""}`}
                    onChange={(e) => props.onChange(e.target.value)}
                    >
                    <option key={0} value={''}>--- SELECCIONE ---</option>
                    {props.items.map((item,index) =>
                        <option key={item[props.keyArray]} value={item[props.keyArray]}>
                            {item[props.labelArray]}{props.labelArray2? " " + item[props.labelArray2] : ""}
                        </option>
                    )}
                </select>
                    {props.error && <div className="invalid-feedback">{props.error.message}</div>}
            </div>
    </>
};
export default FormSelectData;