import http from "./http-common";
import httpMulti from "./http-common-multi";

class Api {
  getAll(path,params = {}) {
    return http.get(`/${path}`,{params : params});
  }

  get(path,uuid) {
    return http.get(`/${path}/${uuid}`);
  }

  create(path,data) {
    return http.post(`/${path}`, data);
  }

  createMultiPart(path,data) {
    return httpMulti.post(`/${path}`, data);
  }

  update(path,uuid, data) {
    return http.put(`/${path}/${uuid}`, data);
  }

  customUpdate(path) {
    return http.put(`/${path}`);
  }

  delete(path,uuid) {
    return http.delete(`/${path}/${uuid}`);
  }

}

export default new Api();