import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import RiegosListadoEstaciones from './RiegosListadoEstaciones';
import LecturasEtosTabla from './etos/LecturasEtosTabla.jsx';
import LecturasEtosRegistrar from './etos/LecturasEtosRegistrar.jsx';
import Modal from 'react-bootstrap/Modal';


const RiegosEtos = (props) => {

  const [idZona, setIdZona] = useState(0);
  const [nombreZona, setNombreZona] = useState('');

  const [idRecomendacionRiegoEto, setIdRecomendacionRiegoEto] = useState(0);
  const [reload, setReload] = useState(0);
  const [modal, setModal] = useState(false);

  const setDataEstacion = row => {
    setIdZona(row.id_zona);
    setNombreZona(row.nombre_zona);
  }

  const onComplete = () => {
    setReload(reload + 1);
    setModal(false);
  };

  const onUpdateClick = (id_recomendacion_riego_eto) => {
    setIdRecomendacionRiegoEto(id_recomendacion_riego_eto);
    setModal(true);
  };



  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Módulo de riegos</Link></li>
        <li className="breadcrumb-item active"></li>
      </ol>
      <h1 className="page-header">Módulo de riegos<small></small></h1>

      <div className="row">
        <div className="col col-md-2">
          <Panel>
            <PanelHeader><h3>{'Estaciones'}</h3></PanelHeader>
            <PanelBody>
              <RiegosListadoEstaciones onSelectItem={(item) => setDataEstacion(item)} />
            </PanelBody>
          </Panel>
        </div>

        <div className="col col-md-10">
          <Panel>
            <PanelHeader><h3>{nombreZona}</h3></PanelHeader>
            <PanelBody>
              {idZona > 0
                ? <>
                  <div className="row">
                    <div className="col col-md-2 offset-md-10">
                      <a className='btn btn-info' onClick={() => setModal(!modal)}>Registrar lectura</a>
                    </div>
                  </div>
                    <LecturasEtosTabla onUpdateClick={(value) => onUpdateClick(value)} reload={reload} id_zona={idZona} />
                  </>
                : <center><h3>Seleccione una estación para continuar</h3></center>
              }
            </PanelBody>
          </Panel>

          <Modal
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Registar / Actualizar
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LecturasEtosRegistrar
                id_recomendacion_riego_eto={idRecomendacionRiegoEto}
                id_zona={idZona}
                onComplete={(result) => onComplete(result)}
              />
            </Modal.Body>
          </Modal>

        </div>
      </div>
    </div>
  );
};
export default RiegosEtos;
