import React from 'react';
import "bootstrap/dist/css/bootstrap.css"
import ListGroup from 'react-bootstrap/ListGroup'

const AvisoRegistrarModalConf = ({values}) => {
  let destTitle = "Por Nivel"
  let destinatarios = values.id_nivel
  
  if (values.id_user.length > 0) {
    destTitle = "Por Productor"
    destinatarios = values.id_user
  }else if (values.id_user_inactivo.length > 0) {
    destTitle = "Por Productor Inactivo"
    destinatarios = values.id_user_inactivo
  } else if (values.id_aliado.length > 0) {
    destTitle = "Por Aliado"
    destinatarios = values.id_aliado
  }


  return (
    <ListGroup >
      <ListGroup.Item>({values.id_avisos_categoria === "1" ? "Clima" : "Información Técnica"}) <strong>{values.titulo}</strong></ListGroup.Item>
      <ListGroup.Item>
        <strong>{destTitle}</strong>
        <ListGroup variant="flush">
          {destinatarios.map((it) => {
            return (
              <ListGroup.Item key={it.id}>{it.label}</ListGroup.Item>
            )
          })}
        </ListGroup>
      </ListGroup.Item>
      <ListGroup.Item>
        <strong>Imagenes</strong>
        <ListGroup variant="flush">
          { Array.from(values.file).map((it, ind) => {
            return (
              <ListGroup.Item key={ind}>{it.name}</ListGroup.Item>
            )
          })}
        </ListGroup>
      </ListGroup.Item>
      <ListGroup.Item><div dangerouslySetInnerHTML={{ __html: values.aviso }}></div></ListGroup.Item>
    </ListGroup>
  )
}

export default AvisoRegistrarModalConf;