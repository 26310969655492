import React from 'react';
import Modal from 'react-bootstrap/Modal';
import AvisosDetalleVistosTabla from './AvisosDetalleVistosTabla';
const ModalDetalleVisto = (props) => {

    const hideFormEdit = () =>{
        props.onCerrarModal();
    }

    return <>
            <Modal
                show={props.show}
                onHide={() => hideFormEdit()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
            <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {'Avisos vistos'}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AvisosDetalleVistosTabla id_aviso={props.aviso} />
            </Modal.Body>
        </Modal>
    </>
};
export default ModalDetalleVisto;