import React from 'react';

const FormInput = ({register,error,label,id,...inputProps}) => {
    return <>
    	<div className="form-group">
            {inputProps.type !== 'hidden' && (
                <label htmlFor={id}>{label}</label>
            )}
            <input
                ref={register}
                id={id}
                className={`form-control ${error ? "is-invalid" : ""}`}
                {...inputProps}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormInput;