import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import Button from 'react-bootstrap/Button';

var dataArray = [];
const RiegosDiagnosticosTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'ID',
			selector: 'id_sector_diagnostico_riego',
			sortable: true,
			right: false
		},
		{
			name: 'Productor',
			selector: 'productor_nombres',
			sortable: true,
			right: false
		},
		{
			name: 'Huerta',
			selector: 'nombre_huerta',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Superficie',
			selector: 'superficie',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Registro',
			selector: 'fecha_registro',
			sortable: true,
			right: false
		},
		{
			name: 'gasto_prom',
			selector: 'gasto_prom',
			sortable: true,
			right: false
		},

		{
			name: 'Presión promedio',
			selector: 'presion_prom',
			sortable: true,
			right: false
		},

		{
			name: '% Eficiencia',
			selector: 'porcentaje_eficiencia',
			sortable: true,
			right: false
		},
		{
			name: '% Uniformidad',
			selector: 'porcentaje_uniformidad',
			sortable: true,
			right: false
		},
		{
			name: 'Lamina Riego',
			selector: 'lamina_riego',
			sortable: true,
			right: false
		},
		{
			name: 'Costo m3',
			selector: 'costo_m3',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Button className="btn btn-xs btn-warning" onClick={() => onUpdateClick(row.id_sector_diagnostico_riego)}><i className="fa fa-edit"></i></Button> <DeleteButton onClick={() => eliminaRegistro(row.id_sector_diagnostico_riego)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	]);


	useEffect(() => {
		if(props.id_productor > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
	}, [props.id_productor]);


	const eliminaRegistro = (id_sector_diagnostico_riego) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				setBlocking(true);
				Api.delete(ApiUrl.riegos.diagnosticos.general, id_sector_diagnostico_riego)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						setBlocking(false);
						props.onComplete();
						Swal.fire(
						  'Eliminado!',
						  'Registro eliminado.',
						  'success'
						);
					}
				})
				.catch(e => {
					setBlocking(false);
				  Swal.fire(
					'Error!',
					'Error al eliminar el registro.',
					'error'
				  );
				});

			}
		  })

	};
	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const onUpdateClick = (id_sector_lectura_caudalimetro) => {props.onUpdateClick(id_sector_lectura_caudalimetro);}

	const setDiagnostico = row  => {
		props.onSelectItem(row);
	  }
	

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar diagnóstico" 
				datosTabla={dataArray} 
				urlRegistrar={false}
				columnasFiltrar={["productor_nombres","nombre_sector","ciclo","created_at"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>
          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={props.diagnosticos}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default RiegosDiagnosticosTabla;
