import React, { useEffect,useState } from "react";
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

var dataArray = [];
const RiegosDiagnosticoInfo = (props) => {
	console.log(props);
	const [blocking, setBlocking] = useState(false);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			{props.diagnostico_inicial?.length == 1 && props.id_productor > 0 && (
			<table className="table table-condensed table-bordered">
				<tbody>
					<tr>
						<td>
							Nombre Huerta <br />
							<b>{props.diagnostico_inicial[0].nombre_huerta}</b>
						</td>
						<td>
							Superfície (m2) <br />
							<b>{props.diagnostico_inicial[0].superficie}</b>
						</td>
						<td>
							Longitud de camas equivalentes promedio (m) <br />
							<b>{props.diagnostico_inicial[0].longitud_camas_equivalentes_promedio}</b>
						</td>
						<td>
							Número de cintillas equivalentes por Sector <br />
							<b>{props.diagnostico_inicial[0].numero_cintillas_equivalentes_por_sector}</b>

						</td>
					</tr>
					<tr>
						<td>
							 Camas <br />
							<b>{props.diagnostico_inicial[0].camas_no}</b>
						</td>

						<td>
							Metros lineales <br />
							<b>{props.diagnostico_inicial[0].camas_largo}</b>
						</td>
						<td>
							Metros lineales <br />
							<b>{props.diagnostico_inicial[0].metros_lineales}</b>
						</td>
						<td>
							Costo/m3 <br />
							<b>{props.diagnostico_inicial[0].costo_m3}</b>
						</td>
					</tr>
					<tr>
						<td>
							Separación entre centro y centro de camas (m) <br />
							<b>{props.diagnostico_inicial[0].camas_distancha}</b>
						</td>
						<td>

						</td>

						<td>

						</td>
						<td>
		
						</td>
					</tr>

				</tbody>
			</table>
			)}
		</BlockUi>
    );
  };
export default RiegosDiagnosticoInfo;
