const Menu = [
  { 
  	path: '/', icon: 'fa fa-th', title: 'Dashboard'
  },

  { 
    path: '/administracion/usuarios', icon: 'fa fa-user', title: 'Usuarios',
    children: [
      { path: '/administracion/usuarios', title: 'Listado de usuarios' },
      { path: '/administracion/usuarios/registrar/0', title: 'Registrar usuario' },
    ]
  },


  { 
    path: '/administracion/usuarios/versiones', icon: 'fa fa-mobile', title: 'Versiones APK',
    children: [
          { path: '/administracion/usuarios/versiones', title: 'Listado' },
    ]
  },

  { 
    path: '/administracion/huertas', icon: 'fa fa-object-group', title: 'Huertas',
    children: [
          { path: '/administracion/huertas', title: 'Listado' },
          { path: '/administracion/huertas/registrar/0', title: 'Registrar' }
    ]
  },
  
  { 
    path: '/administracion/reportes', icon: 'fa fa-download', title: 'Reportes',
    children: [
          { path: '/administracion/reportes/bitacoras', title: 'Bitácoras' },
        /*  { path: '/administracion/reportes/costos', title: 'Costos' }, */
    ]
  },
  { 
  path: '/administracion/catalogos', icon: 'fa fa-list', title: 'Catálogos',
  children: [
        { 
          path: '/administracion/catalogos/plaguicidas', title: 'Plaguicidas',
          children: [
            { path: '/administracion/catalogos/plaguicidas', title: 'Listado' },
            { path: '/administracion/catalogos/plaguicidas/registrar/0', title: 'Registrar' }
          ]
        },
        { 
          path: '/administracion/catalogos/fertilizantes', title: 'Fertilizantes',
          children: [
            { path: '/administracion/catalogos/fertilizantes', title: 'Listado' },
            { path: '/administracion/catalogos/fertilizantes/registrar/0', title: 'Registrar' }
          ]
        },
        { 
          path: '/administracion/catalogos/sinc-catalogos', title: 'Sincronizar Catálogos',
          children: [
            { path: '/administracion/catalogos/sinc-catalogos', title: 'Listado' },
            { path: '/administracion/catalogos/sinc-catalogos/registrar/0', title: 'Registrar' }
          ]
        }
  ]
},


  { 
  path: '/administracion/avisos', icon: 'fa fa-bell', title: 'Avisos',
  children: [
        { path: '/administracion/avisos', title: 'Listado' },
        { path: '/administracion/avisos/registrar/0', title: 'Registrar' },
        { path: '/administracion/avisos/reporte/vistos', title: 'Reporte vistos' }
  ]
},

{ 
  path: '/administracion/videos', icon: 'fa fa-play', title: 'Videos',
  children: [
        { path: '/administracion/videos', title: 'Listado' },
        { path: '/administracion/videos/registrar/0', title: 'Registrar' }
  ]
},
{ 
  path: '/administracion/concursos', icon: 'fa fa-dice-three', title: 'Concursos',
  children: [
        { path: '/administracion/campanias', title: 'Listado Campañas' },
        { path: '/administracion/campanias/registrar/0', title: 'Registrar Campaña' },
        { path: '/administracion/concursos', title: 'Listado Concursos' },
        { path: '/administracion/concursos/registrar/0', title: 'Registrar Concurso' }
  ]
},

{ 
  path: '/administracion/riegos', icon: 'fa fa-list', title: 'Riego',
  children: [
        { path: '/administracion/riegos', title: 'Registro de datos' },
        { path: '/administracion/riegos/etos', title: 'ETOs' },
        { path: '/administracion/riegos/descarga', title: 'Descarga de datos' }
  ]
},
{ 
  path: '/administracion/costos', icon: 'fa fa-list', title: 'Costos',
  children: [
        { path: '/administracion/costos/plaguicidas', title: 'Listado de costos de plaguicidas' },
        { path: '/administracion/costos/fertilizantes', title: 'Listado de costos de fertilizantes' }
  ]
}
]

export default Menu;