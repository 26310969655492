import React,{useState,useEffect} from 'react';
import Api from '../../../api/global.service';
import conviertArray from '../../helpers/conviertArray'; 
import BlockUi from 'react-block-ui';

const FormSelectApiNested = (props) => {
    const [blocking, setBlocking] = useState(false);

	const [state, setState] = useState({
		items : []
	});

    const onFinish = () => {
        props.onFinish();
    };


	useEffect(() => {
        if(props.search.value > 0){
            setBlocking(true);
            Api.getAll(props.url + '?' + props.search.key + '=' + props.search.value).then(response => {
                let _items = conviertArray(response.data.data);
                setState({items : _items});
                setBlocking(false);
                onFinish();
            }).catch(e => {
                console.log(e);
                setBlocking(false);
            });
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.search.value]);

    return <>
    	<div className="form-group">
            <label htmlFor={props.id}>{props.label}</label>
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <select 
                ref={props.register}
                id={props.id}
                name={props.name}
                className={`form-control ${props.error ? "is-invalid" : ""}`}
                onChange={e => props.onChange(e.target.value)}
                >
                <option value={0} selected>--- SELECCIONE ---</option>

                {state.items.map((item,index) =>
                <option key={index} value={item[props.keyArray]}>
                        {item[props.labelArray]}
                    </option>
                )}
			</select>
            </BlockUi>
            {props.error && <div className="invalid-feedback">{props.error.message}</div>}
        </div>
    </>
};
export default FormSelectApiNested;