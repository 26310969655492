import React, { useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import FormSelectApiNested from '../../nuup/componentes/Forms/FormSelectApiNested.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';

const HuertasSectoresForm = (props) => {
		const schema = yup.object().shape({
			id_sector: yup.number().min(0),
			id_huerta: yup.number().min(0),
			nombre_sector: yup.string('Requerido').required('Requerido'),
			hectareas: yup.number().required('Requerido'),
			id_cultivo: yup.number().min(1,'Requedido'),
			id_variedad: yup.number().min(1,'Requedido'),
		});

		const [blocking, setBlocking] = useState(false);
		const [idCultivo, setIdCultivo] = useState(0);
		const [idSector] = useState(0);
		const [btncancelar, setBtncancelar] = useState(false);
		const [state,setState] = useState({
			id_huerta : props.id_huerta,
			id_sector : idSector,
			uuid : '',
			nombre_sector : '',
			hectareas : 0,
			id_cultivo : 0,
			id_variedad : 0,
		});
		const { register, handleSubmit, errors, reset,values } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: state
	});

	
	
	useEffect(() => {
		if (typeof props.sector.id_sector !== 'undefined') {
			setState(props.sector);
			setIdCultivo(props.sector.id_cultivo);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	
	const onSubmit = values => {
		if(values.id_sector > 0){
			actualizarSector(values);
		}else{
			crearSector(values);
		}
	}

	
	const crearSector = (values) => {
			setBlocking(true);
			Api.create(ApiUrl.sectores,{
				id_huerta : values.id_huerta,
				nombre_sector : values.nombre_sector,
				hectareas : values.hectareas,
				id_cultivo : values.id_cultivo,
				id_variedad : values.id_variedad
			}).then(response => {
				setBlocking(false);
				reset(state);
				cancelarEdit();

				let _result = response.data;
				if(_result.success === 1){
					Swal.close();
					Swal.fire(
						'Guardado!',
						'Sector creado.',
						'success'
					);
					props.onChangeElementos();
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire(
				'Error!',
				'Error al crear el sector.',
				'error'
				);
			});
			
		}
		

		const actualizarSector = values => {
			setBlocking(true);
			Api.update(ApiUrl.sectores,values.uuid, {
				id_sector : values.id_sector,
				id_huerta : values.id_huerta,
				nombre_sector : values.nombre_sector,
				hectareas : values.hectareas,
				id_cultivo : values.id_cultivo,
				id_variedad : values.id_variedad
			}).then(response => {
				setBlocking(false);
				let _result = response.data;
				if(_result.success === 1){
					cancelarEdit();
					Swal.fire({
						icon: 'success',
						title: '',
						text: _result.message,
					  })
					  props.onChangeElementos();
				}else{
					Swal.fire({
						icon: 'error',
						title: '',
						text: _result.message,
					  })
				}
			}).catch(e => {
				setBlocking(false);
				Swal.fire({
					icon: 'error',
					title: '',
					text: e,
				  })
			});
		}

		
		const cancelarEdit = () => {
			setBtncancelar(false);
			reset({
				id_huerta : props.id_huerta,
				uuid : '',
				nombre_sector : '',
				hectareas : 0,
				id_cultivo : 0,
				id_variedad : 0,
			});
		}

		const selectFinish = () => {
			if (typeof props.sector.id_sector !== 'undefined') {
				setBlocking(true);
				setTimeout(function(){ 
					setBlocking(false);
					reset(props.sector);
				 }, 500);
			}
		};

	return (
		<> 
			<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormInput 
					id="uuid"
					type="hidden"
					name="uuid"
					label="uuid"
					error={errors.uuid}
					register={register}
				/>
				<FormInput 
					id="id_huerta"
					type="hidden"
					name="id_huerta"
					label="id_huerta"
					error={errors.id_huerta}
					register={register}
				/>

				<FormInput 
					id="id_sector"
					type="hidden"
					name="id_sector"
					label="id_sector"
					error={errors.id_sector}
					register={register}
				/>

			<div className="row">

			<div className="col-md-6">
				<FormInput 
					id="nombre_sector"
					type="text"
					name="nombre_sector"
					label="Nombre sector"
					error={errors.nombre_sector}
					register={register}
					/>
			</div>
			<div className="col-md-6">
			<FormInput 
					id="hectareas"
					type="number"
					name="hectareas"
					label="Tamaño sector"
					step="0.01"
					error={errors.hectareas}
					register={register}
					/>
			</div>

			</div>
			<div className="row">

			<div className="col-md-6">
				<FormSelectApi 
					id="id_cultivo"
					name="id_cultivo"
					label="Cultivo"
					url={ApiUrl.catalogs.formularios.cultivos}
					keyArray="id_cultivo"
					labelArray="nombre_cultivo"
					error={errors.id_cultivo}
					onFinish={() => setIdCultivo(values.id_cultivo)}
					register={register}
					onChange={(value) => setIdCultivo(value)}
				/>
			</div>

			<div className="col-md-6">
				<FormSelectApiNested
					id="id_variedad"
					name="id_variedad"
					label="Variedad"
					url={ApiUrl.catalogs.formularios.variedades}
					search={{key : 'id_cultivo',value : idCultivo}}
					valueSearch={idCultivo}
					keyArray="id_variedad"
					labelArray="nombre_variedad"
					error={errors.id_variedad}
					register={register}
					onFinish={() => selectFinish()}
					onChange={(value) => {}}
				/>
			</div>

			</div>

	
	
				<div className="row" >
				<div className="col-md-4 offset-md-8">
					<div className="row" >
						<div className="col-md-12">
							<button className="btn btn-primary btn-block" type="submit">{(btncancelar) ? 'Actualizar' : 'Guardar'}</button>
							{btncancelar && (
								<button className="btn btn-warning btn-block" onClick={cancelarEdit}>Cancelar</button>
							)}
						</div>
	
					</div>
				</div>
				</div>
			</form>		
			</BlockUi>
		</>
	);
};
export default HuertasSectoresForm;
