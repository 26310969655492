import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import { Link } from "react-router-dom";
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import FertilizantesExportar from './FertilizantesExportar';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const FertilizantesTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const columns = [
		{
			name: 'ID',
			selector: 'id_producto_comercial',
			sortable: true,
			right: false
		},


		{
			name: 'NOMBRE',
			selector: 'nombre_producto_comercial',
			sortable: true
		},

		{
			name: 'MARCA',
			selector: 'nombre_producto_comercial_marca',
			sortable: true
		},

		{
			name: 'INGREDIENTES ACTIVOS',
			selector: 'ingredientes_activos',
			sortable: true
		},
		{
			name: 'CREADO',
			selector: 'created_at',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Link className="btn btn-xs btn-warning" to={'fertilizantes/registrar/' + row.id_producto_comercial}><i className="fa fa-edit"></i></Link> <DeleteButton onClick={() => eliminaFertilizantes(row.id_producto_comercial)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	];



	useEffect(() => {
		obtenerFertilizantes();
	}, []);

	  const obtenerFertilizantes = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.catalogs.fertilizantes.general)
		  .then(response => {
			  dataArray = response.data.data; 
			  setDatosFiltrados(response.data.data);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }


	const eliminaFertilizantes = (id_producto_comercial) => {		
		console.log(id_producto_comercial);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.catalogs.fertilizantes.general, id_producto_comercial)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Fertilizante eliminado.',
						  'success'
						);
					}
				obtenerFertilizantes();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el fertilizante.',
					'error'
				  );
				  obtenerFertilizantes();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar fertilizante" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/catalogos/fertilizantes/registrar/0" 
				columnasFiltrar={["id_producto_comercial","nombre_producto_comercial","nombre_producto_comercial_marca","ingredientes_activos","created_at"]}
				exportar={FertilizantesExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>

          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default FertilizantesTabla;
