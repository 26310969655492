import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import pivotArray from '../../../nuup/helpers/pivotArray.jsx';
import arrayToHTMLTable from '../../../nuup/helpers/arrayToHTMLTable.jsx';
import ModalAdminCostos from './ModalAdminCostos.jsx';
import ModalAgregaProductor from './ModalAgregaProductor.jsx';
import ModalAsociarProductoComercial from './ModalAsociarProductoComercial.jsx';
import ModalNuevoProductoComercial from './ModalNuevoProductoComercial.jsx';
//import ModalRegistrarProductoComercial from './../pages/catalogos/plaguicidas/PlaguicidasRegistrar.jsx';
import { subscribe, unsubscribe } from '../.././../components/events';
import Swal from 'sweetalert2';
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import BlockUi from 'react-block-ui';


const CostosListadoPlaguicidas = () => {
    const dialog = React.createRef();
    const dialogProductores = React.createRef();
    const dialogProductos = React.createRef();
    const dialogNuevoProducto = React.createRef();
    const [idCiclo, setIdCiclo] = useState(2);
    const [blocking, setBlocking] = useState(true);
	  const [datos, setDatos] = useState([]);
    const [path] = useState(ApiUrl.costos.plaguicidas);
	  const [historial, setHistorial] = useState([]);
    const [productoresFaltantes,setProductoresFaltantes] = useState([]);
    const [productosFaltantes,setProductosFaltantes] = useState([]);

    const openModal = (e) => {
      if (typeof e.target.value !== 'undefined'){
        let arrData = e.target.value.split("|");
        dialog.current.setIdProductoComercial(arrData[0]);
        dialog.current.setNombreProductoComercial(arrData[1]);
        dialog.current.setIdProductor(arrData[2])
        dialog.current.setNombreProductor(arrData[3])
        dialog.current.setCosto(arrData[4])
        dialog.current.setId(arrData[5]);
        dialog.current.setPath(path);
        dialog.current.show();
        obtenerHistorial(arrData[0],arrData[2]);
      }
    }

    useEffect(() => {
        if (idCiclo > 0) {
          dialog.current.setIdCiclo(idCiclo);
          dialogProductores.current.setIdCiclo(idCiclo);
          dialogProductos.current.setIdCiclo(idCiclo);
        }
      }, [idCiclo]);
    
      useEffect(() => {
        dialogProductores.current.setPath(ApiUrl.costos.plaguicidas);
        dialogProductores.current.setProductoresFaltantes(productoresFaltantes);

        dialogProductos.current.setPath(ApiUrl.costos.plaguicidas);
        dialogProductos.current.setProductosFaltantes(productosFaltantes);

      }, [productoresFaltantes]);
    
      useEffect(() => {
        dialogProductos.current.setPath(ApiUrl.costos.plaguicidas);
        dialogProductos.current.setProductosFaltantes(productosFaltantes);
      }, [productosFaltantes]);

      useEffect(() => {
        subscribe('AdminCosto::onSave', e => handleSave(e));
    
        return () => {
          unsubscribe('AdminCosto::onSave');
        };
      }, []);

      useEffect(() => {
        subscribe('AgregaProductor::onSave', e => handleSaveProductor(e,'agregado'));
        subscribe('QuitaProductor::onSave', e => handleSaveProductor(e,'eliminado'));
        subscribe('ProductoComercial::Asociado', e => handleAsociaProducto(e));
        subscribe('ProductoComercial::Creado', e => handleCreaProducto(e));
        return () => {
          unsubscribe('QuitaProductor::onSave');
          unsubscribe('AgregaProductor::onSave');
          unsubscribe('ProductoComercial::Asociado');
          unsubscribe('ProductoComercial::Creado');
        };
      }, []);

      const handleSaveProductor = (event,accion) => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Productor ${accion}`,
        });
       obtenerRegistros();
      };

      const handleAsociaProducto = event => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Producto comercial asociado`,
        });
       obtenerRegistros();
      };

      const handleCreaProducto = event => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Producto creado`,
        });
        asociaProducto(event.detail.id_producto_comercial);
      };

      const asociaProducto = (id_producto_comercial) => {
        let data = {id_ciclo : idCiclo,id_producto_comercial : id_producto_comercial};
        Api.create(path + '/agrega_producto/' + idCiclo, data).then((response) => {
          obtenerRegistros();
        }).catch(e => {
          setBlocking(false);
        });
      }
      
      

      const handleSave = event => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se guardó con éxito el costo ${event.detail.costo}.`,
        });
       obtenerRegistros();
      };

        useEffect(() => {
            obtenerRegistros();
        }, [idCiclo]);

        useEffect(() => {
          dialogProductores.current.setPath(ApiUrl.costos.plaguicidas);
          dialogProductores.current.setProductoresFaltantes(productoresFaltantes);
        }, [productoresFaltantes]);
      
        
          const obtenerRegistros = ()  => {
            setBlocking(true);
            Api.getAll(ApiUrl.costos.plaguicidas + '?id_ciclo=' + idCiclo)
              .then(response => {
                  setBlocking(false);
                  setDatos(response.data.data);
              })
              .catch(e => {
                setBlocking(false);
                console.log(e);
              });
          }

          const obtenerHistorial = (id_producto_comercial,id_productor)  => {
            setBlocking(true);
            Api.getAll(ApiUrl.costos.plaguicidas + '/historial/'  + idCiclo + '/' + id_producto_comercial + '/' + id_productor)
              .then(response => {
                  setBlocking(false);
                  setHistorial(response.data.data);
              })
              .catch(e => {
                setBlocking(false);
                console.log(e);
              });
          }
    
          const obtenerProductoresFaltantes = ()  => {
            setBlocking(true);
            dialogProductores.current.show();
            Api.getAll(ApiUrl.costos.plaguicidas + '/productores_faltantes/'  + idCiclo)
              .then(response => {
                  setBlocking(false);
                  setProductoresFaltantes(response.data.data);
                })
              .catch(e => {
                setBlocking(false);
                console.log(e);
              });
          }

          const obtenerProductosFaltantes = ()  => {
            setBlocking(true);
            dialogProductos.current.show();
            Api.getAll(ApiUrl.costos.plaguicidas + '/productos_faltantes/'  + idCiclo)
              .then(response => {
                  setBlocking(false);
                  setProductosFaltantes(response.data.data);
                })
              .catch(e => {
                setBlocking(false);
                console.log(e);
              });
          }

          const openModalProductores = () => {
            obtenerProductoresFaltantes();
          }


          const openModalProductos = () => {
            obtenerProductosFaltantes();
          }

          const openModalNuevoProducto = () => {
            dialogNuevoProducto.current.show();
          }

    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Costos</Link></li>
        <li className="breadcrumb-item active">Listado de costos de plaguicidas</li>
      </ol>
      <h1 className="page-header">Costos <small></small></h1>
      <Panel>
        <PanelHeader>Listado de costos de plaguicidas
        <div className="float-right" style={{paddingRight : 50}}>
            <select className='form-control' value={idCiclo} onChange={(event) => setIdCiclo(event.target.value)}>
              <option value="1">2022-2023</option>
              <option value="2">2023-2024</option>
            </select>
        </div>
        </PanelHeader>
        <PanelBody>
        <button style={{padding : 10,marginBottom : 10}} className="btn btn-info" onClick={() => openModalProductores()}>Administra productores</button>
        <button style={{marginLeft : 10,padding : 10,marginBottom : 10}} className="btn btn-warning" onClick={() => openModalProductos()}>Administra plaguicidas</button>
        <button style={{marginLeft : 10,padding : 10,marginBottom : 10}} className="btn btn-warning" onClick={() => openModalNuevoProducto()}>Nuevo plaguicida</button>
        <ModalAgregaProductor ref={dialogProductores} title="Agregar productor" ></ModalAgregaProductor>
        <ModalAsociarProductoComercial ref={dialogProductos} title="Asocia producto comercial existente" ></ModalAsociarProductoComercial>
        <ModalNuevoProductoComercial ref={dialogNuevoProducto} title="Agregar productor" redirect={false} publish="ProductoComercial::Creado" ></ModalNuevoProductoComercial>
        <ModalAdminCostos ref={dialog} title="Registrar costo" historial={historial}></ModalAdminCostos>
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <table>
              <thead>
                  <tr>
                    <td>
                        <div onClick={(value) => openModal(value)} className={''} dangerouslySetInnerHTML={{ __html: arrayToHTMLTable(pivotArray(datos,'id','nombre_producto_comercial','nombre','costo','Producto Comercial','id_producto_comercial','id_productor')).replace(/\n/g, '<br />')}} />
                    </td>
                  </tr>
              </thead>
            </table>
            </BlockUi>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default CostosListadoPlaguicidas;
