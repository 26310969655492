import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';

var dataArray = [];
const ConcursosTabla = (props) => {
	const [blocking, setBlocking] = useState(false);
	const [datosFiltrados, setDatosFiltrados] = useState([]);

	const getImages = row => {
		if (row.imagen_premio === null) {
			return 'N/A'
		}

		const imageURLs = row.imagen_premio.split(',')
		if (imageURLs.length === 1) {
			return <img alt="Imagen" className="img-thumbnail" src={ApiUrl.Url  + imageURLs[0]} />
		}

		// for more than one image use Carousel
		return (
			<Carousel interval={null}>
				{imageURLs.map(imURL => {
					return (
						<Carousel.Item>
							<img alt="Imagen" className="img-thumbnail" src={ApiUrl.Url  + imURL} />
					  </Carousel.Item>
					)
				})}	
		  </Carousel>
		)
	}
	

	const columns = [
		{
			name: 'ID',
			selector: 'id_concurso',
			maxWidth: '50px',
			sortable: true,
			right: false
		},
		{
			name: 'Campaña',
			selector: 'campania_name',
			sortable: true,
			maxWidth: '250px',
			wrap: true
		},
		{
			name: 'Tipo de Concurso',
			selector: 'id_cat_modal_concurso',
			sortable: true,
			maxWidth: '50px',
		},				
		{
			name: 'TÍTULO',
			selector: 'nombre_concurso',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'IMAGEN',
			sortable: true,
			maxWidth: '250px',
			right: false,
			cell: row => getImages(row)
		},
        {
			name: 'INICIO',
			selector: 'fecha_inicio',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
        {
			name: 'FIN',
			selector: 'fecha_final',
			sortable: true,
			maxWidth: '250px',
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => (
				<>
					<Link 
						className="btn btn-xs btn-warning mx-2" 
						to={'concursos/registrar/' + row.id_concurso}
					>
						<i className="fa fa-edit"></i>
					</Link> 
					<DeleteButton 
					  onClick={() => eliminaConcurso(row.id_concurso)}
					>
					  <i className="fa fa-trash"></i>
					</DeleteButton>
				</>
			),
		},
	];

  const eliminaConcurso = (id_concurso) => {		
		console.log(id_concurso);
		Swal.fire({
			title: '¿Esta seguro?',
			text: "Se eliminarán también los premios ya asignados",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				Api.delete(ApiUrl.concursos.general, id_concurso)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						Swal.close();
						Swal.fire(
						  'Eliminado!',
						  'Concurso eliminado.',
						  'success'
						);
					}
          obtenerConcursos();
				})
				.catch(e => {
				  Swal.fire(
					'Error!',
					'Error al eliminar el concurso.',
					'error'
				  );
				  obtenerConcursos();
				});

			}
		  })
	};

	useEffect(() => {
		obtenerConcursos();
	}, []);

  const obtenerConcursos = ()  => {
    setBlocking(true);	
    Api.getAll(ApiUrl.concursos.general).then(response => {
        dataArray = response.data.data; 
        setDatosFiltrados(response.data)
        // setDatosFiltrados(response.data.data);
        setBlocking(false);	
      })
      .catch(e => {
        setBlocking(false);	
        console.log(e);
      });
  }


	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}


	return (
		<>
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar concursos" 
				datosTabla={dataArray} 
				urlRegistrar="/administracion/concursos/registrar/0" 
				columnasFiltrar={["id_concurso","id_cat_modal_concurso","nombre_concurso","fecha_inicio","fecha_final"]}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>

          <DataTable
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
            noDataComponent={'Sin datos por mostrar'}
            customStyles={estiloGeneral}
          />
		</BlockUi>
    </>
	);
  };
export default ConcursosTabla;
