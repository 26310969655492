import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../../../nuup//componentes/Forms/FormInput.jsx";
import { Link, useParams } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";
import ListGroup from 'react-bootstrap/ListGroup'

const SyncCatalogosRegistrar = () => {
  const { id_schedule } = useParams();
  const [blocking, setBlocking] = useState(false);
  const [modal, setModal] = useState(false);

  const [data] = useState({
    id_schedule: id_schedule,
    nombre: "",
    descripcion: "",
    catalogs_url: "",
  });

  const schema = yup.object().shape({
    id_schedule: yup.number().min(0),
    nombre: yup.string().required("Nombre requerido"),
    descripcion: yup.string().required("Descripción requerido"),
    catalogs_url: yup.string(),
  });

  const { register, handleSubmit, errors, setError, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: data,
  });

  const creaSync = (values) => {
    setBlocking(true);
		console.log('LOS VALORES SON', values);
    Api.create(ApiUrl.catalogs.sinc.general, values)
      .then((response) => {
        setBlocking(false);
        const result = response.data;
				console.log(result)
        if (result.success === 1 && result.data.created_at) {
          Swal.fire({
            icon: "success",
            title: "",
            text: `Sync creado  ${result.data.created_at}`,
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = "/administracion/catalogos/sinc-catalogos";
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: result.message,
            showDenyButton: true,
            denyButtonText: `Regresar a Listado`,
          }).then((result) => {
            if (result.isDenied) {
              window.location = "/administracion/catalogos/sinc-catalogos";
            }
          });
        }
      })
      .catch((e) => {
        setBlocking(false);
        let errorMessage = e;
        if (e.response?.status === 422) {
          errorMessage = e.response.data.message;
          for (let key in errorMessage) {
            setError(key, {
              type: "server",
              message: errorMessage[key],
            });
          }
          errorMessage = Object.values(errorMessage).join(" / ");
        }

        setModal(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: JSON.stringify(errorMessage),
        });
      });
  };

	// Logic disabled until editing is implemented
	// useEffect(() => {
  //   if (id_schedule !== "0") {
  //     obtenerSync(data);
  //   }
  //   // eslint-disable-next-line
  // }, [id_schedule]);

  // const actualizaSync = (values) => {
  //   setBlocking(true);
  //   Api.getAll(ApiUrl.catalogs.sinc.general, values.id_schedule, values)
  //     .then((response) => {
  //       setBlocking(false);
  //       let _result = response.data;
  //       if (_result.success === 1) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "",
  //           text: _result.message,
  //         });
  //         window.location = "/administracion/catalogos/sinc-catalogos";
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "",
  //           text: _result.message,
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       setBlocking(false);
  //       Swal.fire({
  //         icon: "error",
  //         title: "",
  //         text: e,
  //       });
  //     });
  // };

  // const obtenerSync = () => {
  //   setBlocking(true);
  //   Api.getAll(ApiUrl.catalogs.sinc.general + "/" + id_schedule)
  //     .then((response) => {
  //       const syncData = response.data.data;
	// 			reset(syncData);
  //       setBlocking(false);
  //     })
  //     .catch((e) => {
  //       setBlocking(false);
  //       console.log(e);
  //     });
  // };

  const onSubmit = () => {
    // if (id_schedule > 0) {
    //   actualizaSync(getValues());
    // } else {
		creaSync(getValues());
    // }
  };

  const onConfirm = () => {
    setModal(true);
  };

  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Revisar Información Antes de Enviar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {Object.keys(getValues()).map((key, ind) => {
              return (
                <ListGroup.Item key={ind}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>{getValues()[key]}</strong>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Seguir Editando
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            ENVIAR
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/administracion/videos">Registrar Sincronización</Link>
          </li>
          <li className="breadcrumb-item active"></li>
        </ol>
        <h1 className="page-header">
          Administración de Syncronización de Catálogos <small></small>
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelHeader>Datos</PanelHeader>
              <PanelBody>
                <BlockUi
                  tag="div"
                  message=""
                  blocking={blocking}
                  renderChildren={true}
                >
                  <form onSubmit={handleSubmit(onConfirm)}>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <FormInput
                          id="nombre"
                          type="text"
                          name="nombre"
                          label="Nombre"
                          error={errors.nombre}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <FormInput
                          id="descripcion"
                          type="text"
                          name="descripcion"
                          label="Descripción"
                          error={errors.descripcion}
                          register={register}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <FormInput
                          id="catalogs_url"
                          type="text"
                          name="catalogs_url"
                          label="Especificar un sólo catálogo (sección deshabilitada por el momento)"
                          error={errors.url}
                          register={register}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 offset-md-9">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {id_schedule !== "0"
                            ? "Actualizar Sincronización"
                            : "Crear Sincronización"}
                        </button>
                      </div>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
export default SyncCatalogosRegistrar;
