import { useState, useEffect } from "react";
import Api from "../../../api/global.service";
import { cleanData } from "./DataHelpers";

export const useFetchGraphData = (
  url,
  fechaInicial = "",
  fechaFinal = "",
  dataProcessing = cleanData,
  preData = null
) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (preData) {
      setData(preData);
      setBlocking(false);
    } else {
      Api.getAll(url, { fechaInicial: fechaInicial, fechaFinal: fechaFinal })
        .then((response) => {
          setData(dataProcessing(response.data));
          setBlocking(false);
        })
        .catch((e) => {
          setBlocking(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal, preData]);

  return [blocking, data]
};

export default useFetchGraphData;
