import React from 'react';
import '../../styles/Formularios.css';

const FormCheckbox = ({register,error,label,id,value,...inputProps}) => {
    return <>
        <div className="form-group">
        <label className="form-check-label" for="exampleRadios1">
                {label}
            </label>
            <input 
                ref={register}
                id={id}
                className={`form-control  ${error ? "is-invalid" : ""}`}
                type="checkbox"
                value={value}
                {...inputProps}
            ></input>

            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormCheckbox;