import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import SyncCatalogosTabla from './SyncCatalogosTabla.jsx';

const SyncCatalogosListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Administración de sincronización de catálogos</Link></li>
        <li className="breadcrumb-item active">Listado de sincronización de catálogos</li>
      </ol>
      <h1 className="page-header">Administración de sincronización de catálogos <small></small></h1>
      <Panel>
        <PanelHeader>Syncronizaciones registradas</PanelHeader>
        <PanelBody>
            <SyncCatalogosTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default SyncCatalogosListar;
