import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import "bootstrap/dist/css/bootstrap.css"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormInput from '../../../nuup/componentes/Forms/FormInput.jsx';
import { Link,useParams } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import FertilizantesPreciosTabla from './FertilizantesPreciosTabla';
import FertilizantesNutrientesTabla from './FertilizantesNutrientesTabla';

const FertilizantesRegistrar = () => {
	const [blocking, setBlocking] = useState(false);
	const { id_producto_comercial } = useParams();
	const [data] = useState({
		id_producto_comercial : id_producto_comercial,
		uuid: "",
		nombre_producto_comercial: "",
		nombre_producto_comercial_marca: "",
		ingredientes_activos: ""
	});

	const schema = yup.object().shape({
		id_producto_comercial: yup.number().min(0),
		uuid: yup.string(),
		nombre_producto_comercial: yup.string().required('Producto comercial es requerido'),
		nombre_producto_comercial_marca: yup.string().required('Marca es requerido'),
		ingredientes_activos: yup.string().required('Ingredientes activos es requerido'),
	});


	const { register, handleSubmit, errors,reset } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur',
		defaultValues: data
	});


	useEffect(() => {
		if (id_producto_comercial > 0) {
			obtenerFertilizante();
		}else{
			reset(data);
		}
	// eslint-disable-next-line
	}, [id_producto_comercial]);


	const obtenerFertilizante = () => {
		setBlocking(true);
		setTimeout(function(){ 
			Api.getAll(ApiUrl.catalogs.fertilizantes.general + '/' + id_producto_comercial).then(response => {
				let fertilizanteData = response.data.data;
				reset(fertilizanteData[0]);
				setBlocking(false);
			}).catch(e => {
				setBlocking(false);
				console.log(e);
			});
		 }, 5000);
	}

	const creaFertilizante = values => {
		setBlocking(true);
		Api.create(ApiUrl.catalogs.fertilizantes.general,values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				window.location.href = '/administracion/catalogos/fertilizantes/registrar/' + _result.data.id_producto_comercial;
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const actualizaFertilizante = values => {
		setBlocking(true);
		Api.update(ApiUrl.catalogs.fertilizantes.general,values.id_producto_comercial, values).then(response => {
			setBlocking(false);
			let _result = response.data;
			if(_result.success === 1){
				Swal.fire({
					icon: 'success',
					title: '',
					text: _result.message,
				  })
				  window.location.reload(); 
			}else{
				Swal.fire({
					icon: 'error',
					title: '',
					text: _result.message,
				  })
			}
		}).catch(e => {
			setBlocking(false);
			Swal.fire({
				icon: 'error',
				title: '',
				text: e,
			  })
		});
	}

	const onSubmit = values => {
		
		if (id_producto_comercial > 0) {
			actualizaFertilizante(values);
		} else {
			creaFertilizante(values);
		}
		
	}

	return (
		<>
			<ol className="breadcrumb float-xl-right">
				<li className="breadcrumb-item"><Link to="/administracion/catalogos/fertilizantes">Registrar fertilizante</Link></li>
				<li className="breadcrumb-item active"></li>
			</ol>
			<h1 className="page-header">Administración de fertilizantes <small></small></h1>

			<div className="row">
			<div className="col-md-12">

				<Panel>
					<PanelHeader>Datos del fertilizante</PanelHeader>
					<PanelBody>
					<BlockUi tag="div" message="Cargando..." blocking={blocking} renderChildren={true}>

					<form onSubmit={handleSubmit(onSubmit)}>

				<div className="form-group row">
	
					<div className="col-sm-12">
						<FormInput 
							id="nombre_producto_comercial"
							type="text"
							name="nombre_producto_comercial"
							label="Nombre"
							error={errors.nombre_producto_comercial}
							register={register}
						/>
					</div>
					</div>



					<div className="form-group row">
				<div className="col-sm-12">
						<FormInput 
							id="nombre_producto_comercial_marca"
							type="text"
							name="nombre_producto_comercial_marca"
							label="Marca"
							error={errors.nombre_producto_comercial_marca}
							register={register}
						/>
					</div>
				</div>


					<div className="form-group row">

					<div className="col-sm-12">
						<FormInput 
							id="ingredientes_activos"
							type="text"
							name="ingredientes_activos"
							label="Ingredientes activos"
							error={errors.ingredientes_activos}
							register={register}
						/>
					</div>

				</div>

	
				<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="id_producto_comercial"
									type="hidden"
									name="id_producto_comercial"
									label="id_producto_comercial"
									error={errors.id_producto_comercial}
									register={register}
								/>
							</div>
						</div>


						<div className="form-group row">
							<div className="col-md-12">
								<FormInput 
									id="uuid"
									type="hidden"
									name="uuid"
									label="UUID"
									error={errors.uuid}
									register={register}
								/>
							</div>
						</div>

						<div className="row">
						<div className="col-md-3 offset-md-9">
							<button className="btn btn-primary btn-block" type="submit">Guardar fertilizante</button>
						</div>
					</div>
					</form>
					</BlockUi>

					</PanelBody>
				</Panel>
			</div>
			</div>

			{id_producto_comercial > 0 && (
			<div className="row">

				<div className="col-md-6">

				<Panel>
					<PanelHeader>Nutrientes</PanelHeader>
					<PanelBody>
						<FertilizantesNutrientesTabla id_producto_comercial={id_producto_comercial} />
					</PanelBody>

				</Panel>
				</div>

				<div className="col-md-6">

					<Panel>
						<PanelHeader>Precios del sistema</PanelHeader>
						<PanelBody>
							<FertilizantesPreciosTabla id_producto_comercial={id_producto_comercial} />
						</PanelBody>

					</Panel>
				</div>

			</div>
			)}
		</>
	);
};
export default FertilizantesRegistrar;
