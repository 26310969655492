import xlsx from "json-as-xlsx";
import moment from 'moment';
const LecturasCaudalimetrosExportar = (content) => {
    let columns = [
        { label: 'id', value: 'id_recomendacion_riego_eto' },
        { label: 'Ciclo', value: 'nombre_ciclo' },
        { label: 'Estación', value: 'nombre_estacion' },
        { label: 'Fecha Inicio', value: 'fecha' },
        { label: 'Fecha Fin', value: 'fecha_fin' },
        { label: 'Semana', value: 'semana' },
        { label: 'ETO', value: 'eto' },
        { label: 'created_at', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'LecturasEto',
        fileName: 'LecturasEto_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default LecturasCaudalimetrosExportar;
