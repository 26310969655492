import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import VersionesExportar from './VersionesExportar';
import HerramientasTablas from '../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import VersionesHistorialTabla from './VersionesHistorialTabla';

var dataArray = [];
const VersionesTabla = (props) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(true);
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [idUser, setIdUser] = useState(0);

	const [state, setState] = useState({
	id_user : 0,
	usuarios: [],
	usuariosFiltrados: [],
	currentUsuario: null,
	currentIndex: -1
	});

	const columns = [
		{
			name: 'ID USUARIO',
			selector: 'id_user',
			maxWidth: '150px',
			sortable: true,
			right: false
		},
		{
			name: 'USUARIO',
			selector: 'productor',
			maxWidth: '800px',
			sortable: true,
			right: false
		},

	
		{
			name: 'ÚLTIMA VERSION',
			selector: 'version',
			maxWidth: '200px',
			sortable: true,
			right: false
		},


		{
			name: 'HISTORIAL DE VERSIONES',
			maxWidth: '200px',
			cell: (row) => <><button onClick={() => verHistorial(row.id_user)} className="btn btn-info">{row.historial}</button></>,
		},

		{
			name: 'CREADO',
			selector: 'created_at',
			maxWidth: '200px',
			sortable: true,
			right: false
		}
		
	];




	useEffect(() => {
		obtenerVersiones();
	}, []);

	  const obtenerVersiones = ()  => {
		setBlocking(true);
		Api.getAll(ApiUrl.versiones)
		  .then(response => {
			  dataArray = response.data.data; 
			  setDatosFiltrados(dataArray);
			setBlocking(false);
		})
		  .catch(e => {
			setBlocking(false);
		  });
	  }


	const hideFormEdit = () => {
		setState({...state,id_user : 0});
		setShow(false);
	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}

	const verHistorial = (id_user) => {
		setIdUser(id_user);
		setShow(true);
	}


	

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar" 
				datosTabla={dataArray} 
				urlRegistrar="" 
				columnasFiltrar={["id_user","productor","version","created_at"]}
				exportar={VersionesExportar}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}

			/>
			<DataTable
				noHeader
				pagination
				defaultSortAsc={false}
				columns={columns}
				data={datosFiltrados}
				Clicked
				noDataComponent={'Sin datos por mostrar'}
				customStyles={estiloGeneral}
				highlightOnHover
			/>

			<Modal
              show={show}
              onHide={() => hideFormEdit()}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Historial de versiones
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
				<VersionesHistorialTabla id_user={idUser} />
              </Modal.Body>
            </Modal>
		</BlockUi>
    );
  };
export default VersionesTabla;
