import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FertilizantesTabla from './FertilizantesTabla.jsx';

const FertilizantesListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Administración de fertilizantes</Link></li>
        <li className="breadcrumb-item active">Listado de fertilizantes</li>
      </ol>
      <h1 className="page-header">Administración de fertilizantes <small></small></h1>
      <Panel>
        <PanelHeader>fertilizantes registrados</PanelHeader>
        <PanelBody>
            <FertilizantesTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default FertilizantesListar;
