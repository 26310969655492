import React from "react";
import FormInput from "./FormInput.jsx";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format, sub } from "date-fns";

const FormFiltradoRangoFechas = (props) => {
  const today = new Date();
  const threeMonthsAgo = sub(today, { months: 3 });
  const schema = yup.object().shape({
    fechaInicial: yup.date().required("Fecha inicial es requerido"),
    fechaFinal: yup.date().required("Fecha final es requerido"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      fechaInicial: format(threeMonthsAgo, "yyyy-MM-dd"),
      fechaFinal: format(today, "yyyy-MM-dd"),
    },
  });

  const onSubmit = (values) => {
    props.onFilter({
      fechaInicial: values.fechaInicial,
      fechaFinal: values.fechaFinal,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", padding: "0px 15px" }}>
        <div className="row">
          <div className="col-md-4">
            <FormInput
              id="fechaInicial"
              type="date"
              name="fechaInicial"
              label="Fecha Inicial"
              error={errors.fechaInicial}
              register={register}
            />
          </div>

          <div className="col-md-4">
            <FormInput
              id="fechaFinal"
              type="date"
              name="fechaFinal"
              label="Fecha Final"
              error={errors.fechaFinal}
              register={register}
            />
          </div>
        <div className="col-md-4 mt-2">
            <br />
            <button className="btn btn-primary btn-block" type="submit">
            Filtrar
            </button>
        </div>
        </div>
    </form>
  );
};
export default FormFiltradoRangoFechas;
