import React, { Suspense, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RiegosListadoProductores from './RiegosListadoProductores';
import RiegosConsumoAgua from './RiegosConsumoAgua.jsx';
import RiegosTensiometros from './RiegosTensiometros.jsx';
import RiegosDiagnosticoInicial from './RiegosDiagnosticoInicial.jsx';
import RiegosRecomendaciones from './RiegosRecomendaciones.jsx';
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';

const Riegos = () => {
  const [idCiclo, setIdCiclo] = useState(1);
  const [idProductor, setIdProductor] = useState(0);
  const [nombreProductor, setNombreProductor] = useState();
  const [diagnosticos, setDiagnosticos] = useState([]);
  const [tipo, setTipo] = useState(1);
  const [key, setKey] = useState('diagnostico');
  const [huertas, setHuertas] = useState([]);
  const [huertasConDiagnostico, setHuertasConDiagnostico] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [lecturasEtos, setLecturasEtos] = useState([]);
  const [tiposTensiometros, setTiposTensiometros] = useState([]);
  const [productores, setProductores] = useState([]);
  const [reload, setReload] = useState(0);
  const [ciclos, setCiclos] = useState([]);

  const onChangeTab = (eventKey) => {
    setKey(eventKey);
    switch (eventKey) {
      case 'diagnostico':
        setTipo(1);
        break;
      case 'tensiometros':
        setTipo(2);
        break;
      case 'cosnumoagua':
        setTipo(3);
        break;
      case 'recomendaciones':
        setTipo(4);
        break;
      default:
        break;
    }

  }

  const setDataProductor = row => {
    setIdProductor(row.id);
    setNombreProductor(row.full_name);
  }


  const obtenerHuertas = () => {
    Api.getAll(ApiUrl.riegos.sectoresdiagnostico + '?id_productor=' + idProductor + '&id_ciclo=' + idCiclo).then(response => {
      let data = response.data.data;
      setHuertas(data);
    }).catch(e => {
      console.log(e);
    });
  };


  const obtenerHuertasConDiagnostico = () => {
    Api.getAll(ApiUrl.riegos.sectoresproductor + '?id_productor=' + idProductor + '&id_ciclo=' + idCiclo).then(response => {
      let data = response.data.data;
      setHuertasConDiagnostico(data);
    }).catch(e => {
      console.log(e);
    });
  };



  const obtenerEtapas = () => {
    Api.getAll(ApiUrl.catalogs.formularios.etapas).then(response => {
      let data = response.data.data;
      setEtapas(data);
    }).catch(e => {
      console.log(e);
    });
  };

  const obtenerLecturasEto = () => {
    Api.getAll(ApiUrl.riegos.recomendaciones.etos + '?id_productor=' + idProductor + '&id_ciclo=' + idCiclo).then(response => {
      setLecturasEtos(response.data.data);
    }).catch(e => {
      console.log(e);
    });
  }

  const obtenerDiagnosticos = () => {
    Api.getAll(ApiUrl.riegos.diagnosticos.general + '?id_productor=' + idProductor + '&id_ciclo=' + idCiclo)
      .then(response => {
        setDiagnosticos(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const obtenerTipostensiometros = () => {
    Api.getAll(ApiUrl.catalogs.formularios.tipostensiometros)
      .then(response => {
        setTiposTensiometros(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const obtenerProductores = () => {
    Api.getAll(ApiUrl.riegos.productores)
      .then(response => {
        setProductores(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  const obtenerCiclos = () => {
    Api.getAll(ApiUrl.riegos.ciclos)
      .then(response => {
        setCiclos(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  useEffect(() => {
    obtenerHuertas();
    obtenerHuertasConDiagnostico();
    obtenerTipostensiometros();
    obtenerEtapas();
    obtenerLecturasEto();
    obtenerProductores();
    obtenerCiclos();
    // eslint-disable-next-line
  }, [idProductor, idCiclo]);

  const onUpdateDiagnosticos = () => {
    setReload(reload + 1);
  };

  useEffect(() => {
    obtenerDiagnosticos();
  }, [idProductor, idCiclo, reload]);



  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Módulo de riegos</Link></li>
        <li className="breadcrumb-item active"></li>
      </ol>
      <h1 className="page-header">Módulo de riegos<small></small></h1>

      <div className="row">
        <div className="col col-md-3">
          <Panel>
            <PanelHeader><h5>{'Productores'}</h5></PanelHeader>
            <PanelBody>
              <RiegosListadoProductores productores={productores} id_ciclo={idCiclo} onSelectItem={(item) => setDataProductor(item)} />
            </PanelBody>
          </Panel>
        </div>
        <div className="col col-md-9">
          <Panel>
            <PanelHeader>

              <div className="row">
                <div className="col-sm-9">
                  <h5>{nombreProductor}</h5>
                </div>
                <div className="col-sm-3">
                  <div class="float-right" style={{ paddingRight: 50 }}>
                    <select className='form-control' onChange={(event) => setIdCiclo(event.target.value)}>
                      {ciclos.length > 0 ? ciclos.map((item, index) => {
                        return (<option key={index} value={item.id_ciclo}>{item.nombre_ciclo}</option>);
                      }
                      ) : (
                        //cargando
                        <option value="1">Cargando...</option>
                      )

                      }
                    </select>
                  </div>
                </div>
              </div>


            </PanelHeader>
            <PanelBody>
              {idProductor > 0
                ? <Tabs onSelect={onChangeTab} defaultActiveKey={key} id="uncontrolled-tab-example">
                  <Tab tabClassName={key === 'diagnostico' ? 'tab-active' : 'tab'} eventKey="diagnostico" title="Diagnóstico inicial">
                    <RiegosDiagnosticoInicial
                      diagnosticos={diagnosticos}
                      huertas={huertas}
                      id_ciclo={idCiclo}
                      id_productor={idProductor}
                      onUpdateDiagnosticos={() => onUpdateDiagnosticos()}
                    />
                  </Tab>

                  <Tab tabClassName={key === 'tensiometros' ? 'tab-active' : 'tab'} eventKey="tensiometros" title="Lectura de tensiómetros">
                    <RiegosTensiometros
                      huertas={huertasConDiagnostico}
                      id_ciclo={idCiclo}
                      id_productor={idProductor}
                      tipos_tensiometros={tiposTensiometros}
                    />
                  </Tab>

                  <Tab tabClassName={key === 'cosnumoagua' ? 'tab-active' : 'tab'} eventKey="cosnumoagua" title="Consumo de agua">
                    <RiegosConsumoAgua
                      diagnosticos={diagnosticos}
                      etapas={etapas}
                      huertas={huertasConDiagnostico}
                      id_ciclo={idCiclo}
                      id_productor={idProductor}
                    />
                  </Tab>

                  <Tab tabClassName={key === 'recomendaciones' ? 'tab-active' : 'tab'} eventKey="recomendaciones" title="Recomendaciones de riego">
                    <RiegosRecomendaciones
                      diagnosticos={diagnosticos}
                      lecturasEtos={lecturasEtos}
                      etapas={etapas}
                      huertas={huertasConDiagnostico}
                      id_ciclo={idCiclo}
                      id_productor={idProductor}
                      nombre_productor={nombreProductor}
                    />
                  </Tab>

                </Tabs>
                : <center><h3>Seleccione un productor para continuar</h3></center>
              }
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};
export default Riegos;
