import React,{useState,useEffect} from 'react';
import Api from '../../../api/global.service';
import conviertArray from '../../helpers/conviertArray'; 
import BlockUi from 'react-block-ui';
import '../../styles/Formularios.css';
const FormSelectApi = (props) => {


	const [state, setState] = useState({
		items : [],
        blocking: true
});

    const onFinish = () => {
        props.onFinish();
    };


	useEffect(() => {
        Api.getAll(props.url).then(response => {
            let _items = conviertArray(response.data.data);
            setState({items : _items,blocking : false});
            onFinish();
        }).catch(e => {
			console.log(e);
        });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


    return <>
            <div>
                <label htmlFor={props.id}>{props.label}</label>
                <BlockUi tag="div" blocking={state.blocking}>
                <select 
                    ref={props.register}
                    id={props.id}
                    name={props.name}
                    className={`form-control selectpicker ${props.error ? "is-invalid" : ""}`}
                    onChange={e => props.onChange(e.target.value)}
                    >
                    <option value={0} selected>--- SELECCIONE ---</option>

                    {state.items.map((item,index) =>
                    <option key={index} value={item[props.keyArray]}>
                            {item[props.labelArray]}{props.labelArray2? " " + item[props.labelArray2] : ""}
                        </option>
                    )}
                </select>
                    {props.error && <div className="invalid-feedback">{props.error.message}</div>}
                </BlockUi>
            </div>
    </>
};
export default FormSelectApi;