import xlsx from "json-as-xlsx";
import moment from 'moment';

const VersionesExportar = (content) => {
    let columns = [
        { label: 'ID Usuario', value: 'id_user' },
        { label: 'Usuario', value: 'productor' },
        { label: 'Version', value: 'version' },
        { label: 'Historial', value: 'historial' },
        { label: 'Fecha registro', value: 'created_at' }
      ];
      
      let settings = {
        sheetName: 'Versiones',
        fileName: 'Versiones_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
     xlsx(columns, content, settings, true);
  };
  export default VersionesExportar;
