import { filterData, SearchType } from 'filter-data';


/*
    SearchType.EQ: equal
    SearchType.GT: greater than
    SearchType.GTE: greater than or equal
    SearchType.LT: less than
    SearchType.LTE: less than or equal
    SearchType.LK: like
    SearchType.NE: not equal
    SearchType.NLK: not like
*/

const filterDataTables = (data, search, searchFields) => {
    const searchConditions = [
        {
          key: searchFields,
          value: search,
          type: SearchType.LK,
        },
      ];
      return filterData(data, searchConditions, { caseSensitive: false });
    }

    export default filterDataTables;