import React from 'react';
import Home from './../pages/Home.js';
import LoginV3 from './../pages/login-v3.js';
import UsuariosListar from './../pages/usuarios/UsuariosListar.jsx';
import UsuariosRegistrar from './../pages/usuarios/UsuariosRegistrar.jsx';

import VideosListar from './../pages/videos/VideosListar.jsx';
import VideosRegistrar from './../pages/videos/VideosRegistrar.jsx';
import AvisosListar from './../pages/avisos/AvisosListar.jsx';
import ConcursosListar from './../pages/concursos/ConcursosListar.jsx';
import ConcursosRegistrar from './../pages/concursos/ConcursosRegistrar.jsx';
import CampaniasListar from './../pages/concursos/CampaniasListar.jsx';
import CampaniasRegistrar from './../pages/concursos/CampaniasRegistrar.jsx';
import CampaniasEstatus from './../pages/concursos/CampaniasEstatus.jsx';
import AvisosRegistrar from './../pages/avisos/AvisosRegistrar.jsx';
import PlaguicidasListar from './../pages/catalogos/plaguicidas/PlaguicidasListar.jsx';
import PlaguicidasRegistrar from './../pages/catalogos/plaguicidas/PlaguicidasRegistrar.jsx';
import FertilizantesListar from './../pages/catalogos/fertilizantes/FertilizantesListar.jsx';
import FertilizantesRegistrar from './../pages/catalogos/fertilizantes/FertilizantesRegistrar.jsx';
import SyncCatalogosListar from './../pages/catalogos/sync/SyncCatalogosListar.jsx';
import SyncCatalogosRegistrar from './../pages/catalogos/sync/SyncCatalogosRegistrar.jsx';
import HuertasListar from './../pages/huertas/HuertasListar.jsx';
import HuertasRegistrar from './../pages/huertas/HuertasRegistrar.jsx';
import ReportesBitacoras from './../pages/reportes/bitacoras/ReportesBitacoras';
import ReportesCostos from './../pages/reportes/costos/ReportesCostosTabla';
import ReporteActivos from './../pages/reportes/ReporteActivos';
import AvisosVistosListar from './../pages/avisos/reportes/AvisosVistosListar.jsx';
import VersionesListar from './../pages/versiones/VersionesListar.jsx';
import ArablePilot from './../pages/centinela/ArablePilot.jsx';

import Riegos from './../pages/riegos/Riegos.jsx';
import RiegosDiagnosticosListar from './../pages/riegos/diagnostico/RiegosDiagnosticosListar.jsx';
import RiegosDiagnosticosRegistrar from './../pages/riegos/diagnostico/RiegosDiagnosticosRegistrar.jsx';

import LecturasTensiometrosListar from './../pages/riegos/lecturastensiometros/LecturasTensiometrosListar.jsx';
import LecturasTensiometrosRegistrar from './../pages/riegos/lecturastensiometros/LecturasTensiometrosRegistrar.jsx';

import LecturasCaudalimetrosListar from './../pages/riegos/lecturascaudalimetros/LecturasCaudalimetrosListar.jsx';
import LecturasCaudalimetrosRegistrar from './../pages/riegos/lecturascaudalimetros/LecturasCaudalimetrosRegistrar.jsx';

import RiegosEtos from './../pages/riegos/RiegosEtos.jsx';
import RiegosDescargas from './../pages/riegos/descargas/RiegosDescargas.jsx';

import Costos from './../pages/costos/Costos.jsx';
import CostosListadoPlaguicidas from './../pages/costos/listado/CostosListadoPlaguicidas.jsx';
import CostosListadoFertilizantes from './../pages/costos/listado/CostosListadoFertilizantes.jsx';


const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Home />
  },
  {
    path: '/login',
    exact: true,
    title: 'Acceder',
    component: () => <LoginV3 />
  },
  {
    path: '/administracion/usuarios',
    exact: true,
    title: 'Usuarios Registrados',
    component: () => <UsuariosListar />
  },
  {
    path: '/administracion/usuarios/registrar/:id_usuario',
    exact: true,
    title: 'Registrar usuario',
    component: () => <UsuariosRegistrar />
  },
  {
    path: '/administracion/usuarios/versiones',
    exact: true,
    title: 'Versiones Registradas',
    component: () => <VersionesListar />
  },

  {
    path: '/administracion/huertas',
    exact: true,
    title: 'Huertas registradas',
    component: () => <HuertasListar />
  },

  {
    path: '/administracion/huertas/registrar/:id_huerta',
    exact: true,
    title: 'Registrar huerta',
    component: () => <HuertasRegistrar />
  },

  {
    path: '/administracion/reportes/bitacoras',
    exact: true,
    title: 'Exportar bitácoras',
    component: () => <ReportesBitacoras />
  },
  /*
  {
    path: '/administracion/reportes/costos',
    exact: true,
    title: 'Reporte de Costos',
    component: () => <ReportesCostos />
  },
  */
  {
    path: '/administracion/reportes/activos',
    exact: true,
    title: 'Exportar activos',
    component: () => <ReporteActivos />
  },

  {
    path: '/administracion/videos',
    exact: true,
    title: 'Videos Registrados',
    component: () => <VideosListar />
  },
  {
    path: '/administracion/videos/registrar/:id_video',
    exact: true,
    title: 'Registrar video',
    component: () => <VideosRegistrar />
  },
  
  {
    path: '/administracion/avisos',
    exact: true,
    title: 'Avisos Registrados',
    component: () => <AvisosListar />
  },
  {
    path: '/administracion/campanias/registrar/:id_campania',
    exact: true,
    title: 'Registrar Campaña',
    component: () => <CampaniasRegistrar />
  },
  {
    path: '/administracion/campanias/estatus/:id_campania',
    exact: true,
    title: 'Estatus de Campaña',
    component: () => <CampaniasEstatus />
  },
  {
    path: '/administracion/campanias',
    exact: true,
    title: 'Campañas',
    component: () => <CampaniasListar />
  },
  {
    path: '/administracion/concursos',
    exact: true,
    title: 'Concursos',
    component: () => <ConcursosListar />
  },
  {
    path: '/administracion/concursos/registrar/:id_concurso',
    exact: true,
    title: 'Registrar Concurso',
    component: () => <ConcursosRegistrar />
  },
  {
    path: '/administracion/avisos/registrar/:id_aviso',
    exact: true,
    title: 'Registrar aviso',
    component: () => <AvisosRegistrar />
  },

  {
    path: '/administracion/avisos/reporte/vistos',
    exact: true,
    title: 'Reporte vistos',
    component: () => <AvisosVistosListar />
  },

  {
    path: '/administracion/catalogos/plaguicidas',
    exact: true,
    title: 'Plaguicidas registrados',
    component: () => <PlaguicidasListar />
  },
  {
    path: '/administracion/catalogos/plaguicidas/registrar/:id_producto_comercial',
    exact: true,
    title: 'Registrar plaguicida',
    component: () => <PlaguicidasRegistrar />
  },
  {
    path: '/administracion/catalogos/fertilizantes',
    exact: true,
    title: 'Fertilizantes registrados',
    component: () => <FertilizantesListar />
  },

  {
    path: '/administracion/catalogos/fertilizantes/registrar/:id_producto_comercial',
    exact: true,
    title: 'Registrar fertilizantes',
    component: () => <FertilizantesRegistrar />
  },

  {
    path: '/administracion/catalogos/sinc-catalogos',
    exact: true,
    title: 'Syncronizar catálogos',
    component: () => <SyncCatalogosListar />
  },
  {
    path: '/administracion/catalogos/sinc-catalogos/registrar/:id_schedule',
    exact: true,
    title: 'Registrar Sincronización',
    component: () => <SyncCatalogosRegistrar />
  },

  {
    path: '/centinela',
    exact: true,
    title: 'Piloto Aerable',
    component: () => <ArablePilot />
  },

  {
    path: '/administracion/riegos',
    exact: true,
    title: 'Riegos',
    component: () => <Riegos />
  },
  {
    path: '/administracion/riegos/etos',
    exact: true,
    title: 'Riegos ETOs',
    component: () => <RiegosEtos />
  },
  {
    path: '/administracion/riegos/descarga',
    exact: true,
    title: 'Riegos Descargas',
    component: () => <RiegosDescargas />
  },
  

  {
    path: '/administracion/riegos/diagnosticos/registrar/:id_sector_diagnostico_riego',
    exact: true,
    title: 'Registrar diagnóstico de riego',
    component: () => <RiegosDiagnosticosRegistrar />
  },

  {
    path: '/administracion/riegos/diagnosticos',
    exact: true,
    title: 'Diagnósticos de riego registrados',
    component: () => <RiegosDiagnosticosListar />
  },
  {
    path: '/administracion/riegos/lecturastensiometros/registrar/:id_sector_lectura_tensiometro',
    exact: true,
    title: 'Registrar lectura tensiómetro',
    component: () => <LecturasTensiometrosRegistrar />
  },
  {
    path: '/administracion/riegos/lecturastensiometros',
    exact: true,
    title: 'Lecturas de tensiometros registrados',
    component: () => <LecturasTensiometrosListar />
  },

  {
    path: '/administracion/riegos/lecturascaudalimetros/registrar/:id_sector_lectura_caudalimetro',
    exact: true,
    title: 'Registrar lectura caudalimetro',
    component: () => <LecturasCaudalimetrosRegistrar />
  },
  {
    path: '/administracion/riegos/lecturascaudalimetros',
    exact: true,
    title: 'Lecturas de caudalimetros registrados',
    component: () => <LecturasCaudalimetrosListar />
  },
  {
    path: '/administracion/costos/plaguicidas',
    exact: true,
    title: 'Costos',
    component: () => <CostosListadoPlaguicidas />
  },
  {
    path: '/administracion/costos/fertilizantes',
    exact: true,
    title: 'Costos',
    component: () => <CostosListadoFertilizantes />
  },
];

export default routes;