import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  LabelList,
  Cell
} from "recharts";
import BlockUi from "react-block-ui";
import { cleanData } from "./DataHelpers";
import { rojos } from "./Colores";
import useFetchGraphData from "./useFetchGraphData";
import xlsx from "json-as-xlsx";
import moment from 'moment'

export const GraficaBarrasGral = ({
  url,
  height = 200,
  fechaInicial = "",
  fechaFinal = "",
  dataProcessing = cleanData,
}) => {
  const [blocking, data] = useFetchGraphData(url, fechaInicial, fechaFinal, dataProcessing);

  const exportarGrafica = () => {
    const titulo = url.replace(/\//g, '_')
      let columns = [
          { label: 'Nombre', value: 'label' },
          { label: 'Total', value: 'total' },
        ];
        let settings = {
          sheetName: titulo.substr(9,30),
          fileName: `${titulo}_${fechaInicial}_${fechaFinal}_generado_${moment().format("YYYYMMDD_HHmmss")}` ,
          extraLength: 3,
          writeOptions: {}
        }
        
       xlsx(columns, data, settings, true);
    };

  return (
    <BlockUi tag="div" blocking={blocking}>
      <button
        onClick={() => exportarGrafica()}
        className={`btn`}
      >
        <i className="fa fa-download" />  Descargar
      </button>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis
            dataKey="label"
            angle={-90}
            interval={0}
            height={150}
            textAnchor="end"
          />
          <YAxis width={20} />
          <Tooltip />
          <Brush dataKey="label" height={30} stroke="#E74155" />
          <Bar dataKey="total" fill="#000" >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={rojos[index % rojos.length]}
              />
            ))}
            <LabelList dataKey="total" fill={"#000"} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </BlockUi>
  );
};

export default GraficaBarrasGral;
