import xlsx from "json-as-xlsx";
import moment from 'moment';
const AvisosVistosExportar = (content) => {
    let columns = [
        { label: 'Nombres', value: 'nombre' },
        { label: 'Apellido', value: 'apellido' },
        { label: 'Estación', value: 'estacion' },
        { label: 'Fecha envio', value: 'enviado' },
        { label: 'Avisos Enviados', value: 'enviados' },
        { label: 'Avisos Vistos', value: 'vistos' }
      ];
      
      let settings = {
        sheetName: 'Avisos',
        fileName: 'Avisos_vistos_' + moment().format("YYYYMMDD_HHmmss"),
        extraLength: 3,
        writeOptions: {}
      }
      
      xlsx(columns, content, settings, true);
  };
  export default AvisosVistosExportar;
