import React from 'react';

const FormInputInline = ({register,error,label,id,...inputProps}) => {
    return <>
        <div className="input-group mb-2">
            <div className="input-group-prepend">
                <div className="input-group-text">{label}</div>
            </div>
            <input
                ref={register}
                id={id}
                className={`form-control ${error ? "is-invalid" : ""} col`}
                {...inputProps}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    </>
};
export default FormInputInline;