import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import Button from 'react-bootstrap/Button';

var dataArray = [];
const LecturasCaudalimetrosTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'ID',
			selector: 'id_sector_lectura_caudalimetro',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Productor',
			selector: 'productor_nombres',
			sortable: true,
			right: false
		},
		{
			name: 'Huerta',
			selector: 'nombre_huerta',
			sortable: true,
			right: false
		},
		{
			name: 'Evento',
			selector: 'evento',
			sortable: true,
			right: false
		},
		{
			name: 'Fecha Lectura',
			selector: 'fecha_lectura',
			sortable: true,
			maxWidth: '150px',
			right: false
		},
		{
			name: 'Lectura caudalímetro (m3)',
			selector: 'valor',
			sortable: true,
			right: false
		},

		{
			name: 'Incremento consumo en cintilla (m3)',
			selector: 'incremento_consumo',
			sortable: true,
			right: false
		},
		{
			name: 'Total acumulado por cintilla (m3)',
			selector: 'total_acumulado',
			sortable: true,
			right: false
		},
		{
			name: 'Consumo de agua acumulado del Sector 1 (m3/sector)',
			selector: 'consumo_acumulado',
			sortable: true,
			right: false
		},
		{
			name: 'Consumo de agua acumulado equivalente por hectárea (m3/ha)',
			selector: 'consumo_acumulado_hectarea',
			sortable: true,
			right: false
		},
		{
			name: 'Etapa',
			selector: 'nombre_etapa',
			sortable: true,
			right: false
		},
		{
			name: 'Costo energético calculado',
			selector: 'costo_energetico',
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Button className="btn btn-xs btn-warning" onClick={() => onUpdateClick(row.id_sector_lectura_caudalimetro)}><i className="fa fa-edit"></i></Button> <DeleteButton onClick={() => eliminaRegistro(row.id_sector_lectura_caudalimetro)}><i className="fa fa-trash"></i></DeleteButton></>,
		},

	]);


	useEffect(() => {
		if(props.id_productor > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
		obtenerRegistros(props.id_productor);
	}, [props.id_productor]);

	  const obtenerRegistros = (id_productor = 0)  => {
		setBlocking(true);
		Api.getAll(ApiUrl.riegos.lecturasCaudalimetros.general + '?id_productor=' + id_productor)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
			  setDatosFiltrados(response.data.data);
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaRegistro = (id_sector_lectura_caudalimetro) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				setBlocking(true);
				Api.delete(ApiUrl.riegos.lecturasCaudalimetros.general, id_sector_lectura_caudalimetro)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						setBlocking(false);
						Swal.fire(
						  'Eliminado!',
						  'Registro eliminado.',
						  'success'
						);
					}
				obtenerRegistros();
				})
				.catch(e => {
					setBlocking(false);
				  Swal.fire(
					'Error!',
					'Error al eliminar el registro.',
					'error'
				  );
				  obtenerRegistros();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}
	const onUpdateClick = (id_sector_lectura_caudalimetro) => {props.onUpdateClick(id_sector_lectura_caudalimetro);}

	useEffect(() => {
		if(props.id_productor > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
		obtenerRegistros(props.id_productor);
	}, [props.id_productor,props.reload]);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar lectura" 
				datosTabla={dataArray} 
				urlRegistrar={false}
				columnasFiltrar={["productor_nombres","nombre_sector","nombre_etapa","created_at"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>
          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default LecturasCaudalimetrosTabla;
