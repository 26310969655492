import React from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import VideosTabla from './VideosTabla.jsx';

const VideosListar = () => {
    return (
      <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item"><Link to="/">Administración de videos</Link></li>
        <li className="breadcrumb-item active">Listado de videos</li>
      </ol>
      <h1 className="page-header">Administración de videos <small></small></h1>
      <Panel>
        <PanelHeader>Videos registrados</PanelHeader>
        <PanelBody>
            <VideosTabla/>
        </PanelBody>
      </Panel>
    </div>
    );
  };
  export default VideosListar;
