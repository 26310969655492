import React, { useState, useEffect } from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList,
} from 'recharts'
import BlockUi from 'react-block-ui'
import Api from '../../../api/global.service'
import { getDateRange, createNewDeletedTotalUsersArray } from './DataHelpers'
import moment from 'moment'
import xlsx from 'json-as-xlsx'

export const AreaProdPorActivoYNivel = ({ height = 200, fechaInicial = '', fechaFinal = '' }) => {
  const [blocking, setBlocking] = useState(true)
  const [data, setData] = useState([])
  const [labels, setLabels] = useState(false)

  const stringToArray = (string) => {
    if (string && string.length > 0) {
      return string.split(',').map((item) => item.trim())
    }
    return []
  }

  // const hasAtLeastXOccurrances = (arr, xOccurrances) =>
  //   [...new Set(arr)].filter((x) => arr.filter((a) => a === x).length >= xOccurrances)

  const findItemsRepeatedInAllArrays = (arr) =>
    arr.shift().reduce(function (res, v) {
      if (
        res.indexOf(v) === -1 &&
        arr.every(function (a) {
          return a.indexOf(v) !== -1
        })
      )
        res.push(v)
      return res
    }, [])

  // Active users
  const getActiveUsersPerLevel = (users, dateRange, productores) => {
    let activeCurrentWeek = []
    let lastWeek = []
    let lastLastWeek = []
    // let currentWeekLevel3 = []
    // let lastWeekLevel3 = []
    // let lastLastWeekLevel3 = []
    const newData = dateRange.map((item) => {
      const users_current_week = users?.items_todos.find((d) => item.yearweek === d.yearweek)
      lastLastWeek = lastWeek
      lastWeek = activeCurrentWeek
      activeCurrentWeek = stringToArray(users_current_week?.active_users_per_week)
      const last3WeeksData = [activeCurrentWeek, lastWeek, lastLastWeek]
      const activeLast3Weeks = findItemsRepeatedInAllArrays(last3WeeksData)

      // Old Logic used possibly for Level3 in future
      // const users_only_climate_week = users?.items_solo_clima.find(
      //   (d) => item.yearweek === d.yearweek
      // )
      // const users_only_bitacora_week = users?.items_solo_bitacora.find(
      //   (d) => item.yearweek === d.yearweek
      // )
      // lastLastWeekLevel3 = lastWeekLevel3
      // lastWeekLevel3 = currentWeekLevel3
      // const currentWeek_climate = stringToArray(users_only_climate_week?.active_users_per_week)
      // const currentWeek_bitacora = stringToArray(users_only_bitacora_week?.active_users_per_week)
      // const currentWeekLevel3_climate = hasAtLeastXOccurrances(currentWeek_climate, 3)
      // const currentWeekLevel3_bitacora = hasAtLeastXOccurrances(currentWeek_bitacora, 3)
      // currentWeekLevel3 = findItemsRepeatedInAllArrays([
      //   currentWeekLevel3_climate,
      //   currentWeekLevel3_bitacora,
      // ])
      // const last3WeeksDataLevel3 = [currentWeekLevel3, lastWeekLevel3, lastLastWeekLevel3]
      // const level3Users = findItemsRepeatedInAllArrays(last3WeeksDataLevel3)

      const thisWeekTotalUsers = productores.find((d) => item.yearweek === d.yearweek)?.total_users
      return {
        ...item,
        activeUsers: activeCurrentWeek.length,
        inactiveUsers: thisWeekTotalUsers - activeCurrentWeek.length,
        level2Users: activeLast3Weeks.length,
        level1Users: activeCurrentWeek.length - activeLast3Weeks.length,
      }
    })
    return newData
  }

  useEffect(() => {
    const getData = async () => {
      const dateRange = getDateRange(fechaInicial, fechaFinal)
      const response = await Api.getAll('graficas/total_productores_registrados', {
        fechaInicial: fechaInicial,
        fechaFinal: fechaFinal,
      })
      if (response) {
        const productores = createNewDeletedTotalUsersArray(
          response.data.creados,
          response.data.borrados,
          dateRange,
          response.data.total_inicio,
          response.data.total_final
        )
        Api.getAll('graficas/activos_por_nivel', {
          fechaInicial: fechaInicial,
          fechaFinal: fechaFinal,
        })
          .then((response) => {
            setData(getActiveUsersPerLevel(response.data, dateRange, productores))
            setBlocking(false)
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicial, fechaFinal])

  const exportarActivosInactivos = () => {
    let columns = [
      { label: 'Semana', value: 'label' },
      { label: 'Productores Activos', value: 'activeUsers' },
      { label: 'Productores Inactivos', value: 'inactiveUsers' },
    ]
    let settings = {
      sheetName: 'ProductoresActivosInactivos',
      fileName: `ProductoresActivosInactivos_de_${fechaInicial}_a_${fechaFinal}_generado_${moment().format(
        'YYYYMMDD_HHmmss'
      )}`,
      extraLength: 3,
      writeOptions: {},
    }

    xlsx(columns, data, settings, true)
  }

  const exportarPorNivel = () => {
    let columns = [
      { label: 'Semana', value: 'label' },
      { label: 'Nivel 1', value: 'level1Users' },
      { label: 'Nivel 2', value: 'level2Users' },
    ]
    let settings = {
      sheetName: 'ProductoresPorNivel',
      fileName: `ProductoresPorNivel_de_${fechaInicial}_a_${fechaFinal}_generado_${moment().format(
        'YYYYMMDD_HHmmss'
      )}`,
      extraLength: 3,
      writeOptions: {},
    }

    xlsx(columns, data, settings, true)
  }

  return (
    <>
      <BlockUi tag="div" blocking={blocking}>
        <h2>Número de Productores Activos por semana</h2>
        <p style={{ marginBottom: 5 }}>
          <span style={{ color: '#00C49F' }}> ACTIVOS:</span> productores quienes han hecho al menos
          una actividad (generar bitácora o consulta de clima) durante la semana.
        </p>
        <p style={{ marginBottom: 5 }}>
          <span style={{ color: '#FFC107' }}>INACTIVOS:</span>: productores quienes no han hecho
          ninguna actividad durante la semana
        </p>
        <div className="col-md-12">
          <button
            onClick={() => setLabels(!labels)}
            className={`btn mx-2 ${labels ? 'btn-secondary' : 'btn-light'} `}
          >
            {labels ? 'esconder valores' : 'Ver valores'}
          </button>
          <button onClick={() => exportarActivosInactivos()} className={`btn`}>
            <i className="fa fa-download" /> Descargar
          </button>
        </div>
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart width={500} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" iconSize={20} />
            <XAxis
              dataKey="label"
              angle={-90}
              textAnchor="end"
              height={80}
              padding={{ top: 20, left: 15, right: 15 }}
            />
            <YAxis />
            <Legend verticalAlign="top" height={36} />
            <Tooltip />
            <Area
              name="Activos"
              type="linear"
              dataKey="activeUsers"
              stackId="1"
              stroke="#00C49F"
              fill="#00C49F"
            >
              {labels && <LabelList dataKey="activeUsers" position="top" />}
            </Area>
            <Area
              name="Inactivos"
              type="linear"
              dataKey="inactiveUsers"
              stackId="1"
              stroke="#FFC107"
              fill="#FFC107"
            >
              {labels && <LabelList dataKey="inactiveUsers" position="top" />}
            </Area>
          </AreaChart>
        </ResponsiveContainer>
        <hr />
        <br />
        {/*
        <h2>Productores por NIVEL</h2>
        <p style={{ marginBottom: 5 }}>
          <span style={{ color: '#E74155' }}> NIVEL 1:</span> Los productores que tienen una
          actividad a la semana (pero no son parte del nivel 2)
        </p>
        <p style={{ marginBottom: 5 }}>
          <span style={{ color: '#4181BC' }}>NIVEL 2:</span>: Los productores que tienen una
          actividad a la semana por tres semanas consecutivas
        </p>
        <div className="col-md-12">
          <button
            onClick={() => setLabels(!labels)}
            className={`btn mx-2 ${labels ? 'btn-secondary' : 'btn-light'} `}
          >
            {labels ? 'esconder valores' : 'Ver valores'}
          </button>
          <button onClick={() => exportarPorNivel()} className={`btn`}>
            <i className="fa fa-download" /> Descargar
          </button>
        </div>
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart width={500} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" iconSize={20} />
            <XAxis
              dataKey="label"
              angle={-90}
              textAnchor="end"
              height={80}
              padding={{ top: 20, left: 15, right: 15 }}
            />
            <YAxis />
            <Legend verticalAlign="top" height={36} />
            <Tooltip />
            <Area
              name="Nivel 2"
              type="linear"
              dataKey="level2Users"
              stackId="1"
              stroke="#4181BC"
              fill="#4181BC"
            >
              {labels && <LabelList dataKey="level2Users" position="top" />}
            </Area>
            <Area
              name="Nivel 1"
              type="linear"
              dataKey="level1Users"
              stackId="1"
              stroke="#E74155"
              fill="#E74155"
            >
              {labels && <LabelList dataKey="level1Users" position="top" />}
            </Area>
          </AreaChart>
        </ResponsiveContainer>
        */}
        <hr />
        <br />
      </BlockUi>
    </>
  )
}

export default AreaProdPorActivoYNivel
