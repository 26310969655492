import React, { useEffect,useState } from "react";
import Api from '../../../api/global.service';
import ApiUrl from '../../../constants/Api';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import DeleteButton from '../../../nuup/componentes/Generales/DeleteButton';
import estiloGeneral from '../../../nuup/componentes/Tablas/estiloGeneral';
import  '../../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';
import HerramientasTablas from '../../../nuup/componentes/Tablas/HerramientasTablas/HerramientasTablas';
import Button from 'react-bootstrap/Button';

var dataArray = [];
const RecomendacionesTabla = (props) => {
	const [datosFiltrados, setDatosFiltrados] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [columns, setColumns] = useState([
		{
			name: 'ID',
			selector: 'id_recomendacion_riego',
			maxWidth: '100px',
			sortable: true,
			right: false
		},
		{
			name: 'Huerta',
			selector: 'nombre_huerta',
			sortable: true,
			right: false
		},
		{
			name: 'Evento',
			selector: 'evento',
			sortable: true,
			right: false
		},
		{
			name: 'Ciclo',
			selector: 'nombre_ciclo',
			sortable: true,
			right: false
		},
		{
			name: 'Día (Lunes)	Semana',
			selector: 'fecha',
			sortable: true,
			right: false
		},
		{
			name: 'Etapa cultivo',
			selector: 'nombre_etapa',
			sortable: true,
			right: false
		},

		{
			name: 'Kc',
			selector: 'kc',
			sortable: true,
			right: false
		},
		{
			name: 'Eto (mm)',
			selector: 'eto',
			sortable: true,
			right: false
		},
		{
			name: 'ETc/semana (mm)	',
			selector: 'etc_semana',
			sortable: true,
			right: false
		},
		{
			name: 'm3/ha',
			selector: 'm3_ha',
			sortable: true,
			right: false
		},
		{
			name: 'Tiempo riego necesario  por semana (horas)',
			cell: (row) => getTiempoRiego(row.tiempo_riego_semana_minutos),
			sortable: true,
			right: false
		},
		{
			name: 'OPCIONES',
			button: true,
			cell: (row) => <><Button className="btn btn-xs btn-warning" onClick={() => onUpdateClick(row.id_recomendacion_riego)}><i className="fa fa-edit"></i></Button> <DeleteButton onClick={() => eliminaRegistro(row.id_recomendacion_riego)}><i className="fa fa-trash"></i></DeleteButton></>,
		}
	]);

	const getTiempoRiego = (tiempo_riego_semana_minutos) => {
		let minutos = tiempo_riego_semana_minutos % 60;
		let horas = (tiempo_riego_semana_minutos - minutos) / 60;
		return (horas < 10 ? "0" : "") + horas.toString() + ":" + (minutos < 10 ? "0" : "") + parseInt(minutos).toString();
	}

	useEffect(() => {
		if(props.id_productor > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
		obtenerRegistros(props.id_productor);
	}, [props.id_productor]);

	  const obtenerRegistros = (id_productor = 0)  => {
		setBlocking(true);
		Api.getAll(ApiUrl.riegos.recomendaciones.general + '?id_productor=' + id_productor)
		  .then(response => {
			  dataArray = response.data.data; 
			  setBlocking(false);
			  setDatosFiltrados(response.data.data);
			console.log(response.data.data);
		  })
		  .catch(e => {
			setBlocking(false);
			console.log(e);
		  });
	  }


	const eliminaRegistro = (id_recomendacion_riego) => {		
		Swal.fire({
			title: '¿Esta seguro?',
			text: "El registro se eliminará",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!'
		  }).then((result) => {
			if (result.isConfirmed) {
				setBlocking(true);
				Api.delete(ApiUrl.riegos.recomendaciones.general, id_recomendacion_riego)
				.then(response => {
					let _result = response.data;
					if(_result.success === 1){
						setBlocking(false);
						Swal.fire(
						  'Eliminado!',
						  'Registro eliminado.',
						  'success'
						);
					}
				obtenerRegistros();
				})
				.catch(e => {
					setBlocking(false);
				  Swal.fire(
					'Error!',
					'Error al eliminar el registro.',
					'error'
				  );
				  obtenerRegistros();
				});

			}
		  })

	};

	const handleChange = (datosFiltrados) => {setDatosFiltrados(datosFiltrados);}
	const onUpdateClick = (id_recomendacion_riego) => {props.onUpdateClick(id_recomendacion_riego);}

	useEffect(() => {
		if(props.id_productor > 0){setColumns(columns.filter(function(item){ return item['selector'] !== 'productor_nombres' }))}
		obtenerRegistros(props.id_productor);
	}, [props.id_productor,props.reload]);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
			<HerramientasTablas 
				txtBusqueda="Buscar lectura" 
				datosTabla={dataArray} 
				urlRegistrar={false}
				columnasFiltrar={["productor_nombres","nombre_sector","nombre_etapa","created_at"]}
				exportar={false}
				onFiltrar={(datosFiltrados) => handleChange(datosFiltrados)}
			/>
          <DataTable
		  	noHeader
            pagination
            columns={columns}
            data={datosFiltrados}
            Clicked
			noDataComponent={'Sin datos por mostrar'}
			customStyles={estiloGeneral}
          />
		</BlockUi>
    );
  };
export default RecomendacionesTabla;
